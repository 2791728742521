import { createStyles, Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import { PartnerAvatar } from '@portals/framework';
import { CecPartnerType } from '@portals/types';

interface PartnerCardProps {
  partner: CecPartnerType;
  handlePartnerSelected: (partner: CecPartnerType) => void;
}

export function PartnerCard({
  partner,
  handlePartnerSelected,
}: PartnerCardProps) {
  const { classes } = useStyles();

  return (
    <Paper
      p="xxl"
      w={290}
      radius="lg"
      className={classes.container}
      onClick={() => handlePartnerSelected(partner)}
    >
      <Stack align="center">
        <PartnerAvatar
          src={partner.logo}
          partnerName={partner.display_name}
          size={70}
        />
        <Text size="lg" align="center" lineClamp={1}>
          {partner.display_name}
        </Text>
        <Text color="gray.6" align="center" lineClamp={5}>
          {partner.about}
        </Text>
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    border: `2px solid ${theme.colors.gray[1]}`,
    cursor: 'pointer',
    height: 290,

    '&:hover': {
      boxShadow: '0px 5px 12px 0px rgba(38, 50, 56, 0.13)',
    },
  },
}));
