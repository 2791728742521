import { Button, createStyles, Menu } from '@mantine/core';
import React from 'react';

import {
  SubscriptionDetailsType,
  useStoreSettings,
} from '@portals/api/organizations';
import { DetailsPanel } from '@portals/core';
import { useOpenPolicyModal, usePermissionAccess } from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { ReactComponent as CloseCircle } from '@portals/icons/linear/close-circle.svg';
import { ReactComponent as DocumentText } from '@portals/icons/linear/document-text.svg';
import { ReactComponent as MoreIcon } from '@portals/icons/linear/more.svg';
import { ReactComponent as RefreshIcon } from '@portals/icons/linear/refresh.svg';
import { SubscriptionStatus } from '@portals/types';

interface SubscriptionDetailsPanelActionsProps {
  subscription: SubscriptionDetailsType;
  onCancelSubscription: () => void;
  onRenewSubscription: () => void;
}

export function SubscriptionDetailsPanelActions({
  subscription,
  onCancelSubscription,
  onRenewSubscription,
}: SubscriptionDetailsPanelActionsProps) {
  const { classes } = useStyles();

  const { isAdmin } = usePermissionAccess();

  const openRouteModal = useOpenRouteModal();
  const hasDevice = !!subscription.device;
  const isPendingCancellation = subscription.status === 'pending_cancellation';

  const showActions =
    subscription.status === 'active' || isPendingCancellation || hasDevice;

  if (!showActions) return null;

  const mainButtonRenderer = () => {
    if (hasDevice) {
      return (
        <DetailsPanel.ActionButton
          className={classes.mainButton}
          data-testid="subscription-actions-button"
          onClick={() =>
            openRouteModal({
              modalId: 'device',
              pathParams: [subscription.device?.id as string],
            })
          }
        >
          Show Device
        </DetailsPanel.ActionButton>
      );
    } else if (isPendingCancellation && isAdmin) {
      return (
        <DetailsPanel.ActionButton
          className={classes.mainButton}
          onClick={onRenewSubscription}
          data-testid="renew-subscription-button"
        >
          Renew Subscription
        </DetailsPanel.ActionButton>
      );
    }

    return null;
  };

  return (
    <DetailsPanel.Actions>
      {mainButtonRenderer()}

      <ActionsMenu
        hasDevice={hasDevice}
        subscriptionStatus={subscription.status}
        onCancelSubscription={onCancelSubscription}
        onRenewSubscription={onRenewSubscription}
      />
    </DetailsPanel.Actions>
  );
}

interface ActionsMenuProps {
  hasDevice: boolean;
  subscriptionStatus: SubscriptionStatus;
  onCancelSubscription: () => void;
  onRenewSubscription: () => void;
}
function ActionsMenu({
  hasDevice,
  subscriptionStatus,
  onCancelSubscription,
  onRenewSubscription,
}: ActionsMenuProps) {
  const { classes } = useStyles();
  const openPolicyModal = useOpenPolicyModal();
  const { isAdmin } = usePermissionAccess();

  const storeSettings = useStoreSettings();

  const renderMenuTargetAsIcon =
    hasDevice || (subscriptionStatus === 'pending_cancellation' && isAdmin);
  const renderRenewMenuItem =
    hasDevice && isAdmin && subscriptionStatus === 'pending_cancellation';

  return (
    <Menu
      withinPortal={false}
      position="bottom-end"
      width={renderMenuTargetAsIcon ? undefined : 'target'}
    >
      <Menu.Target>
        {renderMenuTargetAsIcon ? (
          <Button
            variant="default"
            size="xs"
            px={0}
            className={classes.compactMenuTarget}
          >
            <MoreIcon />
          </Button>
        ) : (
          <Button variant="default" size="xs" px={0}>
            Actions
          </Button>
        )}
      </Menu.Target>

      <Menu.Dropdown miw={210}>
        <Menu.Item
          icon={<DocumentText />}
          onClick={() =>
            openPolicyModal({
              policyKey: 'terms_of_service',
              editorContent: storeSettings.data?.terms_of_service,
            })
          }
        >
          Terms of use
        </Menu.Item>

        <Menu.Item
          icon={<DocumentText />}
          onClick={() =>
            openPolicyModal({
              policyKey: 'privacy_policy',
              editorContent: storeSettings.data?.privacy_policy,
            })
          }
        >
          Privacy
        </Menu.Item>

        <Menu.Item
          icon={<DocumentText />}
          onClick={() =>
            openPolicyModal({
              policyKey: 'cancellations_and_returns_policy',
              editorContent:
                storeSettings.data?.cancellations_and_returns_policy,
            })
          }
        >
          Cancellation & returns
        </Menu.Item>

        {subscriptionStatus === 'active' && isAdmin ? (
          <>
            <Menu.Divider />
            <Menu.Item
              icon={<CloseCircle />}
              color="red"
              onClick={onCancelSubscription}
              data-testid="cancel-subscription-menu-item"
            >
              Cancel subscription
            </Menu.Item>
          </>
        ) : null}

        {renderRenewMenuItem && isAdmin ? (
          <>
            <Menu.Divider />
            <Menu.Item icon={<RefreshIcon />} onClick={onRenewSubscription}>
              Renew Subscription
            </Menu.Item>
          </>
        ) : null}
      </Menu.Dropdown>
    </Menu>
  );
}

const useStyles = createStyles(() => ({
  compactMenuTarget: {
    aspectRatio: '1',
    flexGrow: 0,
  },
  mainButton: {
    maxWidth: 'none',
  },
}));
