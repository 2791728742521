import { Box, Button, Stack, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import { object, string } from 'yup';

import {
  PasswordInputWithRequirements,
  yupPasswordValidator,
} from '@portals/framework';

import { StepSubtitle, StepTitle } from '../common';
import { useSignUpWizard, useTrackPageView } from '../wizard/wizard.hooks';

const schema = object({
  password: yupPasswordValidator,
  name: string().required('Name is required'),
});

export function SetUpAccount() {
  const { onNext, onPrev, contextData, setContextData } = useSignUpWizard();

  const form = useForm({
    initialValues: {
      name: contextData.name,
      password: contextData.password,
    },
    validate: yupResolver(schema),
  });

  const onSubmit = (values: typeof form.values) => {
    setContextData((curr) => ({
      ...curr,
      name: values.name,
      password: values.password,
      auth: Boolean(contextData.name && contextData.password),
    }));

    onNext();
  };

  useTrackPageView('Set-up your account screen');

  return (
    <Box>
      <StepTitle>Set-up your account</StepTitle>

      <StepSubtitle mb="xxl">Fill in your profile details.</StepSubtitle>

      <Stack spacing="lg">
        <form onSubmit={form.onSubmit(onSubmit)} noValidate>
          <Stack spacing="md">
            <TextInput
              data-testid="full-name-input"
              required
              label="What is your full name"
              placeholder="e.g. Thomas Parker"
              type="text"
              {...form.getInputProps('name')}
            />

            <Box data-testid="new-password-input">
              <PasswordInputWithRequirements
                {...form.getInputProps('password')}
              />
            </Box>

            <Button
              fullWidth
              mt="xl"
              type="submit"
              data-testid="onboarding-continue-button"
            >
              Continue
            </Button>

            <Button
              data-testid="back"
              data-analyticsid="back-to-authentication"
              fullWidth
              onClick={onPrev}
              variant="default"
              color="blue_gray"
              size="md"
            >
              Back
            </Button>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
}
