import { createStyles, LoadingOverlay, Stack } from '@mantine/core';
import { find } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { usePortalConfig } from '@portals/api/organizations';
import { defaultStoreBannerSrc } from '@portals/framework';
import { useAuth } from '@portals/redux';

import { ExternalStoreHeader } from './ExternalStoreHeader';
import { ExternalStoreNavbar } from './ExternalStoreNavbar';
import { ExternalStoreProductsList } from './ExternalStoreProductsList';
import { useShopAndCheckSelectedCurrency } from '../../../hooks/store';
import { useShopCurrency } from '../../../redux/hooks/store';
import { ProductDetails } from '../store/product-details';
import { SortByEnum } from '../store/SortBy';
import { BANNER_HEIGHT } from '../store/store.constants';

export function ExternalStore() {
  const { classes, cx } = useStyles();
  const { storeListingSlug } = useParams<{
    storeListingSlug?: string;
  }>();
  const [sortBy, setSortBy] = useState(SortByEnum.Featured);
  const auth = useAuth();
  const portalConfig = usePortalConfig();

  const shopCurrency = useShopCurrency();

  const shop = useShopAndCheckSelectedCurrency();

  const [isCartOpened, setIsCartOpened] = useState(false);

  const isWithBanner = portalConfig.data?.design?.store?.with_banner === true;

  const content = useMemo(() => {
    if (storeListingSlug) {
      return (
        <ProductDetails
          currencyCode={shopCurrency.selected}
          storeListing={find({ slug: storeListingSlug }, shop.data?.products)}
          baseStoreUrl="/store"
          className={classes.productDetails}
        />
      );
    }

    return (
      <>
        {isWithBanner ? (
          <img
            height={BANNER_HEIGHT}
            width="100%"
            className={classes.banner}
            src={
              portalConfig.data?.design?.store?.banner || defaultStoreBannerSrc
            }
            alt="Store banner"
          />
        ) : null}

        <ExternalStoreHeader sortBy={sortBy} setSortBy={setSortBy} />

        <ExternalStoreProductsList sortBy={sortBy} />
      </>
    );
  }, [
    classes.banner,
    classes.productDetails,
    isWithBanner,
    portalConfig.data?.design?.store?.banner,
    shop.data?.products,
    shopCurrency.selected,
    sortBy,
    storeListingSlug,
  ]);

  if (auth) return <Navigate to="/store" replace />;

  if (!shop.isFetched || !portalConfig.isFetched) {
    return (
      <Stack spacing={0} className={classes.container}>
        <LoadingOverlay visible />
      </Stack>
    );
  }

  return (
    <Stack
      spacing={0}
      className={cx(classes.container, {
        'with-banner': isWithBanner,
      })}
    >
      <ExternalStoreNavbar setIsOpen={setIsCartOpened} isOpen={isCartOpened} />

      {content}
    </Stack>
  );
}

const useStyles = createStyles(() => ({
  container: {
    position: 'relative',
    minHeight: '100vh',
  },
  banner: {
    objectFit: 'cover',
  },
  productDetails: {
    flexGrow: 1,
  },
}));
