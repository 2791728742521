import {
  Alert,
  Button,
  Group,
  Modal,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import React, { useState } from 'react';

import { DeviceType, useCreateTicket } from '@portals/api/organizations';
import { ModalBody, ModalFooter } from '@portals/core';
import { DeviceAvatar, ModalProps } from '@portals/framework';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { OrganizationIncidentType } from '@portals/types';

export interface CreateTicketProps
  extends ModalProps<{
    device: DeviceType;
    description?: OrganizationIncidentType['description'];
  }> {}

export function CreateTicket({ data, closeMe }: CreateTicketProps) {
  const { device, description } = data;
  const createTicket = useCreateTicket();

  const [title, setTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState(description || '');

  const isSubmitDisabled =
    title.trim() === '' || editedDescription.trim() === '';

  const handleSubmit = () => {
    createTicket.mutate(
      {
        deviceId: device.id,
        title: title.trim(),
        description: editedDescription.trim(),
      },
      { onSuccess: closeMe }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      padding="xxl"
      title="Request device support"
    >
      <ModalBody>
        <Stack spacing="xl">
          <Group spacing="xl" align="flex-start">
            <DeviceAvatar
              img={device.model_settings?.media?.img}
              icon={device.model_settings?.icon}
              size={97}
            />

            <Stack spacing="xs">
              <Title
                order={6}
                sx={(theme) => ({
                  fontWeight: 600,
                  color: theme.colors.blue_gray[6],
                })}
              >
                {device.partner.vendor}
              </Title>
              <Title order={4} sx={{ fontWeight: 400 }}>
                {device.partner.model}
              </Title>
            </Stack>
          </Group>

          <Stack>
            <TextInput
              required
              label="Title"
              data-testid="request-device-support-title"
              placeholder="General description"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <Textarea
              required
              label="Description"
              data-testid="request-device-support-description"
              placeholder="Detailed description of the problem"
              maxRows={7}
              minRows={7}
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
            />
          </Stack>

          <Alert radius="md" icon={<InfoCircle width={18} height={18} />}>
            Creating a ticket allows the service provider / manufacturer full
            access to this device, includes: Access device information, Perform
            commands (reboot, firmware upgrade, etc)
          </Alert>

          {createTicket.error ? (
            <Text color="red">{createTicket.error?.error}</Text>
          ) : null}
        </Stack>
      </ModalBody>

      <ModalFooter grow>
        <Button
          variant="default"
          onClick={closeMe}
          data-testid="close-incident-button"
        >
          Cancel
        </Button>
        <Button
          data-testid="request-device-support-escalate-button"
          disabled={isSubmitDisabled}
          loading={createTicket.isLoading}
          onClick={handleSubmit}
        >
          Escalate
        </Button>
      </ModalFooter>
    </Modal>
  );
}
