import {
  Badge,
  BadgeProps,
  Button,
  Center,
  Image,
  LoadingOverlay,
  Menu,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';

import {
  LICENSES_STATUS_COLORS,
  LICENSES_STATUS_VALUES,
  PurchasedProductType,
  usePurchasedProduct,
  useStoreSettings,
} from '@portals/api/organizations';
import { DetailsPanel } from '@portals/core';
import { useOpenPolicyModal } from '@portals/framework';
import { ReactComponent as Gallery } from '@portals/icons/bold/gallery.svg';
import { ReactComponent as DocumentText } from '@portals/icons/linear/document-text.svg';
import { ProductTypeEnum } from '@portals/types';

import { PlatformLicenseTabs } from './PlatformLicenseTabs';
import { PostPurchaseParamsTabs } from './PostPurchaseParamsTabs';
import { OverviewTab } from './tabs/OverviewTab';
import { AssignLicenseToDeviceInfo } from '../../../../route-modals/Order/order-products-card/AssignLicenseToDeviceInfo';

interface PurchasedProductDetailsPanelProps {
  purchasedProductId: string;
  onClose: () => void;
}

export function PurchasedProductDetailsPanel({
  purchasedProductId,
  onClose,
}: PurchasedProductDetailsPanelProps) {
  const theme = useMantineTheme();
  const openPolicyModal = useOpenPolicyModal();

  const storeSettings = useStoreSettings();

  const purchasedProduct = usePurchasedProduct(purchasedProductId);

  if (purchasedProduct.isFetching) {
    return <LoadingOverlay visible />;
  }

  return (
    <DetailsPanel
      enableScrollUiChanges={
        !purchasedProduct.data?.post_purchase_parameters_required &&
        purchasedProduct.data?.product.product_type !==
          ProductTypeEnum.PlatformLicense
      }
    >
      <DetailsPanel.Header
        onClose={onClose}
        spacing="xxl"
        title={
          <AssignedLicenseBadge
            licenseState={purchasedProduct.data?.license?.state}
            purchasedProductLicenseType={
              purchasedProduct.data?.product.product_type
            }
          />
        }
      >
        <Stack align="center">
          <Image
            width={100}
            height={100}
            radius="md"
            withPlaceholder
            src={purchasedProduct.data?.product?.image_url}
            placeholder={
              <Gallery
                width="50%"
                height="50%"
                color={theme.colors.blue_gray[2]}
              />
            }
          />

          <Stack spacing="xs" align="center">
            <DetailsPanel.Title>
              {purchasedProduct.data?.product?.name}
            </DetailsPanel.Title>

            {purchasedProduct.data?.product?.subtitle ? (
              <Text color="dimmed">
                {purchasedProduct.data?.product?.subtitle}
              </Text>
            ) : null}
          </Stack>
        </Stack>

        <Menu withinPortal width="target">
          {!purchasedProduct.data?.license.device_id &&
          purchasedProduct.data?.product.product_type ===
            ProductTypeEnum.PlatformLicense ? (
            <Center>
              <AssignLicenseToDeviceInfo
                deviceModelName={
                  purchasedProduct.data?.product.device_model_name
                }
                withinPortal={false}
              />
            </Center>
          ) : null}
          <Menu.Target>
            <Button variant="default">Actions</Button>
          </Menu.Target>

          <Menu.Dropdown miw={198}>
            <Menu.Item
              icon={<DocumentText />}
              onClick={() =>
                openPolicyModal({
                  policyKey: 'terms_of_service',
                  editorContent: storeSettings.data?.terms_of_service,
                })
              }
            >
              Terms of use
            </Menu.Item>

            <Menu.Item
              icon={<DocumentText />}
              onClick={() =>
                openPolicyModal({
                  policyKey: 'privacy_policy',
                  editorContent: storeSettings.data?.privacy_policy,
                })
              }
            >
              Privacy
            </Menu.Item>

            <Menu.Item
              icon={<DocumentText />}
              onClick={() =>
                openPolicyModal({
                  policyKey: 'cancellations_and_returns_policy',
                  editorContent:
                    storeSettings.data?.cancellations_and_returns_policy,
                })
              }
            >
              Cancellation & returns
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </DetailsPanel.Header>

      <PurchasedProductDetailsPanelTabs
        purchasedProduct={purchasedProduct.data}
      />
    </DetailsPanel>
  );
}

interface PurchasedProductDetailsPanelTabsProps {
  purchasedProduct: PurchasedProductType | undefined;
}

function PurchasedProductDetailsPanelTabs({
  purchasedProduct,
}: PurchasedProductDetailsPanelTabsProps) {
  if (purchasedProduct?.post_purchase_parameters_required) {
    return <PostPurchaseParamsTabs purchasedProduct={purchasedProduct} />;
  }

  if (
    purchasedProduct?.product.product_type === ProductTypeEnum.PlatformLicense
  ) {
    return <PlatformLicenseTabs purchasedProduct={purchasedProduct} />;
  }

  return (
    <DetailsPanel.Body>
      <OverviewTab purchasedProduct={purchasedProduct} />
    </DetailsPanel.Body>
  );
}

interface AssignedLicenseBadgeProps {
  purchasedProductLicenseType:
    | PurchasedProductType['product']['product_type']
    | undefined;
  licenseState: PurchasedProductType['license']['state'] | undefined;
}

function AssignedLicenseBadge({
  purchasedProductLicenseType,
  licenseState,
}: AssignedLicenseBadgeProps) {
  if (
    purchasedProductLicenseType !== ProductTypeEnum.PlatformLicense ||
    !licenseState
  ) {
    return null;
  }

  return (
    <Badge
      color={LICENSES_STATUS_COLORS[licenseState]}
      styles={badgeStyles}
      px="sm"
      py="xs"
    >
      {LICENSES_STATUS_VALUES[licenseState]}
    </Badge>
  );
}

const badgeStyles: BadgeProps['styles'] = (theme) => ({
  root: {
    color: theme.colors.blue_gray[9],
    fontSize: theme.fontSizes.xs,
    fontWeight: 400,
    textTransform: 'none',
  },
});
