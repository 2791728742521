import React from 'react';
import { Link } from 'react-router-dom';

const ServiceDisabled = ({ name, url }) => (
  <div className="d-flex flex-column justify-content-center align-items-center w-100">
    <h5>{`${name} integration not enabled`}</h5>

    <Link to={url}>{`Go to ${name} settings`}</Link>
  </div>
);

export default ServiceDisabled;
