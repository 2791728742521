import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { filter, find, isEmpty, map } from 'lodash/fp';
import React from 'react';

import {
  GroupAccessItem,
  SpaceDetailsType,
  useAddSpaceAccess,
  useGroups,
} from '@portals/api/organizations';
import { ModalCenteredMediaLayout } from '@portals/core';
import {
  GroupIconNames,
  GroupsSelect,
  GroupsSelectProps,
  Members,
  ModalProps,
  usersEmptyStateSrc,
} from '@portals/framework';
import { useOpenModal } from '@portals/redux';

import { AddExternalGroupAccessModalProps } from './AddExternalGroupAccessModal';

export interface AddInternalGroupAccessModalProps
  extends ModalProps<{
    space: SpaceDetailsType;
  }> {}

const ACCESS_LEVEL = [
  { value: '1', label: 'View' },
  { value: '2', label: 'Edit' },
  { value: '3', label: 'Admin' },
];

export function AddInternalGroupAccessModal({
  closeMe,
  data: { space },
}: AddInternalGroupAccessModalProps) {
  const groups = useGroups();
  const openModal = useOpenModal();

  const addSpaceAccess = useAddSpaceAccess();

  const groupsForm = useForm<{ group_id: string; level: string }>();

  const groupsOptions = composeGroupsOptions(
    space?.access?.groups,
    groups.data
  );

  const onSubmit = async (values: typeof groupsForm.values) => {
    try {
      await addSpaceAccess.mutateAsync({
        spaceId: space.id,
        ...values,
      });

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  if (groups.isFetched && isEmpty(groupsOptions)) {
    return (
      <Modal opened onClose={closeMe}>
        <Stack py="xl" px="sm" spacing="xl">
          <Stack spacing="md">
            <img src={usersEmptyStateSrc} alt="no-users" height={140} />

            <Text size="sm" weight={600} align="center">
              No groups to add
            </Text>

            <Stack spacing={0}>
              <Text size="xs" align="center" color="gray.5">
                Looks like there are no internal groups to add
              </Text>
              <Text size="xs" align="center" color="gray.5">
                You can invite external groups to your organization by clicking
                on the "Add External Groups”
              </Text>
            </Stack>
          </Stack>

          <Group w="100%" position="center">
            <Button
              variant="subtle"
              onClick={() => {
                closeMe();

                openModal<AddExternalGroupAccessModalProps['data']>(
                  'AddExternalGroupAccessModal',
                  {
                    space,
                  }
                );
              }}
            >
              Add External Groups
            </Button>
          </Group>

          <Group w="100%" position="center" grow>
            <Button variant="default" onClick={closeMe} size="md">
              Close
            </Button>
          </Group>
        </Stack>
      </Modal>
    );
  }

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title="Add Internal Access"
      media={<Members />}
    >
      <LoadingOverlay visible={groups.isLoading || addSpaceAccess.isLoading} />

      {groups.isFetched ? (
        <form onSubmit={groupsForm.onSubmit(onSubmit)}>
          <Stack spacing="xl">
            <GroupsSelect
              withinPortal
              data={groupsOptions}
              required
              {...groupsForm.getInputProps('group_id')}
            />

            <Select
              required
              withinPortal
              label="Access Level"
              placeholder="Select access level"
              data={ACCESS_LEVEL}
              {...groupsForm.getInputProps('level')}
            />

            <Group grow>
              <Button variant="default" onClick={closeMe}>
                Cancel
              </Button>
              <Button type="submit">Add Group</Button>
            </Group>
          </Stack>
        </form>
      ) : null}
    </ModalCenteredMediaLayout>
  );
}

function composeGroupsOptions(
  groupAccessItems: Array<GroupAccessItem> = [],
  groups: ReturnType<typeof useGroups>['data']
): GroupsSelectProps['data'] {
  if (!groups) return [];

  const filteredGroups = filter(
    (group) => !find({ id: group.id }, groupAccessItems),
    groups
  );

  return map(
    (group) => ({
      value: group.id,
      label: group.name,
      iconName: group?.icon_name as GroupIconNames,
    }),
    filteredGroups
  );
}
