import { Progress } from '@mantine/core';
import React from 'react';

import { useOrganizationConfig } from '@portals/api/organizations';

export function FreeTrialProgress() {
  const organizationConfig = useOrganizationConfig();

  const calcFreeTrialProgress = () => {
    if (organizationConfig?.free_trial_status !== 'in_progress') return 0;

    return ((organizationConfig?.remaining_trial_days as number) / 30) * 100;
  };

  const numOfDaysLeft = organizationConfig?.remaining_trial_days ?? 0;

  return (
    <Progress
      value={calcFreeTrialProgress()}
      color={numOfDaysLeft <= 3 ? 'red' : 'primary'}
    />
  );
}
