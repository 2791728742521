import { Badge, Button, Group, Stack, Text } from '@mantine/core';
import { find } from 'lodash/fp';
import React from 'react';

import {
  GroupAccessItem,
  SpaceDetailsType,
  useRemoveSpaceAccess,
  useSpaceDetails,
} from '@portals/api/organizations';
import { useConfirmationModal, usersEmptyStateSrc } from '@portals/framework';
import { EntityAccess } from '@portals/framework/EntityAccess';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { RowMenuItems, SmartTable } from '@portals/table';
import {
  AccessLevelEnum,
  RowMenuRefType,
  RowType,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';
import { ACCESS_LEVEL, ACCESS_LEVEL_COLOR } from '@portals/utils';

import {
  AddExternalGroupAccessModalProps,
  AddInternalGroupAccessModalProps,
  AddUserTypeModalProps,
  InternalOrExternal,
} from '../../../../../modals';

type GroupsAccessListProps = {
  space: SpaceDetailsType;
};

const COLUMNS: Array<TableColumn<GroupAccessItem>> = [
  {
    dataField: 'name',
    text: 'Group Name',
    sort: true,
    minWidth: 150,
    maxWidth: 150,
    formatter: (_, row) => (
      <Group align="center" noWrap spacing="md">
        <Stack
          spacing={2}
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <Text size="sm" color="gray.7" title={row.name} truncate>
            {row.name}
          </Text>

          <Text size="xs" color="gray.5">
            {row.support_partner_display_name}
          </Text>
        </Stack>

        {row.is_external ? (
          <Badge color="purple" radius="sm" size="lg" sx={{ flexShrink: 0 }}>
            <Text color="gray.9" size="xs" weight={400}>
              External Group
            </Text>
          </Badge>
        ) : null}
      </Group>
    ),
  },
  {
    dataField: 'level',
    text: 'Access Level',
    sort: true,
    minWidth: 60,
    maxWidth: 60,
    filter: { type: TableFilterTypeEnum.Select, options: ACCESS_LEVEL },
    formatter: (_, { access_level }) => (
      <Badge
        size="lg"
        radius="sm"
        variant="filled"
        color={ACCESS_LEVEL_COLOR[access_level]}
      >
        <Text size="xs" weight={400} color="gray.9">
          {ACCESS_LEVEL[access_level] || 'Unknown'}
        </Text>
      </Badge>
    ),
  },
];

export function GroupsAccessList({ space }: GroupsAccessListProps) {
  const asyncConfirmationCheck = useConfirmationModal();
  const openModal = useOpenModal();
  const removeSpaceAccess = useRemoveSpaceAccess();

  const parentSpace = useSpaceDetails(space.parent_id);

  const onRemoveAccess = async (
    row: RowType<GroupAccessItem>,
    menuRef: RowMenuRefType
  ) => {
    menuRef.onClose();

    const isAccessInherited = Boolean(
      find({ id: row.original.id }, parentSpace?.data?.access?.groups)
    );

    if (isAccessInherited) {
      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Access removal',
      description: `Are you sure you want to remove "${row.original.name}" group access?`,
    });

    if (isConfirmed) {
      try {
        await removeSpaceAccess.mutateAsync({
          spaceId: space.id,
          group_id: row.original.id,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onUserTypeSubmit = (userType: InternalOrExternal) => {
    if (userType === 'internal') {
      openModal<AddInternalGroupAccessModalProps['data']>(
        'AddInternalGroupAccessModal',
        {
          space,
        }
      );
    } else if (userType === 'external') {
      openModal<AddExternalGroupAccessModalProps['data']>(
        'AddExternalGroupAccessModal',
        {
          space,
        }
      );
    }
  };

  return (
    <SmartTable<GroupAccessItem>
      keyField="id"
      name="organizations.overview.accesses"
      data={space.access.groups}
      columns={COLUMNS}
      exportParams={{ isEnabled: false }}
      noColumnsSelection
      noDataIndication={{
        title: 'No groups with access yet',
        subtitle: 'Select a group to add access to this space',
        assetSrc: usersEmptyStateSrc,
        actions: (
          <Button
            variant="light"
            onClick={() =>
              openModal<AddUserTypeModalProps['data']>('AddUserTypeModal', {
                onSubmit: onUserTypeSubmit,
                type: 'groups',
              })
            }
          >
            Add Group
          </Button>
        ),
      }}
      isUrlSyncEnabled={false}
      additionalActions={() => (
        <EntityAccess
          minLevel={AccessLevelEnum.Admin}
          entity={space}
          id="add-access"
        >
          <Button
            size="xs"
            onClick={() =>
              openModal<AddUserTypeModalProps['data']>('AddUserTypeModal', {
                onSubmit: onUserTypeSubmit,
                type: 'groups',
              })
            }
          >
            Add Group
          </Button>
        </EntityAccess>
      )}
      rowMenu={({ row, wrapperProps }) => {
        const isAccessInherited = Boolean(
          find({ id: row.original.id }, parentSpace.data?.access?.groups)
        );

        return (
          <RowMenuItems
            wrapperProps={wrapperProps}
            items={[
              {
                id: 'remove',
                Icon: Trash,
                label: 'Remove',
                color: 'red',
                onClick: () => onRemoveAccess(row, wrapperProps.menuRef),
                Wrapper: ({ children }) => (
                  <EntityAccess
                    minLevel={
                      isAccessInherited
                        ? AccessLevelEnum.Disabled
                        : AccessLevelEnum.Admin
                    }
                    entity={space}
                    id={`remove-access-${row.original.id}`}
                    tooltipProps={{ placement: 'left' }}
                    {...(isAccessInherited && {
                      reasonLabel:
                        'Cannot remove an inherited access. Removal can be done in parent space.',
                    })}
                  >
                    {children}
                  </EntityAccess>
                ),
              },
            ]}
          />
        );
      }}
      defaultSorted={[{ id: 'level', desc: true }]}
    />
  );
}
