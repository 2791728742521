import {
  Badge,
  Box,
  createStyles,
  Group,
  MantineColor,
  Text,
  Tooltip,
} from '@mantine/core';
import { startsWith } from 'lodash';
import {
  filter,
  flow,
  get,
  isEmpty,
  join,
  map,
  sortBy,
  split,
} from 'lodash/fp';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { SpaceType, useSpaces } from '@portals/api/organizations';
import { IncidentsBySpaceIdTooltip } from '@portals/framework';
import { SmartTable } from '@portals/table';
import { TableColumn } from '@portals/types';
import { getSpaceHighestIncidentPriority } from '@portals/utils';

import { useCurrentSpace } from '../../../overview.hooks';

const SpaceCell = ({
  treePathName,
  name,
  id,
}: {
  treePathName: string;
  name: string;
  id: number;
}) => {
  const { classes } = useStyles();
  const spaceNames = split('/', treePathName).slice(1, -1);
  const spacePath = join(' / ', spaceNames);

  return (
    <Tooltip
      label={`${spacePath}${isEmpty(spacePath) ? '' : ' / '}${name}`}
      withinPortal
    >
      <Box className={classes.spaceCell}>
        <Text
          inline
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {spacePath}
        </Text>

        <Text inline>
          <Group align="center" spacing={4} noWrap>
            {isEmpty(spacePath) ? null : (
              <Text inline inherit>
                /
              </Text>
            )}
            <Link to={`/overview/${id}`}>{name}</Link>
          </Group>
        </Text>
      </Box>
    </Tooltip>
  );
};

function IncidentsCell({
  color,
  numOfIncidents,
  space,
}: {
  space: SpaceType;
  color: MantineColor;
  numOfIncidents: number;
}) {
  return (
    <IncidentsBySpaceIdTooltip space={space}>
      <Badge color={color} radius="xl" size="sm" px="xs" variant="filled">
        {numOfIncidents}
      </Badge>
    </IncidentsBySpaceIdTooltip>
  );
}

interface SpacesIncidentsRankType {
  space_tree_path_name: string;
  name: string;
  id: number;
  numOfIncidents: number;
  highestIncidentPriority: MantineColor;
  space: SpaceType;
}

const COLUMNS: Array<TableColumn<SpacesIncidentsRankType>> = [
  {
    dataField: 'name',
    text: 'Space',
    sort: true,
    minWidth: 200,
    maxWidth: 200,
    formatter: (_, { space_tree_path_name, name, id }) => (
      <SpaceCell treePathName={space_tree_path_name} name={name} id={id} />
    ),
  },
  {
    dataField: 'numOfIncidents',
    text: 'Incidents',
    minWidth: 145,
    maxWidth: 145,
    sort: true,
    formatter: (_, { space, numOfIncidents, highestIncidentPriority }) => (
      <IncidentsCell
        space={space}
        numOfIncidents={numOfIncidents}
        color={highestIncidentPriority}
      />
    ),
  },
];

export function SpacesIncidentsRankTable() {
  const spaces = useSpaces();
  const space = useCurrentSpace();

  const tableData = useMemo(
    () =>
      flow([
        // Filter only spaces related to the current space & only with active incidents
        filter(
          (currentSpace: SpaceType) =>
            startsWith(currentSpace.tree_path_name, space.tree_path_name) &&
            currentSpace.state?.local_incidents?.total > 0
        ),
        // Sort the spaces by num of incidents
        sortBy(
          (currentSpace: SpaceType) =>
            -get('state.local_incidents.total', currentSpace)
        ),
        // Map the spaces to the table data { space_tree_path_name, num_of_incidents,
        // highestIncidentPriority }
        map((currentSpace: SpaceType) => ({
          space: currentSpace,
          space_tree_path_name: currentSpace.tree_path_name,
          name: currentSpace.name,
          id: currentSpace.id,
          numOfIncidents: currentSpace.state?.local_incidents?.total || 0,
          highestIncidentPriority: getSpaceHighestIncidentPriority(
            currentSpace.state?.local_incidents
          ),
        })),
      ])(spaces.data),
    [spaces, space]
  );

  return (
    <SmartTable<SpacesIncidentsRankType>
      keyField="id"
      name="devicesByModel"
      data={tableData}
      columns={COLUMNS}
      isCompact
      noDataIndication={{ title: 'No incidents' }}
      noHeader
      isUrlSyncEnabled={false}
    />
  );
}

const useStyles = createStyles((theme) => ({
  spaceCell: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 'fit-content',
    gap: 4,
    paddingRight: theme.spacing.md,
  },
}));
