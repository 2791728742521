import {
  OrderType,
  useUpdatePurchaseOrderFile,
} from '@portals/api/organizations';
import { useGenericContext } from '@portals/framework';
import { UploadFileProps } from '@portals/framework/modals';
import { useOpenModal } from '@portals/redux';

export const useUploadPurchaseOrder = () => {
  const order = useGenericContext<OrderType>();
  const updatePurchaseOrderFile = useUpdatePurchaseOrderFile();
  const openModal = useOpenModal();

  const onSuccess = ([purchaseOrderFileUrl]: string[]) => {
    try {
      updatePurchaseOrderFile.mutateAsync({
        payment_id: order.payment?.id,
        purchase_order_file_url: purchaseOrderFileUrl,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return () =>
    openModal<UploadFileProps['data']>('UploadFile', {
      title: 'Upload Purchase Order',
      onSuccess,
      dropzoneProps: {
        sx: { height: 350 },
        maxSize: 20 * 1024 * 1024,
      },
    });
};
