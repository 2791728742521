import { ActionIcon, Badge, Box, Group, Text } from '@mantine/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useMemo } from 'react';

import {
  INVOICES_API_URL,
  OrganizationInvoiceType,
  useOrganizationInvoicesTableData,
  useUpdateInvoiceOrganizationNote,
} from '@portals/api/organizations';
import { FileTypeIcon } from '@portals/core';
import {
  OrganizationInvoicePaymentStatusBadge,
  OrderItemsPreviewTooltip,
  OrderItemsPreviewTooltipProps,
  usePermissionAccess,
} from '@portals/framework';
import { EditNoteModalProps } from '@portals/framework/modals';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { useOpenModal } from '@portals/redux';
import { DateCell, PaginatedTable } from '@portals/table';
import {
  OrderPaymentStatusType,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';
import {
  downloadFile,
  formatCurrency,
  getOrderPaymentMethodLabel,
  suppressPropagation,
} from '@portals/utils';

import { InvoiceDetailsPanel } from './InvoiceDetailsPanel';

dayjs.extend(relativeTime);

interface InvoicesTableProps {
  status: OrderPaymentStatusType | 'all';
  subtitle?: string;
}
export function InvoicesTable({ status, subtitle }: InvoicesTableProps) {
  const openModal = useOpenModal();
  const { canEdit } = usePermissionAccess();

  const updateInvoiceOrganizationNote = useUpdateInvoiceOrganizationNote();

  const tableColumns = useMemo(() => {
    const idColumn: TableColumn<OrganizationInvoiceType> = {
      dataField: 'orders_short_id',
      text: 'ID',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Text,
      },
      minWidth: 200,
      maxWidth: 200,
      formatter: (_, { order_short_id }) => order_short_id,
    };

    const itemsColumn: TableColumn<OrganizationInvoiceType> = {
      dataField: 'items',
      text: 'Items',
      minWidth: 100,
      formatter: (_, { items, items_count }) => {
        if (!items_count) return null;

        const itemsList: OrderItemsPreviewTooltipProps['items'] = items.map(
          (invoiceItem) => ({
            name: invoiceItem.product_name,
            quantity: invoiceItem.quantity,
            imageUrl: invoiceItem.product_image_url,
          })
        );

        return (
          <OrderItemsPreviewTooltip items={itemsList}>
            <Badge fw={400}>
              {items_count === 1 ? '1 item' : `${items_count} items`}
            </Badge>
          </OrderItemsPreviewTooltip>
        );
      },
    };

    const createdAtColumn: TableColumn<OrganizationInvoiceType> = {
      dataField: 'created_at',
      text: 'Created at',
      sort: true,
      formatter: (_, { created_at }) => {
        const parsedCreatedAtDate = Date.parse(created_at);
        const createdAtTimeAgo = dayjs().to(dayjs(parsedCreatedAtDate));

        return (
          <>
            <DateCell date={created_at} withTimeAgo={false} />
            <Text size="xs" color="blue_gray.7">
              {createdAtTimeAgo}
            </Text>
          </>
        );
      },
      filter: {
        type: TableFilterTypeEnum.Date,
      },
    };

    const amountColumn: TableColumn<OrganizationInvoiceType> = {
      dataField: 'amount_in_scu',
      text: 'Charged Amount',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Text,
      },
      formatter: (_, { amount_in_scu, currency }) =>
        formatCurrency(amount_in_scu, currency),
    };

    const paymentMethodColumn: TableColumn<OrganizationInvoiceType> = {
      dataField: 'payment_method',
      text: 'Payment Method',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Select,
        options: {
          credit_card: 'Credit Card',
          purchase_order: 'Purchase Order',
          lab: 'Lab',
        },
      },
      minWidth: 250,
      maxWidth: 250,
      formatter: (_, { payment_method }) =>
        getOrderPaymentMethodLabel(payment_method),
    };

    const paymentStatusColumn: TableColumn<OrganizationInvoiceType> = {
      dataField: 'status',
      text: 'Status',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Select,
        options: {
          pending: 'Pending',
          paid: 'Paid',
          failed: 'Payment failed',
          void: 'Void',
          refunded: 'Payment Refunded',
        },
      },
      formatter: (_, { status }) => (
        <OrganizationInvoicePaymentStatusBadge status={status} />
      ),
    };

    const noteColumn: TableColumn<OrganizationInvoiceType> = {
      dataField: 'note',
      text: 'Note',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Text,
      },
      formatter: (cell, row) => (
        <Group
          position="apart"
          noWrap
          sx={{
            display: 'grid',
            gridTemplateColumns: 'max-content 1fr',
          }}
        >
          {canEdit('finance') && (
            <ActionIcon
              size="sm"
              onClick={suppressPropagation(() =>
                openModal<EditNoteModalProps['data']>('EditNoteModal', {
                  note: row.note,
                  onSubmit: (newNote) =>
                    updateInvoiceOrganizationNote.mutateAsync({
                      note: newNote,
                      invoiceId: row.id,
                    }),
                })
              )}
            >
              <Edit />
            </ActionIcon>
          )}

          <Text size="xs" title={row.note} truncate>
            {row.note}
          </Text>
        </Group>
      ),
    };

    const invoiceFileColumn: TableColumn<OrganizationInvoiceType> = {
      dataField: 'invoice_file_url',
      text: 'Receipt file',
      formatter: (_, { id, invoice_file_url, order_id }) => {
        if (invoice_file_url) {
          return (
            <Box
              onClick={suppressPropagation(() =>
                downloadFile(invoice_file_url, `invoice_${order_id}`)
              )}
            >
              <FileTypeIcon fileUrl={invoice_file_url} width={28} />
            </Box>
          );
        }
      },
    };

    if (status === 'all') {
      return [
        idColumn,
        itemsColumn,
        paymentStatusColumn,
        createdAtColumn,
        amountColumn,
        paymentMethodColumn,
        noteColumn,
        invoiceFileColumn,
      ];
    } else if (status === 'failed') {
      return [
        idColumn,
        itemsColumn,
        createdAtColumn,
        amountColumn,
        paymentMethodColumn,
        noteColumn,
        invoiceFileColumn,
      ];
    } else {
      return [
        idColumn,
        itemsColumn,
        createdAtColumn,
        amountColumn,
        paymentMethodColumn,
        noteColumn,
        invoiceFileColumn,
      ];
    }
  }, [canEdit, openModal, status, updateInvoiceOrganizationNote]);

  return (
    <>
      <Text color="gray.7" size="sm" mb="xxl">
        {subtitle}
      </Text>

      <PaginatedTable<OrganizationInvoiceType>
        noHeader
        readOnly={!canEdit('finance')}
        keyField="id"
        name="partners.invoicing"
        dataHook={useOrganizationInvoicesTableData}
        dataHookUrl={buildDataHookUrl(status)}
        columns={tableColumns}
        noDataIndication={{ title: 'No invoices' }}
        defaultSortBy={[{ id: 'created_at', desc: true }]}
        detailsPanel={{
          type: 'page',
          width: 400,
          renderer: ({ row, onClose }) => (
            <InvoiceDetailsPanel invoice={row.original} onClose={onClose} />
          ),
        }}
      />
    </>
  );
}

function buildDataHookUrl(status: OrderPaymentStatusType | 'all') {
  if (status === 'all') return INVOICES_API_URL;

  const queryString = `q[status_eq]=${status}`;

  return `${INVOICES_API_URL}?${queryString}`;
}
