import { Button, ButtonProps } from '@mantine/core';
import React, { useCallback } from 'react';

import { SpaceType, usePortalConfig } from '@portals/api/organizations';
import { useFeatureFlags } from '@portals/api/organizations';
import { useOpenModal } from '@portals/redux';

import {
  AddDeviceModalPropsV1,
  SelectModelFromPartnerModalProps,
  SelectModelModalProps,
  SelectPartnerOrDeviceModelModalProps,
} from '../modals';

interface ClaimDeviceButtonProps extends ButtonProps {
  spaceId?: SpaceType['id'];
  partnerId?: string;
}

export function ClaimDeviceButton({
  spaceId = 1,
  partnerId,
  children,
  ...buttonProps
}: ClaimDeviceButtonProps) {
  const openClaimDeviceModal = useOpenClaimDeviceModal();

  return (
    <Button
      onClick={() => openClaimDeviceModal({ spaceId, partnerId })}
      {...buttonProps}
    >
      {children}
    </Button>
  );
}

export function useOpenClaimDeviceModal() {
  const portalConfig = usePortalConfig();
  const openModal = useOpenModal();
  const featureFlags = useFeatureFlags();

  return useCallback(
    (params: { spaceId?: SpaceType['id']; partnerId?: string }) => {
      const { spaceId = 1, partnerId } = params;

      if (portalConfig.data?.cec_enabled) {
        if (partnerId) {
          openModal<SelectModelFromPartnerModalProps['data']>(
            'SelectModelFromPartnerModal',
            { spaceId, partnerId }
          );
        } else {
          openModal<SelectPartnerOrDeviceModelModalProps['data']>(
            'SelectPartnerOrDeviceModelModal',
            { spaceId }
          );
        }
      } else {
        if (featureFlags?.new_claiming_device_flow) {
          openModal<SelectModelModalProps['data']>('SelectModelModal', {
            spaceId,
          });
        } else {
          openModal<AddDeviceModalPropsV1['data']>('AddDeviceModalV1', {
            spaceId,
          });
        }
      }
    },
    [
      featureFlags?.new_claiming_device_flow,
      openModal,
      portalConfig.data?.cec_enabled,
    ]
  );
}
