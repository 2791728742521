import { createStyles, getStylesRef, Group, Text } from '@mantine/core';
import React from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';
import { PartnerAvatar } from '@portals/framework';
import { ReactComponent as ArrowRightIcon } from '@portals/icons/linear/arrow-right.svg';
import { ReactComponent as BarcodeIcon } from '@portals/icons/linear/barcode.svg';
import { CecPartnerType } from '@portals/types';

import { useOpenClaimDeviceModal } from '../../components/ClaimDeviceButton';

interface ConnectionSuccessModalProps {
  isOpened: boolean;
  onClose: VoidFunction;
  cecPartner: CecPartnerType | null;
  closeCecPartnersModal: VoidFunction;
}

export function ConnectionSuccessModal({
  isOpened,
  onClose,
  cecPartner,
  closeCecPartnersModal,
}: ConnectionSuccessModalProps) {
  const { classes } = useStyles();

  const openClaimDeviceModal = useOpenClaimDeviceModal();

  const onOpenClaimDeviceClick = () => {
    onClose();
    closeCecPartnersModal();
    openClaimDeviceModal({ partnerId: cecPartner?.id });
  };

  if (!cecPartner) {
    return null;
  }

  return (
    <ModalCenteredMediaLayout
      opened={isOpened}
      onClose={onClose}
      media={
        <PartnerAvatar
          size={96}
          partnerName={cecPartner.display_name}
          src={cecPartner.logo}
        />
      }
      title="Connection Successful! 🎉"
      description={
        <Text>
          You are now connected with{' '}
          <Text span weight={700}>
            {cecPartner.display_name}
          </Text>
          .<br />
          Start exploring the new possibilities available to you.
        </Text>
      }
    >
      <Group className={classes.listItem} onClick={onOpenClaimDeviceClick}>
        <BarcodeIcon />

        <Text>
          Claim and monitor your devices from {cecPartner.display_name}.
        </Text>

        <ArrowRightIcon className={classes.arrowIcon} />
      </Group>
    </ModalCenteredMediaLayout>
  );
}

const useStyles = createStyles((theme) => ({
  listItem: {
    paddingBlock: theme.spacing.xl,
    paddingInline: theme.spacing.md,
    borderRadius: theme.radius.lg,
    color: theme.colors.gray[9],
    backgroundColor: theme.colors.gray[0],
    cursor: 'pointer',

    ...theme.fn.hover({
      backgroundColor: theme.colors.gray[1],

      [`.${getStylesRef('arrow')}`]: {
        opacity: 1,
      },
    }),
  },
  arrowIcon: {
    ref: getStylesRef('arrow'),
    opacity: 0,
    marginLeft: 'auto',
    width: 20,
    height: 20,
  },
}));
