import { Badge, Button, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import {
  SpaceDetailsType,
  UserAccessItem,
  useRemoveSpaceAccess,
} from '@portals/api/organizations';
import { useConfirmationModal, usersEmptyStateSrc } from '@portals/framework';
import { EntityAccess } from '@portals/framework/EntityAccess';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { RowMenuItems, SmartTable } from '@portals/table';
import {
  AccessLevelEnum,
  RowMenuRefType,
  RowType,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';
import { ACCESS_LEVEL, ACCESS_LEVEL_COLOR } from '@portals/utils';

import {
  AddExternalUserAccessModalProps,
  AddInternalUserAccessModalProps,
  AddUserTypeModalProps,
  InternalOrExternal,
} from '../../../../../modals';

type UsersAccessListProps = {
  space: SpaceDetailsType;
};

const COLUMNS: Array<TableColumn<UserAccessItem>> = [
  {
    dataField: 'user.name',
    text: 'Name',
    sort: true,
    minWidth: 150,
    maxWidth: 150,
    formatter: (_, row) => (
      <Group align="center" noWrap>
        <Stack
          spacing={2}
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <Text size="sm" color="gray.7" title={row.name} truncate>
            {row.name}
          </Text>

          <Text size="xs" color="gray.5">
            {row.support_partner_display_name}
          </Text>
        </Stack>

        {row.is_external ? (
          <Badge color="purple" radius="sm" size="lg" sx={{ flexShrink: 0 }}>
            <Text color="gray.9" size="xs" weight={400}>
              External User
            </Text>
          </Badge>
        ) : null}
      </Group>
    ),
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: true,
    minWidth: 100,
    maxWidth: 100,
  },
  {
    dataField: 'level',
    text: 'Access Level',
    sort: true,
    minWidth: 100,
    maxWidth: 100,
    filter: { type: TableFilterTypeEnum.Select, options: ACCESS_LEVEL },
    formatter: (_, { access_level }) => (
      <Badge
        size="lg"
        radius="sm"
        variant="filled"
        color={ACCESS_LEVEL_COLOR[access_level]}
      >
        <Text size="xs" weight={400} color="gray.9">
          {ACCESS_LEVEL[access_level] || 'Unknown'}
        </Text>
      </Badge>
    ),
  },
];

export function UsersAccessList({ space }: UsersAccessListProps) {
  const asyncConfirmationCheck = useConfirmationModal();
  const openModal = useOpenModal();
  const removeSpaceAccess = useRemoveSpaceAccess();

  const onRemoveAccess = async (
    row: RowType<UserAccessItem>,
    menuRef: RowMenuRefType
  ) => {
    menuRef.onClose();

    const isConfirmed = await asyncConfirmationCheck({
      title: 'Access removal',
      description: `Are you sure you want to remove ${row.original.name}'s access?`,
    });

    if (isConfirmed) {
      try {
        await removeSpaceAccess.mutateAsync({
          spaceId: space.id,
          user_id: row.original.id,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onUserTypeSubmit = (userType: InternalOrExternal) => {
    if (userType === 'internal') {
      openModal<AddInternalUserAccessModalProps['data']>(
        'AddInternalUserAccessModal',
        {
          space,
        }
      );
    } else if (userType === 'external') {
      openModal<AddExternalUserAccessModalProps['data']>(
        'AddExternalUserAccessModal',
        {
          space,
        }
      );
    }
  };

  return (
    <SmartTable<UserAccessItem>
      keyField="id"
      name="organizations.overview.accesses"
      data={space?.access?.users || []}
      columns={COLUMNS}
      exportParams={{ isEnabled: false }}
      noColumnsSelection
      noDataIndication={{
        title: 'No members with access yet',
        subtitle: 'Select a member or a group to add access to this space',
        assetSrc: usersEmptyStateSrc,
        actions: (
          <Button
            variant="light"
            onClick={() =>
              openModal<AddUserTypeModalProps['data']>('AddUserTypeModal', {
                onSubmit: onUserTypeSubmit,
                type: 'users',
              })
            }
          >
            Manage user access
          </Button>
        ),
      }}
      isUrlSyncEnabled={false}
      additionalActions={() => (
        <EntityAccess
          minLevel={AccessLevelEnum.Admin}
          entity={space}
          id="add-access"
        >
          <Button
            size="xs"
            onClick={() =>
              openModal<AddUserTypeModalProps['data']>('AddUserTypeModal', {
                onSubmit: onUserTypeSubmit,
                type: 'users',
              })
            }
          >
            Manage user access
          </Button>
        </EntityAccess>
      )}
      rowMenu={({ row, wrapperProps }) => {
        return (
          <RowMenuItems
            wrapperProps={wrapperProps}
            items={[
              {
                id: 'remove',
                Icon: Trash,
                label: 'Remove',
                color: 'red',
                onClick: () => onRemoveAccess(row, wrapperProps.menuRef),
                Wrapper: ({ children }) => (
                  <EntityAccess
                    minLevel={AccessLevelEnum.Admin}
                    entity={space}
                    id={`remove-access-${row.original.id}`}
                    tooltipProps={{ placement: 'left' }}
                  >
                    {children}
                  </EntityAccess>
                ),
              },
            ]}
          />
        );
      }}
      defaultSorted={[{ id: 'level', desc: true }]}
    />
  );
}
