import { Descope, getSessionToken, useDescope } from '@descope/react-sdk';
import {
  Anchor,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Pendo } from '@portals/analytics';
import { useCheckUserDomain, useSignIn } from '@portals/api/auth';
import { useAuth, useOpenModal } from '@portals/redux';
import { toastrError } from '@portals/redux/actions/toastr';
import { TenantType } from '@portals/types';

import { SignInProps } from './types';
import { useAppConfig } from '../../context';
import { useCommonPortalConfig } from '../../hooks/portal-config';
import { RegisteredWithDifferentPortalModalProps } from '../../modals';
import {
  descopeErrorTransformer,
  sendDescopeErrorReport,
} from '../../utils/descope.utils';

export function SignIn({ afterAuthPath, config }: SignInProps) {
  const { tenantType } = useAppConfig();

  const checkUserDomain = useCheckUserDomain();
  const openModal = useOpenModal();

  const navigate = useNavigate();

  const portalConfig = useCommonPortalConfig({
    enabled: tenantType !== TenantType.Partner,
  });

  const descope = useDescope();

  const adjustedConfig =
    tenantType === TenantType.Partner ? config : portalConfig.data;

  const auth = useAuth();
  const dispatch = useDispatch();

  const signIn = useSignIn();

  const withSignup = adjustedConfig?.signup || false;

  const setRouteTimeout = useRef<ReturnType<typeof setTimeout>>(null);

  useEffect(
    function redirectedAuthenticatedUser() {
      if (!auth?.token) return;

      let path;

      if (afterAuthPath) {
        path = afterAuthPath;
      } else {
        path = localStorage.getItem('afterAuth') || '/';
        localStorage.removeItem('afterAuth');
      }

      setRouteTimeout.current = setTimeout(() =>
        navigate(path, { replace: true })
      );

      return () => {
        if (setRouteTimeout.current) {
          clearTimeout(setRouteTimeout.current);
          setRouteTimeout.current = null;
        }
      };
    },
    [auth?.token, afterAuthPath, navigate]
  );

  useEffect(
    function redirectToChangePassword() {
      if (!auth?.change || !!auth?.token) return;

      setRouteTimeout.current = setTimeout(() =>
        navigate('/auth/change-password', { replace: true })
      );

      return () => {
        if (setRouteTimeout.current) {
          clearTimeout(setRouteTimeout.current);
          setRouteTimeout.current = null;
        }
      };
    },
    [auth?.change, auth?.token, navigate]
  );

  const isReferral = !!localStorage.getItem('referral');

  const isSignupVisible =
    isReferral || (withSignup && tenantType === TenantType.Organization);

  const logger: React.ComponentProps<typeof Descope>['logger'] = {
    debug: (title: string, description?: string) => {
      return;
    },
    info: (title, description, state) => {
      sendDescopeErrorReport(state, 'Framework > SignIn');

      if (state.error) {
        const email = state?.screen?.state?.form?.email;

        if (!email) {
          Pendo.track('Descope error - wrong login domain', {
            email: 'No email provided',
            domain: window.location.origin,
          });

          return;
        }

        if (tenantType !== TenantType.Partner) {
          checkUserDomain.mutate(
            { email },
            {
              onSuccess: ({ domain, partner_name }) => {
                if (!domain || domain === window.location.origin) {
                  return;
                }

                openModal<RegisteredWithDifferentPortalModalProps['data']>(
                  'RegisteredWithDifferentPortal',
                  { domain, partnerName: partner_name }
                );
              },
            }
          );
        }

        Pendo.track('Descope error - wrong login domain', {
          email,
          domain: window.location.origin,
        });
      }
    },
    warn: () => {
      return;
    },
    error: () => {
      return;
    },
  };

  return (
    <Stack>
      <Group mb="lg" align="baseline" position="center">
        <Title order={1} className="auth-page-title" align="center">
          Sign-In
        </Title>

        {isSignupVisible ? (
          <Text color="dimmed" size="sm" align="center">
            <Group spacing="xs">
              Don't have an account yet?
              <Anchor size="sm" component={Link} to="/auth/sign-up">
                Sign up
              </Anchor>
            </Group>
          </Text>
        ) : null}
      </Group>

      <LoadingOverlay visible={checkUserDomain.isLoading} />

      <Descope
        flowId={
          tenantType === TenantType.Organization
            ? 'sign-in-organization'
            : 'sign-in-partner'
        }
        logger={logger}
        onSuccess={async (e) => {
          const sessionToken = getSessionToken();

          try {
            await signIn.mutateAsync({
              token: sessionToken,
              tenant_type: tenantType as TenantType,
            });
          } catch (error) {
            await descope.logout();
            await descope.refresh();
            dispatch(
              toastrError(
                'Failed to sign in',
                error?.data?.error || error?.data
              )
            );
          } finally {
            navigate('/');
          }
        }}
        errorTransformer={descopeErrorTransformer}
      />
    </Stack>
  );
}
