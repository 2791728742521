import { Group, Menu } from '@mantine/core';
import React from 'react';

import { ReactComponent as ArrowDown } from '@portals/icons/linear/arrow-down.svg';

const SNOOZE_OPTIONS = [
  {
    value: 60,
    label: '1 Hour',
  },
  {
    value: 60 * 24,
    label: '1 Day',
  },
  {
    value: 60 * 24 * 7,
    label: '7 Days',
  },
  {
    value: 60 * 24 * 30,
    label: '30 Days',
  },
];

interface DeviceSnoozeMenuProps {
  onSnoozeSelect: (minutes: number) => void;
}

export function DeviceSnoozeMenu({ onSnoozeSelect }: DeviceSnoozeMenuProps) {
  return (
    <Menu position="bottom-end" trigger="hover">
      <Menu.Target>
        <Group position="apart">
          <span>Turn Off Incident Tracking</span>
          <ArrowDown width={15} height={15} />
        </Group>
      </Menu.Target>
      <Menu.Dropdown>
        {SNOOZE_OPTIONS.map(({ value, label }) => (
          <Menu.Item
            component="div"
            key={value}
            onClick={() => onSnoozeSelect(value)}
          >
            {label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}
