import {
  Anchor,
  Avatar,
  Button,
  createStyles,
  Modal,
  ModalProps,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React, { useState } from 'react';

import {
  useConnectCecPartner,
  useOrganizationConfig,
} from '@portals/api/organizations';
import { ModalBody, ModalFooter } from '@portals/core';
import { PartnerAvatar } from '@portals/framework';
import { CecPartnerType } from '@portals/types';

import { ConnectionSuccessModal } from './ConnectionSuccessModal';

interface ConfirmCecConnectionModalProps {
  onClose: VoidFunction;
  cecPartner: CecPartnerType | null;
  closeCecPartnersModal: VoidFunction;
}

export function ConfirmCecConnectionModal({
  onClose,
  cecPartner,
  closeCecPartnersModal,
}: ConfirmCecConnectionModalProps) {
  const { classes } = useStyles();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const organizationConfig = useOrganizationConfig();
  const connectCecPartner = useConnectCecPartner();

  const onConnectionClick = () => {
    if (!cecPartner) return;

    connectCecPartner.mutate(
      { partnerId: cecPartner.id },
      {
        onSuccess: () => {
          setIsSuccessModalOpen(true);
        },
      }
    );
  };

  if (!cecPartner) {
    return null;
  }

  return (
    <>
      <Modal
        opened={!isSuccessModalOpen}
        onClose={onClose}
        styles={modalStyles}
        padding="xxl"
        title={
          <Stack align="center">
            <Avatar.Group>
              <PartnerAvatar
                size={82}
                partnerName={organizationConfig?.display_name || ''}
                src={organizationConfig?.logo_url}
                classNames={{ root: classes.avatarRoot }}
              />

              <PartnerAvatar
                size={82}
                partnerName={cecPartner.display_name}
                src={cecPartner.logo}
                classNames={{ root: classes.avatarRoot }}
              />
            </Avatar.Group>

            <Title weight={400} order={4} ta="center">
              Connect with {cecPartner.display_name}
            </Title>
          </Stack>
        }
      >
        <ModalBody ta="center" className={classes.modalBody}>
          <Text mb="md">
            By connecting with <b>{cecPartner.display_name}</b>, you gain access
            to their cloud services. These services may include claiming and
            managing <b>{cecPartner.display_name}</b> devices, accessing
            dedicated support, and directly purchasing their products and
            services.
          </Text>

          <Text size="xs">
            Please take a moment to review the{' '}
            <Anchor
              weight={600}
              href="https://xyte.io/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms and conditions
            </Anchor>{' '}
            and{' '}
            <Anchor
              weight={600}
              href="https://xyte.io/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </Anchor>{' '}
            related to this connection. By connecting with{' '}
            <b>{cecPartner.display_name}</b>, you agree to abide by these terms.
          </Text>
        </ModalBody>

        <ModalFooter grow>
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            loading={connectCecPartner.isLoading}
            onClick={onConnectionClick}
          >
            Connect
          </Button>
        </ModalFooter>
      </Modal>

      <ConnectionSuccessModal
        isOpened={isSuccessModalOpen}
        onClose={onClose}
        cecPartner={cecPartner}
        closeCecPartnersModal={closeCecPartnersModal}
      />
    </>
  );
}

const useStyles = createStyles((theme) => ({
  avatarRoot: {
    border: `1px solid ${theme.colors.gray[3]}`,
  },
  modalBody: {
    b: {
      fontWeight: 700,
    },
  },
}));

const modalStyles: ModalProps['styles'] = (theme) => ({
  close: { alignSelf: 'flex-start' },
  title: { flexGrow: 1 },
  header: { paddingBottom: theme.spacing.md },
});
