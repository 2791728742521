import { Notifications } from '@mantine/notifications';
import { isMobile } from 'is-mobile';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { QueryClientWrapper } from '@lib/query-client-wrapper';
import { ThemeProvider as MantineThemeProvider } from '@portals/core';
import {
  DescopeAuthProvider,
  DevTools,
  EnvName,
  ErrorBoundary,
  HubspotConversationsProvider,
  initGoogleMap,
  Routes,
  VersionMonitor,
} from '@portals/framework';
import { AppConfigProvider } from '@portals/framework/context';
import { default as frameworkModals } from '@portals/framework/modals';
import { Localization } from '@portals/i18n';
import { store } from '@portals/redux';
import {
  ExtraLayoutType,
  OrganizationConfigurationType,
  TenantType,
} from '@portals/types';
import { ThemeProvider } from '@portals/ui';
import { initSentry } from '@portals/utils';

import { AutoTenantSwitcher } from './desktop/components/AutoTenantSwitcher';
import { OrganizationDataLayout } from './desktop/components/layouts';
import {
  ResponsiveAuthLayout,
  ResponsiveDashboardLayout,
} from './desktop/components/layouts/ResponsiveLayout';
import { MonitoringTourContextProvide } from './desktop/components/MonitoringTourContextProvider';
import { NavbarContactSupport } from './desktop/components/NavbarContactSupport';
import { OrganizationsMeta } from './desktop/components/OrganizationsMeta';
import { ShoppingCartToggle } from './desktop/components/ShoppingCart';
import { desktopModals } from './desktop/modals';
import { ConnectToggle } from './desktop/route-modals/Connect/ConnectToggle';
import { desktopRoutes } from './desktop/routes';
import GlobalStyle from './mobile/components/layout/GlobalStyle';
import { MobileOrganizationMeta } from './mobile/components/layout/MobileOrganizationMeta';
import { MobileExternalStoreLayout } from './mobile/layouts/MobileExternalStoreLayout';
import { mobileModals } from './mobile/modals';
import { mobileRoutes } from './mobile/routes';

const basename = process.env.NX_PREVIEW_APP_NAME
  ? `/${process.env.NX_PREVIEW_APP_NAME}`
  : '/';

export function App() {
  const [isMobileView, setIsMobileView] = useState(isMobile());

  initSentry();
  initGoogleMap();

  useEffect(
    function updateMobileView() {
      function resizeObserver() {
        if (isMobileView !== isMobile()) {
          setIsMobileView(isMobile());
        }
      }

      window.addEventListener('resize', resizeObserver);

      return () => {
        window.removeEventListener('resize', resizeObserver);
      };
    },
    [isMobileView]
  );

  const routes = isMobileView ? mobileRoutes : desktopRoutes;
  const modals = isMobileView ? mobileModals : desktopModals;

  const getExtraLayout = (): ExtraLayoutType<OrganizationConfigurationType> => {
    if (isMobileView) {
      return {
        globalLayout: () => (
          <>
            <GlobalStyle />
            <MobileOrganizationMeta />
          </>
        ),
        authLayout: ResponsiveAuthLayout,
        dashboardLayout: ResponsiveDashboardLayout,
        dashboardDataLayout: OrganizationDataLayout,
        externalStoreLayout: MobileExternalStoreLayout,
        options: {
          isMobile: true,
        },
      };
    }

    // Desktop
    return {
      globalLayout: () => <OrganizationsMeta />,
      authLayout: ResponsiveAuthLayout,
      dashboardLayout: ResponsiveDashboardLayout,
      dashboardDataLayout: OrganizationDataLayout,
      navbarLayout: () => (
        <>
          <NavbarContactSupport />
          <ShoppingCartToggle />
        </>
      ),
      sidebarLayout: ({ configuration }) =>
        configuration?.feature_flags?.connect_plus ? <ConnectToggle /> : null,
      options: {
        isMobile: false,
      },
    };
  };

  const params = new URLSearchParams(document.location.search);
  const existingAuth = params.get('auth');

  if (existingAuth) {
    try {
      // Parsing first to catch errors
      const auth = JSON.parse(atob(existingAuth));

      localStorage.setItem('auth', JSON.stringify(auth));

      window.location.assign('/');
    } catch (err) {
      console.error(err);
    }
  }

  if (process.env.NX_GIT_LATEST_COMMIT_SHA) {
    console.log(
      `Current portal version: ${process.env.NX_GIT_LATEST_COMMIT_SHA}`
    );
  }

  return (
    <ErrorBoundary
      // When switching between desktop/mobile views, we want to re-render entirely the app
      key={isMobileView ? 'mobile' : 'desktop'}
    >
      <BrowserRouter basename={basename}>
        <HelmetProvider>
          <ThemeProvider>
            <AppConfigProvider<OrganizationConfigurationType>
              value={{
                tenantType: TenantType.Organization,
                routes,
                modals: { ...frameworkModals, ...modals },
                extraLayout: getExtraLayout(),
              }}
            >
              <Provider store={store}>
                <QueryClientWrapper>
                  <MantineThemeProvider>
                    <Notifications position="bottom-center" />

                    <EnvName />

                    <VersionMonitor />

                    {process.env.NX_ENV === 'production' ? null : <DevTools />}

                    <HubspotConversationsProvider>
                      <Localization>
                        <DescopeAuthProvider>
                          <DndProvider backend={HTML5Backend}>
                            <MonitoringTourContextProvide>
                              <AutoTenantSwitcher />

                              <Routes<OrganizationConfigurationType> />
                            </MonitoringTourContextProvide>
                          </DndProvider>
                        </DescopeAuthProvider>
                      </Localization>
                    </HubspotConversationsProvider>
                  </MantineThemeProvider>
                </QueryClientWrapper>
              </Provider>
            </AppConfigProvider>
          </ThemeProvider>
        </HelmetProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
