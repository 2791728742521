import { OrganizationFeatureFlagKey } from '@portals/types';

enum CategoryNames {
  Common = 'Common',
  Release = 'Release',
}

export const FEATURE_LIST: Array<{
  id: OrganizationFeatureFlagKey;
  label: string;
  category: CategoryNames;
}> = [
  { id: 'crestron', label: 'Crestron', category: CategoryNames.Common },
  { id: 'experts', label: 'Experts', category: CategoryNames.Common },
  { id: 'ansa', label: 'Ansa', category: CategoryNames.Common },
  { id: 'connect_plus', label: 'Connect +', category: CategoryNames.Common },
  {
    id: 'integrations_demo',
    label: 'Integrations',
    category: CategoryNames.Common,
  },
  {
    id: 'managed_service_providers',
    label: 'MSP',
    category: CategoryNames.Common,
  },
  {
    id: 'tunnel_demo',
    label: 'Tunneling Demo',
    category: CategoryNames.Common,
  },
  { id: 'paid_plans', label: 'Paid Plans', category: CategoryNames.Release },
  {
    id: 'orders_csv_export',
    label: 'Enable Orders CSV Export',
    category: CategoryNames.Common,
  },
  {
    id: 'hide_new_tickets_ui',
    label: 'Hide new tickets UI',
    category: CategoryNames.Release,
  },
  {
    id: 'pendo_feedback_button',
    label: 'Pendo Feedback Button',
    category: CategoryNames.Common,
  },
  {
    id: 'new_claiming_device_flow',
    label: 'New claiming device Flow',
    category: CategoryNames.Release,
  },
];
