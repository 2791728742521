import { Anchor, Box, Text } from '@mantine/core';
import React from 'react';

import { useStoreSettings } from '@portals/api/organizations';
import { useOpenPolicyModal } from '@portals/framework';

export function ProductPolicies() {
  const openPolicyModal = useOpenPolicyModal();
  const storeSettings = useStoreSettings();

  return (
    <Box pb="xl">
      <Text span color="gray.5">
        By placing your order, you agree to the seller's{' '}
        <Anchor
          onClick={() =>
            openPolicyModal({
              policyKey: 'privacy_policy',
              editorContent: storeSettings.data?.privacy_policy,
            })
          }
        >
          Privacy Policy
        </Anchor>
        ,{' '}
        <Anchor
          onClick={() =>
            openPolicyModal({
              policyKey: 'terms_of_service',
              editorContent: storeSettings.data?.terms_of_service,
            })
          }
        >
          Terms of Service
        </Anchor>{' '}
        and the{' '}
        <Anchor
          onClick={() =>
            openPolicyModal({
              policyKey: 'cancellations_and_returns_policy',
              editorContent:
                storeSettings.data?.cancellations_and_returns_policy,
            })
          }
        >
          Cancellations and Returns Policy
        </Anchor>
        .
      </Text>
    </Box>
  );
}
