import { BackgroundImage, Box, createStyles, Stack } from '@mantine/core';
import { find, get } from 'lodash/fp';
import React, { useMemo } from 'react';

import { useSpaces } from '@portals/api/organizations';

import { PanelWrapper } from './overview-header-panel/PanelWrapper';
import { UpdateCoverPhotoToggle } from './UpdateCoverPhotoToggle';
import spaceCoverPhoto from '../../../../assets/img/space-cover-photo.png';
import { useCurrentSpace } from '../overview.hooks';

export function OverviewHeader() {
  const { classes } = useStyles();
  const spaces = useSpaces();
  const space = useCurrentSpace();

  const spaceImage = useMemo(() => {
    const isInherited = get(['config', 'inherit_img'], space);

    if (!isInherited) return get(['config', 'img'], space);

    const parentSpace = find({ id: space.parent_id }, spaces.data);

    return get(['config', 'img'], parentSpace);
  }, [spaces.data, space]);

  return (
    <Stack spacing={0} className={classes.container}>
      <BackgroundImage src={spaceImage || spaceCoverPhoto}>
        <Box className={classes.backgroundPlaceholder || spaceCoverPhoto}>
          <UpdateCoverPhotoToggle space={space} />
        </Box>
      </BackgroundImage>

      <PanelWrapper />
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    height: 'fit-content',
    position: 'sticky',
    top: -184,
    zIndex: 1,
  },
  backgroundPlaceholder: {
    height: 184,
    width: '100%',
    zIndex: 1,
    position: 'relative',
    opacity: 0,
    transition: 'opacity 0.15s ease-in-out',

    '&:hover': {
      opacity: 1,
    },
  },
}));
