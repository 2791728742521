import { Box, createStyles, Group, Text, TextInput } from '@mantine/core';
import React, { Dispatch, SetStateAction, useCallback } from 'react';

import { SpaceTreeNodeType } from '@portals/api/organizations';

import { CustomLabel } from '../../../../overview.types';
import {
  useIsNodeExpanded,
  useIsNodeSelected,
} from '../../organization-tree.context';

interface TreeNodeLabelProps {
  node: SpaceTreeNodeType;
  customLabel?: CustomLabel;

  // Edit mode
  isEditMode: boolean;
  onFinishEditing: () => Promise<void>;
  spaceNameInput: string;
  onSetSpaceNameInput: Dispatch<SetStateAction<string>>;
}

export function TreeNodeLabel({
  node,
  isEditMode,
  onFinishEditing,
  spaceNameInput,
  onSetSpaceNameInput,
  customLabel,
}: TreeNodeLabelProps) {
  const { classes, cx } = useStyles();
  const isSelected = useIsNodeSelected(node.id);
  const isExpanded = useIsNodeExpanded(node.id);

  const onSearchInputKeyDown = useCallback((event) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.target.blur();
    }
  }, []);

  const onSearchInputChange = useCallback(
    (e) => onSetSpaceNameInput(e.target.value),
    [onSetSpaceNameInput]
  );

  // Select all text for easy replacement upon editing mode toggle
  const onRef = useCallback((inputRef: HTMLInputElement) => {
    if (!inputRef) return;

    inputRef.select();
  }, []);

  return (
    <Box className={cx(classes.container)}>
      {isEditMode ? (
        <TextInput
          className="inline-space-name-input"
          autoFocus
          ref={onRef}
          value={spaceNameInput}
          onChange={onSearchInputChange}
          onKeyDown={onSearchInputKeyDown}
          onBlur={onFinishEditing}
          classNames={{
            root: classes.inputRoot,
            wrapper: classes.inputWrapper,
          }}
        />
      ) : (
        <Group
          align="center"
          p={0}
          spacing={10}
          className={classes.labelWrapper}
          grow
        >
          <Text
            className={cx('node-label', classes.label, {
              selected: isSelected,
              expanded: isExpanded,
            })}
            title={node.title}
          >
            {/*
            Input value & space name will be different only if name was edited & saved via API,
            meaning we're waiting for server response to update the name in store. Displaying the
            INPUT value as space's name while waiting for update, to prevent flickering of old
            name to new name
          */}
            <NodeLabel
              node={node}
              labelRenderer={customLabel}
              label={spaceNameInput}
            />
          </Text>
        </Group>
      )}
    </Box>
  );
}

interface NodeLabelProps {
  node: SpaceTreeNodeType;
  labelRenderer: CustomLabel;
  label: string;
}

function NodeLabel({ node, labelRenderer, label }: NodeLabelProps) {
  if (labelRenderer) {
    return labelRenderer({
      node,
      label,
    });
  }

  return <Box>{label !== node.title ? label : node.title}</Box>;
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  labelWrapper: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  label: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: theme.colors.gray[9],
    fontWeight: 300,
    fontSize: theme.fontSizes.sm,

    '&.expanded': {
      fontWeight: 500,
    },

    '&.selected': {
      color: theme.other.primaryColor,
    },
  },
  inputRoot: {
    marginRight: theme.spacing.xs,
  },
  inputWrapper: {
    input: {
      border: 'none',
      height: 24,
      minHeight: 24,
      padding: '0 5px',
    },
  },
  // Displayed only for root space
  avatar: {
    padding: 2,
    borderRadius: theme.radius.sm,
    marginRight: theme.spacing.xs,
    border: `1px solid ${theme.colors.gray[2]}`,
  },
}));
