import { MoveDevice } from './MoveDevice';
import { PremiumCommandOnMobile } from './PremiumCommandOnMobile';
import { SetDeviceName, type SetDeviceNameProps } from './SetDeviceName';
import {
  UpdateDeviceName,
  type UpdateDeviceNameProps,
} from './UpdateDeviceName';
import { desktopModals } from '../../desktop/modals';

export const mobileModals = {
  ...desktopModals,
  SetDeviceName,
  MoveDevice,
  UpdateDeviceName,
  PremiumCommandOnMobile,
};

export { type SetDeviceNameProps, UpdateDeviceNameProps };
