import React from 'react';

import { TicketDetailsType } from '@portals/api/organizations';
import {
  DetailsList,
  DetailsListProps,
  TicketStatusBadge,
} from '@portals/framework';
import { prettyTime } from '@portals/utils';

interface TicketInfoDetailsListProps {
  ticket: TicketDetailsType | undefined;
}

export function TicketInfoDetailsList({ ticket }: TicketInfoDetailsListProps) {
  const detailsList: DetailsListProps['items'] = [
    {
      label: 'Created by',
      value: ticket?.organization,
    },
    {
      label: 'Created',
      value: prettyTime(ticket?.created_at),
    },
    {
      label: 'Last Updated',
      value: prettyTime(ticket?.updated_at),
    },
    {
      label: 'ID',
      value: ticket?.id,
    },
    {
      label: 'Status',
      value: ticket?.status ? (
        <TicketStatusBadge status={ticket?.status} />
      ) : (
        '--'
      ),
    },
  ];

  return <DetailsList title="Information" items={detailsList} />;
}
