import React, { ReactNode } from 'react';

import { Responsive } from '@portals/core';

import { AuthDesktopLayout } from './AuthDesktopLayout';
import { OrganizationDashboardLayout } from './OrganizationDashboardLayout';
import {
  AuthMobileLayout,
  DashboardMobileLayout,
} from '../../../mobile/layouts/MobileLayout';

interface LayoutProps {
  children: ReactNode;
}

export function ResponsiveDashboardLayout({ children }: LayoutProps) {
  return (
    <Responsive>
      <Responsive.Desktop>
        <OrganizationDashboardLayout>{children}</OrganizationDashboardLayout>
      </Responsive.Desktop>

      <Responsive.Mobile>
        <DashboardMobileLayout>{children}</DashboardMobileLayout>
      </Responsive.Mobile>
    </Responsive>
  );
}

export function ResponsiveAuthLayout({ children }: LayoutProps) {
  return (
    <Responsive>
      <Responsive.Desktop>
        <AuthDesktopLayout>{children}</AuthDesktopLayout>
      </Responsive.Desktop>

      <Responsive.Mobile>
        <AuthMobileLayout>{children}</AuthMobileLayout>
      </Responsive.Mobile>
    </Responsive>
  );
}
