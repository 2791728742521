import { createStyles, Group, Paper, Stack, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { DeviceDetailsType } from '@portals/api/organizations';
import { DeviceAvatarWithIndicator } from '@portals/framework';

interface ChildDevicesProps {
  device: DeviceDetailsType;
}

export function DeviceChildren({ device }: ChildDevicesProps) {
  const { classes } = useStyles();
  const navigate = useNavigate();

  if (!device?.child_devices?.length) return null;

  return (
    <Paper p="xl" radius="xl" sx={{ overflow: 'hidden' }}>
      <Stack>
        <Group spacing="xs">
          <Text color="gray.9" size="sm">
            Child devices
          </Text>
          <Text color="gray.5">{` (${device.child_devices.length})`}</Text>
        </Group>

        {device.child_devices.map((childDevice) => (
          <Group
            key={childDevice.id}
            noWrap
            className={classes.childDeviceContainer}
            data-testid="child-device-container"
            onClick={() => navigate(`/devices/${childDevice.id}`)}
            px="xs"
          >
            <DeviceAvatarWithIndicator
              deviceName={childDevice.name}
              deviceStatus={childDevice.state?.status}
              deviceModelSettings={childDevice.model_settings}
              avatarProps={{ size: 'lg' }}
            />

            <div className={classes.textsContainer}>
              <Text
                truncate
                color="blue_gray.9"
                weight={600}
                data-testid="child-device-name-display"
              >
                {childDevice.name}
              </Text>
              <Text truncate size="xs" color="gray.6" weight={600}>
                {childDevice.device_model_name}
              </Text>
              <Text truncate size="xs" color="gray.5" mt="xs">
                {childDevice.display_path?.split?.('/')?.pop?.() || ''}
              </Text>
            </div>
          </Group>
        ))}
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  childDeviceContainer: {
    padding: `${theme.spacing.md} 0`,
    borderRadius: theme.radius.md,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
  textsContainer: {
    overflow: 'hidden',
  },
}));
