import {
  ActionIcon,
  Badge,
  Button,
  createStyles,
  Group,
  Menu,
  Text,
  Tooltip,
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { size, uniq } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateSpace, useUpdateSpace } from '@portals/api/organizations';
import { TOUR_STEPS_IDS, useConfirmationModal } from '@portals/framework';
import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit-2.svg';
import { ReactComponent as ElementPlus } from '@portals/icons/linear/element-plus.svg';
import { ReactComponent as Maintenance } from '@portals/icons/linear/maintenance.svg';
import { ReactComponent as More } from '@portals/icons/linear/more.svg';
import { ReactComponent as Setting2 } from '@portals/icons/linear/setting-2.svg';
import { ReactComponent as Snooze } from '@portals/icons/linear/snooze.svg';
import {
  generateUniqueDefaultSpaceNameInSiblings,
  searchTree,
} from '@portals/utils';

import { useCommonStyles } from './overview-header-panel.common';
import { canAdmin, canEdit } from '../../../../../lib/access';
import { ClaimDeviceButton } from '../../../../components/ClaimDeviceButton';
import { useOverviewContext } from '../../overview.context';
import { useCurrentSpace } from '../../overview.hooks';
import {
  DEFAULT_FONT_SIZE,
  getTextWidth,
  handleTextResize,
  handleTruncateSize,
  PATH_LENGTH_TO_TRUNCATE,
} from '../../overview.utils';
import { SpacePath } from '../overview-header-path';

export function OverviewHeaderPanel() {
  const { classes, theme } = useStyles();
  const commonStyles = useCommonStyles();
  const space = useCurrentSpace();

  const { ref: containerRef, width: containerWidth } = useElementSize();
  const { ref: rightButtonsRef, width: rightButtonsWidth } = useElementSize();

  const pathWidth = useMemo(() => {
    return getTextWidth({
      text: space?.tree_path_name,
      font: `${DEFAULT_FONT_SIZE}px ${theme.fontFamily}`,
      containerWidth,
      rightButtonsWidth,
    });
  }, [
    space?.tree_path_name,
    theme.fontFamily,
    containerWidth,
    rightButtonsWidth,
  ]);

  const fontSize = useMemo(() => {
    return handleTextResize({
      pathWidth,
      containerWidth,
      rightButtonsWidth,
    });
  }, [containerWidth, rightButtonsWidth, pathWidth]);

  const shouldTruncate = useMemo(
    () =>
      size(space?.path) > PATH_LENGTH_TO_TRUNCATE ||
      (size(space?.path) > PATH_LENGTH_TO_TRUNCATE &&
        handleTruncateSize({
          pathWidth,
          containerWidth,
          rightButtonsWidth,
        })),
    [space?.path, pathWidth, containerWidth, rightButtonsWidth]
  );

  const {
    setSpaceEditModeId,
    spaceEditModeId,
    isAdmin,
    organizationTree: { tree, setExpandedNodes },
  } = useOverviewContext();

  const updateSpace = useUpdateSpace();
  const createSpace = useCreateSpace();

  const navigate = useNavigate();
  const asyncConfirmationCheck = useConfirmationModal();

  const onSetMaintenance = async () => {
    if (!space?.state?.maintenance) {
      const isConfirmed = await asyncConfirmationCheck({
        title: `Maintenance Mode Activation`,
        description: `Maintenance mode will turn off all incident notifications. This will allow you to perform maintenance and updates without disrupting your work. Do you want to continue?`,
        confirmationLabel: 'Turn ON',
        cancelLabel: 'Cancel',
      });

      if (isConfirmed) {
        updateSpace.mutate({
          spaceId: space.id,
          updatedSpace: { maintenance: true },
        });
      } else {
        return;
      }
    } else {
      updateSpace.mutate({
        spaceId: space.id,
        updatedSpace: { maintenance: false },
      });
    }
  };

  const onAddWidgets = () => navigate(`/overview/${space.id}/config`);

  const onAddSpace = async () => {
    const parentSpace = searchTree(tree[0], space.id);

    if (!parentSpace) return;

    try {
      const newSpace = await createSpace.mutateAsync({
        parentSpaceId: space.id,
        newSpace: {
          name: generateUniqueDefaultSpaceNameInSiblings(parentSpace.children),
        },
      });

      setExpandedNodes((curr) => uniq([...curr, ...newSpace.path]));

      navigate(`/overview/${newSpace.id}`);
      setSpaceEditModeId(newSpace.id);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (spaceEditModeId && space.id !== spaceEditModeId) {
      setSpaceEditModeId(null);
    }
  }, [space.id, spaceEditModeId, setSpaceEditModeId]);

  return (
    <Group
      className={commonStyles.classes.container}
      position="apart"
      noWrap
      px={30}
      ref={containerRef}
    >
      <Group
        align="center"
        data-testid="overview-header-title"
        spacing="md"
        noWrap
      >
        <SpacePath
          containerWidth={containerWidth}
          space={space}
          fontSize={fontSize}
          pathWidth={pathWidth}
          truncate={shouldTruncate}
          rightButtonsWidth={rightButtonsWidth}
        />

        {spaceEditModeId || !canAdmin(space) ? null : (
          <Group spacing="md" noWrap>
            <Tooltip label="Update space name">
              <ActionIcon
                color="blue_gray.3"
                variant="subtle"
                size="lg"
                className="space-name-action-button"
                onClick={() => setSpaceEditModeId(space.id)}
              >
                <Edit />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Add new space">
              <ActionIcon
                color="blue_accent.4"
                variant="light"
                size="lg"
                className="space-name-action-btn"
                onClick={onAddSpace}
              >
                <Add />
              </ActionIcon>
            </Tooltip>
          </Group>
        )}
      </Group>

      <Group noWrap position="right" ref={rightButtonsRef}>
        {space?.state?.maintenance && canEdit(space) ? (
          <Tooltip label="Set maintenance OFF" withinPortal>
            <Badge
              color="amber"
              size="xl"
              radius="md"
              leftSection={<Maintenance />}
              onClick={onSetMaintenance}
              classNames={{
                root: classes.maintenanceRoot,
                leftSection: classes.maintenanceLeftSection,
                inner: classes.maintenanceRightSection,
              }}
            >
              Maintenance Mode
            </Badge>
          </Tooltip>
        ) : null}

        {space?.state?.snoozed &&
        !space.state?.maintenance &&
        canEdit(space) ? (
          <Badge
            color="amber"
            size="lg"
            leftSection={<Snooze />}
            classNames={{
              root: classes.maintenanceRoot,
              leftSection: classes.maintenanceLeftSection,
              inner: classes.maintenanceRightSection,
            }}
          >
            Snooze Mode
          </Badge>
        ) : null}

        {isAdmin || canAdmin(space) ? (
          <>
            <ClaimDeviceButton
              size="sm"
              spaceId={space.id}
              data-testid="claim-device-button"
              leftIcon={<Add color="white" />}
              data-tour-step-id={TOUR_STEPS_IDS.claimDeviceButton}
            >
              Claim device
            </ClaimDeviceButton>

            <Tooltip withinPortal label="Edit dashboard">
              <Button
                onClick={onAddWidgets}
                data-testid="edit-dashboard-button"
                variant="default"
              >
                <Group spacing="xs" noWrap>
                  <ElementPlus
                    width={16}
                    height={16}
                    color={theme.colors.blue_gray[8]}
                  />

                  <Text
                    span
                    color="blue_gray.8"
                    className={classes.editDashboard}
                  >
                    Edit dashboard
                  </Text>
                </Group>
              </Button>
            </Tooltip>

            <Menu position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  color="blue_gray.4"
                  data-tour-step-id={TOUR_STEPS_IDS.overviewHeaderMenu}
                >
                  <More />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                {space?.state?.maintenance ? null : (
                  <Menu.Item
                    onClick={onSetMaintenance}
                    icon={<Maintenance width={16} height={16} />}
                  >
                    <Tooltip withinPortal label="Set maintenance ON">
                      <Text color="blue_gray.8">Set Maintenance ON</Text>
                    </Tooltip>
                  </Menu.Item>
                )}

                <Menu.Item
                  onClick={() =>
                    navigate(`/overview/${space.id}/settings/space`)
                  }
                  icon={<Setting2 width={16} height={16} />}
                >
                  <Tooltip withinPortal label="Configure space">
                    <Text color="blue_gray.8">Settings</Text>
                  </Tooltip>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </>
        ) : null}
      </Group>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  maintenanceRoot: {
    color: theme.colors.gray[9],
    fontWeight: 300,
    overflow: 'unset',
    fontSize: theme.fontSizes.sm,
    cursor: 'pointer',
  },

  maintenanceRightSection: {
    [theme.fn.smallerThan('xl')]: {
      display: 'none',
    },
  },

  maintenanceLeftSection: {
    width: 18,

    svg: {
      width: 15,
      height: 15,
    },
  },

  editDashboard: {
    [theme.fn.smallerThan('xl')]: {
      display: 'none',
    },
  },
}));
