import { Button, Group, LoadingOverlay, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { FC, useCallback } from 'react';

import { DeviceDetailsType, useUpdateDevice } from '@portals/api/organizations';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';

import roomSrc from '../../assets/img/room.svg';

export interface UpdateDeviceNameProps {
  device: DeviceDetailsType;
}

export const UpdateDeviceName: FC<ModalProps<UpdateDeviceNameProps>> = ({
  closeMe,
  data,
}) => {
  const { device } = data;
  const updateDevice = useUpdateDevice();

  const form = useForm<{ name: string }>({
    initialValues: {
      name: device.name,
    },
  });

  const onSubmit = useCallback(
    async (values) => {
      await updateDevice.mutateAsync({
        deviceId: device.id,
        spaceId: device.space_id,
        updatedDevice: {
          name: values.name,
        },
      });

      closeMe();
    },
    [closeMe, device.id, device.space_id, updateDevice]
  );

  return (
    <ModalCenteredMediaLayout
      opened
      title="Name your device"
      onClose={closeMe}
      media={<img src={roomSrc} />}
    >
      <LoadingOverlay visible={updateDevice.isLoading} />

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack>
          <Group grow mb="md">
            <TextInput
              {...form.getInputProps('name')}
              placeholder="Device Name"
              required
            />
          </Group>

          <Group grow>
            <Button
              type="submit"
              disabled={!form.isValid || !form.values.name}
              loading={updateDevice.isLoading}
            >
              Submit
            </Button>
          </Group>
        </Stack>
      </form>
    </ModalCenteredMediaLayout>
  );
};
