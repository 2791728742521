import { useLocalStorage } from '@mantine/hooks';
import { useDispatch } from 'react-redux';
import { useEffectOnce, useSearchParam } from 'react-use';

import { useAuth } from '@portals/redux';
import { switchTenant } from '@portals/redux/actions/auth';
import { TenantType } from '@portals/types';

export function AutoTenantSwitcher() {
  const dispatch = useDispatch();
  const auth = useAuth();

  // Search param of "?org=orgId" -> will be signed in as that organization
  const orgIdSearchParam = useSearchParam('org');
  // Stored id of last signed-in used organization
  const [lastTenantId, setLastTenantId] = useLocalStorage({
    key: 'last_tenant_id',
    getInitialValueInEffect: false,
  });

  useEffectOnce(() => {
    // No "?org" search param -> do nothing
    if (!orgIdSearchParam) return;
    // No auth -> ...
    else if (!auth) {
      // Set current org id search param in local storage, so we would know which org to sign in as
      setLastTenantId(orgIdSearchParam);

      return;
    }

    // At this point we:
    // 1. Have "?org" search param
    // 2. User is authenticated

    // If user is not signed in as the organization from "?org" search param
    if (lastTenantId !== orgIdSearchParam) {
      // Switch the tenant to "?org" search param id
      dispatch(switchTenant({ id: orgIdSearchParam }, TenantType.Organization));
    }
  });

  return null;
}
