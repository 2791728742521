import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { TenantType } from '@portals/types';

import { useCommonConfig } from '../hooks/portal-config';

function Onboarded({ children }: { children: ReactNode }) {
  const { pathname, search } = useLocation();
  const config = useCommonConfig();

  const partner = config.data?.[TenantType.Partner];

  const isCompleted = partner?.onboarding_step === 'complete';
  const isOnboarding = pathname.startsWith('/onboarding');

  // we're not in the partners portal, no need to worry if the user completed the onboarding wizard
  if (!partner) {
    return <>{children}</>;
  }

  if (isCompleted) {
    // user completed the onboarding wizard AND accessed `/onboarding` route
    if (isOnboarding) {
      return <Navigate to={`/${search}`} replace />;
    } else {
      return <>{children}</>;
    }
  } else {
    // user did NOT complete the onboarding wizard AND accessed `/onboarding` route
    if (isOnboarding) {
      return <>{children}</>;
    } else {
      return <Navigate to="/onboarding" replace />;
    }
  }
}

export default Onboarded;
