import { Anchor, createStyles, Group, Paper, Stack, Text } from '@mantine/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  DEVICES_API_URL,
  devicesQueryKeys,
  DeviceType,
  useDeleteStateDump,
  useResetStateDumpAccess,
  useStateDumps,
} from '@portals/api/organizations';
import { CopyToClipboard, FileTypeIcon } from '@portals/core';
import { ReactComponent as CloseSquare } from '@portals/icons/linear/close-square.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { DateCell, PaginatedTable, RowMenuItems } from '@portals/table';
import {
  StateDumpType,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';

import { ManufacturerFileInfos } from './ManufacturerFileInfos';

interface DeviceModelFileInfosProps {
  device: DeviceType;
}

const getFileUrl = (accessToken: string) =>
  `${process.env.NX_SERVER_URL}external/dump_view/${accessToken}`;

export function DeviceModelFileInfos({ device }: DeviceModelFileInfosProps) {
  const { classes } = useStyles();

  const ref = useRef<HTMLDivElement | null>(null);
  const [maxWidth, setMaxWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (ref.current) {
      setMaxWidth(ref.current?.clientWidth);
    }
  }, []);

  const resetStateDumpAccess = useResetStateDumpAccess(device.id);
  const deleteStateDump = useDeleteStateDump(device.id);

  const columns = useMemo<Array<TableColumn<StateDumpType>>>(
    () => [
      {
        dataField: 'type',
        text: '',
        maxWidth: 72,
        minWidth: 72,
        formatter: (_, { mime_type }) => (
          <FileTypeIcon fileUrl={mime_type} width={33} />
        ),
      },
      {
        dataField: 'filename',
        text: 'Name',
        sort: true,
        filter: { type: TableFilterTypeEnum.Text },
        isSticky: true,
        formatter: (row, { filename, access_token, mime_type }) => (
          <Group position="apart">
            <Anchor href={getFileUrl(access_token)} target="_blank">
              {filename}
            </Anchor>

            <CopyToClipboard value={getFileUrl(access_token)} />
          </Group>
        ),
      },
      {
        dataField: 'access_count',
        text: 'Access Count',
        minWidth: 220,
        maxWidth: 220,
        sort: true,
        filter: { type: TableFilterTypeEnum.Number },
      },
      {
        dataField: 'access_token',
        text: 'Access Code',
        minWidth: 220,
        maxWidth: 220,
        formatter: (_, dump) => dump.access_token.slice(0, 8),
        sort: true,
        filter: { type: TableFilterTypeEnum.Text },
      },
      {
        dataField: 'mime_type',
        text: 'MIME type',
        sort: true,
        minWidth: 220,
        maxWidth: 220,
        filter: { type: TableFilterTypeEnum.Text },
      },
      {
        dataField: 'created_at',
        text: 'Created At',
        formatter: (_, dump) => (
          <DateCell date={dump.created_at} withTimeAgo={false} />
        ),
        sort: true,
        filter: { type: TableFilterTypeEnum.Date },
      },
    ],
    []
  );

  return (
    <Stack h="90%" spacing="xl">
      <Paper
        radius="lg"
        withBorder
        mt="xl"
        py="xxl"
        pl="xxl"
        pr="lg"
        ref={ref}
        pos="relative"
        className={classes.container}
      >
        <ManufacturerFileInfos device={device} maxWidth={maxWidth} />
      </Paper>

      <Paper
        radius="lg"
        withBorder
        p="xxl"
        ref={ref}
        h={700}
        className={classes.container}
      >
        <Stack h={650}>
          <Text fz="md" color="gray.9">
            Device uploaded files
          </Text>

          <PaginatedTable<StateDumpType>
            keyField="id"
            exportParams={{ isEnabled: false }}
            name={`${device.id}.state_dumps`}
            noColumnsSelection
            columns={columns}
            dataHook={useStateDumps}
            dataHookUrl={`${DEVICES_API_URL}/${device.id}/state_dumps`}
            dataHookQueryKey={devicesQueryKeys.stateDumps.all(device.id)}
            defaultSortBy={[{ id: 'created_at', desc: true }]}
            rowMenu={({ row, wrapperProps }) => (
              <RowMenuItems
                wrapperProps={wrapperProps}
                items={[
                  {
                    id: 'reset_access',
                    label: 'Reset Access',
                    color: 'red',
                    Icon: CloseSquare,
                    onClick: () => {
                      wrapperProps.menuRef?.onClose();
                      resetStateDumpAccess.mutate(row.original.id);
                    },
                  },
                  {
                    withTopDivider: true,
                    id: 'Delete',
                    label: 'Delete',
                    color: 'red',
                    Icon: Trash,
                    onClick: () => {
                      wrapperProps.menuRef?.onClose();
                      deleteStateDump.mutate(row.original.id);
                    },
                  },
                ]}
              />
            )}
            isUrlSyncEnabled={false}
            noDataIndication={{ title: 'No state dumps' }}
          />
        </Stack>
      </Paper>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    '&[data-with-border]': {
      border: `1px solid ${theme.colors.gray[2]}`,
    },
  },
}));
