import { Avatar, Box, Group, Select, Text } from '@mantine/core';
import { size } from 'lodash/fp';
import React, { forwardRef, useEffect } from 'react';
import { usePrevious } from 'react-use';

import { useClaimableDevicesModels } from '../../add-device-modal.hooks';

interface ModelFieldProps {
  partnerId: string;
  value: string;
  fieldName: string;
  setFieldValue: (name: string, value: string) => void;
}

interface ModelSelectItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string;
  value: string;
  label: string;
}

const ModelSelectItem = forwardRef<HTMLDivElement, ModelSelectItemProps>(
  ({ image, label, ...others }: ModelSelectItemProps, ref) => (
    <Box ref={ref} {...others} px="md" py="sm">
      <Group noWrap>
        <Avatar src={image} />

        <Text size="sm">{label}</Text>
      </Group>
    </Box>
  )
);

export function ModelField({
  partnerId,
  fieldName,
  value,
  setFieldValue,
}: ModelFieldProps) {
  const claimableDevices = useClaimableDevicesModels();
  const options = composeModelOptions(claimableDevices, partnerId);
  const isOnlyOneModel = size(options) === 1;

  const previousPartnerId = usePrevious(partnerId);

  useEffect(() => {
    if (previousPartnerId === partnerId) return;

    // If there's only one option, invoke onChange and setFieldValue once
    if (isOnlyOneModel) {
      const firstOptionValue = options[0]?.value;

      setFieldValue(fieldName, firstOptionValue);
    } else {
      setFieldValue(fieldName, '');
    }
  }, [
    fieldName,
    isOnlyOneModel,
    options,
    partnerId,
    previousPartnerId,
    setFieldValue,
  ]);

  return (
    <Select
      withinPortal
      searchable
      itemComponent={ModelSelectItem}
      data={options}
      label="Model"
      data-testid="select-model-dropdown"
      name="model"
      placeholder="Select model"
      required
      withAsterisk={false}
      disabled={isOnlyOneModel}
      readOnly={isOnlyOneModel}
      value={isOnlyOneModel ? options[0]?.value : value}
      onChange={(value) => setFieldValue(fieldName, value)}
      filter={(value, item) =>
        item.label?.toLowerCase().includes(value.toLowerCase().trim()) ?? false
      }
    />
  );
}

function composeModelOptions(
  claimableDevices: ReturnType<typeof useClaimableDevicesModels>,
  partnerId: string
) {
  const partnerModelsSorted = claimableDevices
    .filter((device) => device.partner.id === partnerId)
    .sort((a, b) =>
      a?.model?.toLowerCase() > b?.model?.toLowerCase() ? 1 : -1
    );

  return partnerModelsSorted.map((model) => ({
    value: model.id,
    label: model.model,
    image: model.user_settings.media?.img,
  }));
}
