import { Box, Button, createStyles, Modal, Stack, Text } from '@mantine/core';
import React from 'react';

import {
  useCecPartners,
  useOrganizationConfig,
  usePortalConfig,
} from '@portals/api/organizations';
import { ModalProps } from '@portals/framework';
import { useOpenModal } from '@portals/redux';

export interface AdminWelcomeVideoModalProps extends ModalProps {}

export function AdminWelcomeVideoModal({
  closeMe,
}: AdminWelcomeVideoModalProps) {
  const { classes } = useStyles();
  const config = useOrganizationConfig();
  const openModal = useOpenModal();
  const portalConfig = usePortalConfig();
  const cecPartners = useCecPartners();

  const showCecPartnersModal =
    portalConfig.isFetched &&
    cecPartners.isFetched &&
    portalConfig.data?.cec_enabled &&
    cecPartners.data?.length === 0;

  const onClose = () => {
    closeMe();

    if (showCecPartnersModal) {
      openModal('CecPartnersModal');
    }
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      classNames={{
        content: classes.modalContent,
        header: classes.modalHeader,
        body: classes.modalBody,
      }}
    >
      <Box className={classes.videoWrapper}>
        <iframe
          title="welcome-video"
          width="100%"
          height="370"
          src="https://www.youtube.com/embed/9egnq9ko_sE?&mute=1&autoplay=1"
          allowFullScreen
        />
      </Box>

      <Stack spacing="xs" align="center" px="xl">
        <Text size="lg">
          Welcome to {config.partner_display_name}'s device cloud
        </Text>
        <Text align="center">
          We’re happy you’re here. Discover the platform's full potential in
          just a few minutes. Watch this short video and unlock a world of
          productivity, collaboration, and success.
        </Text>
      </Stack>

      <Box className={classes.modalFooter} p="xl">
        <Button data-testid="video-cancel-button" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  modalContent: {
    backgroundColor: theme.black,
    borderRadius: theme.radius.lg,
    color: theme.colors.gray[2],
  },

  modalBody: {
    padding: 0,
  },

  modalHeader: {
    backgroundColor: theme.black,
  },

  modalFooter: {
    display: 'flex',
    justifyContent: 'end',
  },

  videoWrapper: {
    iframe: {
      border: 'none',
    },
  },
}));
