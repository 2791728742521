import {
  AccessLevelEnum,
  CommandType,
  DeviceModelIconName,
  DeviceStatusType,
  SupportedCommandType,
} from '@portals/types';

import { IncidentPriorityEnum } from '../incidents';

export interface DeviceType extends Record<string, any> {
  id: string;
  space_id: number;
  space_tree_path_name: string;
  config_version: number;
  x: number | null;
  status: DeviceStatusType;
  name: string;
  type: string;
  model_settings: {
    icon?: DeviceModelIconName;
    media?: {
      img?: string;
    };
    commands: Array<SupportedCommandType>;
    file_dumps: boolean;
  };
  child_devices_count: number;
  parent_device: RelatedDevice | null;
  test: boolean;
  details: any;
  firmware: {
    version: string | null;
    url: any;
    latest_version: string | null;
  };
  config: Record<string, any>;
  config_last_updated: string;
  config_schema: Record<string, any>;
  driver: any;
  state: {
    status: DeviceStatusType;
  } & Record<string, any>;
  incidents: any[];
  last_seen: string;
  custom_info: {
    prompt: any;
  };
  custom_info_version: number;
  connection_info: {
    access_key: string;
    hub_url: any;
    hub_url_static_cert: any;
    mqtt_hub_url: any;
  };
  access_level: AccessLevelEnum;
  snoozed_until: string | null;
  registration_failed_at: string | null;
  awaiting_replacement: boolean;
  has_attached_files: boolean;
  migrated_to_aws: boolean;

  tabs: {
    commands: boolean;
    configuration: boolean;
    dumps: boolean;
    files: boolean;
    licenses: boolean;
    state: boolean;
    telemetries: boolean;
    details: boolean;
    events: boolean;
  };
  supported_commands: Array<SupportedCommandType>;
  partner: {
    sn: null | any;
    mac: null | any;
    vendor: string;
    model: string;
    type_id: string;
    cloud_id?: string;
    sub_model?: string | null;
    logo: string | null;
  };
}

export interface RelatedDevice {
  id: string;
  name: string;
  space_id: number;
  display_path: string;
  device_model_name: string;
  state: {
    status: DeviceStatusType;
  } | null;
  model_settings: {
    icon?: DeviceModelIconName;
    media: {
      img: string;
    };
    commands: Array<SupportedCommandType>;
    file_dumps: boolean;
  };
}

export interface DeviceDetailsType extends DeviceType {
  child_devices: Array<RelatedDevice>;
  unresolved_tickets_count: number;
  partner: {
    sn: string;
    mac: string | null;
    cloud_id: string;
    vendor: string;
    model: string;
    sub_model: string | null;
    type_id: string;
    logo: string | null;
  };
}

export interface DeviceTelemetryType {
  id: string;
  created_at: string;
  device_timestamp: string;
  common: JSON;
}

export interface AwsDeviceTelemetryType {
  timestamp: string;
  status: string;
  override: boolean;
  telemetries: JSON;
}

export interface DeviceStateType {
  id: string;
  created_at: string;
  updated_at: string;
  state: DeviceType['state'];
}

export interface TelemetriesResponseType {
  offset: number;
  showing: string;
  telemetries: DeviceTelemetryType[];
}

export type DeviceTelemetriesResponseType = Record<
  string /* deviceId */,
  TelemetriesResponseType
>;

export interface CreateDeviceWarranty {
  name: string;
  description: string | null;
  warranty_document_url: string;
  start_date: string;
  end_date: string;
}

export type DeviceStateHistory = [Record<string, unknown>, string];

export enum WidgetDataRangeEnum {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Live = 'live',
  MonthToDate = 'monthToDate',
}

export interface ActiveCommandType extends CommandType {
  order: number;
}

export enum DeviceIncidentViewEnum {
  DevicesWithIncidents = 'devices_with_incidents',
  DevicesWithoutIncidents = 'incident_free_devices',
}

export interface DeviceIncidentRateFormType {
  title: string;
  deviceIncidentView: DeviceIncidentViewEnum;
  incidentPriorities: IncidentPriorityEnum[];
}

export enum DeviceModelBreakdownViewEnum {
  AllDevices = 'all_devices',
  OnlineDevices = 'online_devices',
}

export enum DevicesPerSpaceViewEnum {
  AllDevices = 'all_devices',
  OnlineDevices = 'online_devices',
}

export interface DeviceModelBreakdownFormType {
  title: string;
  deviceModelBreakdownView: DeviceModelBreakdownViewEnum;
}

export interface DevicesPerSpaceFormType {
  title: string;
  devicesPerSpaceView: DevicesPerSpaceViewEnum;
}

export interface DeviceUpdatesType {
  active_commands: Array<CommandType>;
  should_update_config: boolean;
}

export interface DeviceEventType {
  id: string;
  name: string;
  category: string;
  source: string;
  space_path: string | null;
  firmware_version: string;
  timestamp: string;
  additional_info: {
    int_key_1: number | null;
    int_key_2: number | null;
    string_key_1: string | null;
    string_key_2: string | null;
  };
}
