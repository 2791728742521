import { Carousel, CarouselProps, Embla } from '@mantine/carousel';
import {
  Button,
  createStyles,
  getStylesRef,
  Group,
  Modal,
} from '@mantine/core';
import React, { useState } from 'react';

import {
  freeTrialStep2Src,
  ModalProps,
  newOrganizationDashboardSrc,
} from '@portals/framework';

import { FreeTrialModalCard } from './FreeTrialModalCard';

export function FreeTrialModal({ closeMe }: ModalProps) {
  const { classes } = useStyles();
  const [embla, setEmbla] = useState<Embla | null>(null);

  const onNext = () => {
    if (!embla) return;

    if (embla.canScrollNext()) {
      embla.scrollNext();
    } else {
      closeMe();
    }
  };

  return (
    <Modal
      opened
      radius={15}
      padding={0}
      onClose={closeMe}
      withCloseButton={false}
      closeOnEscape={false}
      closeOnClickOutside={false}
      styles={{
        content: { width: 714, overflow: 'hidden' },
      }}
    >
      <Carousel
        withIndicators
        getEmblaApi={setEmbla}
        styles={carouselStyles}
        classNames={classes}
      >
        <Carousel.Slide>
          <FreeTrialModalCard
            imageSrc={newOrganizationDashboardSrc}
            title="Welcome to your 30-day free trial!"
            description={
              <>
                Congratulations! You've just started your 30-day free trial of
                our premium plan. This means you can now enjoy all the features
                that were previously unavailable to you.
                <br />
                <br />
                During your free trial, you'll have access to Xyte eXtended
                features such as Xyte eXtended integrations. Once your trial is
                up, you'll need to upgrade to our Xyte eXtended plan to continue
                using these features.
                <br />
                <br />
                If you have any questions or issues during your trial, please
                don't hesitate to contact our support team. We're here to help
                you make the most of your trial and answer any questions you may
                have.
              </>
            }
          />
        </Carousel.Slide>
        <Carousel.Slide>
          <FreeTrialModalCard
            imageSrc={freeTrialStep2Src}
            title="Advanced incidents integrations"
            description="Enhance your incident management process by seamlessly integrating with popular ticketing systems like ServiceNow and JIRA. Have Xyte report device-related incidents directly to these systems and streamline your incident management workflow"
          />
        </Carousel.Slide>
      </Carousel>

      <Group grow p="xl">
        <Button variant="default" onClick={closeMe}>
          Skip
        </Button>
        <Button onClick={onNext}>Continue</Button>
      </Group>
    </Modal>
  );
}

const carouselStyles: CarouselProps['styles'] = (theme) => ({
  indicator: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.colors.gray[3],

    '&[data-active]': {
      backgroundColor: theme.colors.gray[9],
    },
  },
  controls: {
    top: 160,
  },
});

const useStyles = createStyles<'controls' | 'root'>(() => ({
  controls: {
    ref: getStylesRef('controls'),
    transition: 'opacity 200ms ease',
    opacity: 0,
  },
  root: {
    '&:hover': {
      [`.${getStylesRef('controls')}`]: {
        opacity: 1,
      },
    },
  },
}));
