import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import { DEVICES_API_URL, getDeviceApiUrl } from './devices.constants';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { globalQueryKeys } from '../global-query-keys';
import { IncidentType } from '../incidents/incidents.types';
import { spacesQueryKeys } from '../spaces/spaces.constants';

function getDeviceIncidentsApiUrl(deviceId: string) {
  return `${getDeviceApiUrl(deviceId)}/incidents`;
}

function getDeviceIncidentUrl(deviceId: string, incidentId: string) {
  return `${getDeviceIncidentsApiUrl(deviceId)}/${incidentId}`;
}

type IncidentMutationType = Pick<
  IncidentType,
  'id' | 'title' | 'description' | 'priority'
>;

export function useUpdateIncident() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: '',
    method: 'PUT',
  });

  return useMutation({
    mutationFn: async ({
      deviceId,
      incident,
    }: {
      deviceId: string;
      incident: IncidentMutationType;
    }): Promise<IncidentType> =>
      fetchApiRequest(`${url}/${getDeviceIncidentUrl(deviceId, incident.id)}`, {
        ...options,
        body: JSON.stringify(incident),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(globalQueryKeys.devices);
      queryClient.invalidateQueries(spacesQueryKeys.base);
      dispatch(toastrSuccess('Updated incident successfully'));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateIncident',
      baseUrl: `${DEVICES_API_URL}/:id/incidents/:id`,
      method: 'PUT',
    },
  });
}

export function useCreateIncident() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation({
    mutationFn: async ({
      deviceId,
      incident,
    }: {
      deviceId: string;
      incident: Omit<IncidentMutationType, 'id'>;
    }): Promise<IncidentType> =>
      fetchApiRequest(`${url}/${getDeviceIncidentsApiUrl(deviceId)}`, {
        ...options,
        body: JSON.stringify(incident),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(globalQueryKeys.devices);

      setTimeout(() => {
        queryClient.invalidateQueries(spacesQueryKeys.base);
      }, 2000);

      dispatch(toastrSuccess('Created incident successfully'));
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateIncident',
      baseUrl: `${DEVICES_API_URL}/:id/incidents`,
      method: 'POST',
    },
  });
}

export function useCloseIncident() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: '',
    method: 'DELETE',
  });

  return useMutation({
    mutationFn: async ({
      incident,
      closed_reason,
    }: {
      incident: IncidentType;
      closed_reason: string;
    }): Promise<IncidentType> =>
      fetchApiRequest(
        `${url}/${getDeviceIncidentUrl(incident.device_id, incident.id)}`,
        {
          ...options,
          body: JSON.stringify({ closed_reason }),
        }
      ),
    onSuccess: (_, { incident }) => {
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(globalQueryKeys.devices);

      dispatch(toastrSuccess('Incident closed successfully'));

      setTimeout(() => {
        queryClient.invalidateQueries(spacesQueryKeys.base);
      }, 2000);
    },
    onError: ({ error }: any) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCloseIncident',
      baseUrl: `${DEVICES_API_URL}/:id/incidents/:id`,
      method: 'DELETE',
    },
  });
}
