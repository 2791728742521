import {
  ActionIcon,
  Anchor,
  Box,
  createStyles,
  Loader,
  Paper,
  Popover,
  PopoverProps,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useKey } from 'react-use';

import {
  DeviceModelAndPartnerSearchResultType,
  useSearchDeviceModelsAndPartners,
} from '@portals/api/organizations';
import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { ReactComponent as SearchNormal2 } from '@portals/icons/linear/search-normal 2.svg';
import { useOpenModal } from '@portals/redux';

import { DeviceModelSearchResult } from './DeviceModelSearchResult';
import { PartnerSearchResult } from './PartnerSearchResult';

const SEARCH_TERM_MIN_LENGTH = 2;

interface DeviceModelsAndPartnersSearchInputProps {
  onSearchResultSelected: (
    searchResult: DeviceModelAndPartnerSearchResultType
  ) => void;
}

export function DeviceModelsAndPartnersSearchInput({
  onSearchResultSelected,
}: DeviceModelsAndPartnersSearchInputProps) {
  const { theme, classes, cx } = useStyles();

  const openModal = useOpenModal();

  const searchResultsElementRefs = useRef<Record<string, HTMLDivElement>>({});

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<
    Array<DeviceModelAndPartnerSearchResultType>
  >([]);
  const [hoveredSearchResultId, setHoveredSearchResultId] = useState('');

  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);

  const { mutate: deviceModelsAndPartnersSearchResult, isLoading } =
    useSearchDeviceModelsAndPartners();

  const { deviceModels, partners } = useMemo(() => {
    const deviceModels: Array<DeviceModelAndPartnerSearchResultType> = [];
    const partners: Array<DeviceModelAndPartnerSearchResultType> = [];

    searchResults.forEach((result) => {
      if (result.type === 'device_model') {
        deviceModels.push(result);
      } else if (result.type === 'partner') {
        partners.push(result);
      }
    });

    return { partners, deviceModels };
  }, [searchResults]);

  useEffect(
    function onSearch() {
      if (!debouncedSearchTerm) return;

      if (debouncedSearchTerm.length >= SEARCH_TERM_MIN_LENGTH) {
        deviceModelsAndPartnersSearchResult(
          { searchTerm: debouncedSearchTerm },
          { onSuccess: setSearchResults }
        );
      } else {
        setSearchResults([]);
      }
    },
    [debouncedSearchTerm, deviceModelsAndPartnersSearchResult]
  );

  const onCleanSearchTerm = () => {
    setSearchTerm('');
    setSearchResults([]);
  };

  const scrollSearchResultElementIntoView = (searchResultId: string) => {
    const element = searchResultsElementRefs.current[searchResultId];

    if (element) {
      element.scrollIntoView({ block: 'nearest' });
    }
  };

  const onArrowDown = () => {
    // Clear the `hoveredSearchResultId` if the searchResult list is empty
    if (searchResults.length === 0) {
      setHoveredSearchResultId('');
      return;
    }

    let searchResultIdToHover: string;

    // If the `hoveredSearchResultId` is not set yet, set it to the first searchResult in the list
    if (!hoveredSearchResultId) {
      searchResultIdToHover = searchResults[0].id;

      setHoveredSearchResultId(searchResultIdToHover);
      scrollSearchResultElementIntoView(searchResultIdToHover);

      return;
    }

    const currentHoveredSearchResultIndex = searchResults.findIndex(
      (searchResult) => searchResult.id === hoveredSearchResultId
    );

    if (
      currentHoveredSearchResultIndex === -1 ||
      currentHoveredSearchResultIndex === searchResults.length - 1
    ) {
      // Set the `hoveredSearchResultId` to the first search result in the list
      searchResultIdToHover = searchResults[0].id;
    } else {
      // Set the `hoveredSearchResultId` to the next search result in `the list
      searchResultIdToHover =
        searchResults[currentHoveredSearchResultIndex + 1].id;
    }

    setHoveredSearchResultId(searchResultIdToHover);
    scrollSearchResultElementIntoView(searchResultIdToHover);
  };

  const onArrowUp = () => {
    // Clear the `hoveredSearchResultId` if the search results list is empty
    if (searchResults.length === 0) {
      setHoveredSearchResultId('');
      return;
    }

    let searchResultIdToHover: string;

    // If the `hoveredSearchResultId` is not set yet, set it to the last search result in the list
    if (!hoveredSearchResultId) {
      searchResultIdToHover = searchResults[searchResults.length - 1].id;

      setHoveredSearchResultId(searchResultIdToHover);
      scrollSearchResultElementIntoView(searchResultIdToHover);

      return;
    }

    const currentHoveredSearchResultIdToHoverIndex = searchResults.findIndex(
      (searchResult) => searchResult.id === hoveredSearchResultId
    );

    if (
      currentHoveredSearchResultIdToHoverIndex === -1 ||
      currentHoveredSearchResultIdToHoverIndex === 0
    ) {
      searchResultIdToHover = searchResults[searchResults.length - 1].id;
    } else {
      // Set the `hoveredSearchResultId` to the prev search result in the list
      searchResultIdToHover =
        searchResults[currentHoveredSearchResultIdToHoverIndex - 1].id;
    }

    setHoveredSearchResultId(searchResultIdToHover);
    scrollSearchResultElementIntoView(searchResultIdToHover);
  };

  const onEnter = () => {
    if (!hoveredSearchResultId) return;

    const selectedSearchResult = searchResults.find(
      (searchResult) => searchResult.id === hoveredSearchResultId
    );

    onSearchResultSelected(selectedSearchResult);
  };

  useKey('ArrowDown', onArrowDown, {}, [searchResults, hoveredSearchResultId]);
  useKey('ArrowUp', onArrowUp, {}, [searchResults, hoveredSearchResultId]);
  useKey('Enter', onEnter, {}, [hoveredSearchResultId]);

  const getRightSectionIcon = () => {
    if (isLoading) {
      return <Loader size={16} />;
    } else if (searchTerm) {
      return (
        <ActionIcon onClick={onCleanSearchTerm}>
          <CloseX width={16} height={16} color={theme.colors.gray[5]} />
        </ActionIcon>
      );
    } else {
      return (
        <SearchNormal2 width={16} height={16} color={theme.colors.gray[5]} />
      );
    }
  };

  return (
    <Popover
      styles={popoverStyles}
      opened={searchResults.length > 0 && !!searchTerm}
    >
      <Popover.Target>
        <TextInput
          w={400}
          value={searchTerm}
          placeholder="Search brand or model name..."
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          rightSection={getRightSectionIcon()}
        />
      </Popover.Target>

      <Popover.Dropdown>
        <Paper p="xs" w={400}>
          <Box className={classes.results}>
            {deviceModels && deviceModels.length > 0 ? (
              <Stack spacing="xs">
                <Text color="gray.5" weight={500} p="xs">
                  Models
                </Text>

                <Stack spacing="xs">
                  {deviceModels.map((deviceModel) => (
                    <Box
                      key={deviceModel.id}
                      ref={(el: HTMLDivElement) =>
                        (searchResultsElementRefs.current[deviceModel.id] = el)
                      }
                      className={cx({
                        [classes.hoveredItem]:
                          hoveredSearchResultId === deviceModel.id,
                      })}
                    >
                      <DeviceModelSearchResult
                        key={deviceModel.id}
                        deviceModel={deviceModel}
                        onDeviceModelSelected={onSearchResultSelected}
                      />
                    </Box>
                  ))}
                </Stack>
              </Stack>
            ) : null}

            {partners && partners.length > 0 ? (
              <Stack spacing="xs">
                <Text color="gray.5" weight={500} p="xs">
                  Brands
                </Text>

                <Stack spacing="xs">
                  {partners.map((partner) => (
                    <Box
                      key={partner.id}
                      ref={(el: HTMLDivElement) =>
                        (searchResultsElementRefs.current[partner.id] = el)
                      }
                      className={cx({
                        [classes.hoveredItem]:
                          hoveredSearchResultId === partner.id,
                      })}
                    >
                      <PartnerSearchResult
                        partner={partner}
                        onPartnerSelected={onSearchResultSelected}
                      />
                    </Box>
                  ))}
                </Stack>
              </Stack>
            ) : null}
          </Box>

          <Stack
            spacing={4}
            align="center"
            className={classes.searchResultsFooter}
          >
            <Text size="xs" weight={600}>
              Can't find your brand?
            </Text>
            <Text size="xs">Connect them now to add your devices!</Text>

            <Anchor
              size="xs"
              weight={600}
              onClick={() => openModal('CecPartnersModal')}
            >
              Connect
            </Anchor>
          </Stack>
        </Paper>
      </Popover.Dropdown>
    </Popover>
  );
}

const popoverStyles: PopoverProps['styles'] = (theme) => ({
  dropdown: {
    padding: 0,
  },
});

const useStyles = createStyles((theme) => ({
  results: {
    overflow: 'auto',
    maxHeight: 350,
  },

  searchResultsFooter: {
    borderTop: `1px solid ${theme.colors.gray[2]} `,
    padding: theme.spacing.sm,
    marginTop: theme.spacing.xs,
  },

  hoveredItem: {
    borderColor: theme.colors.gray[5],
    backgroundColor: theme.colors.gray[2],
  },

  selectedItem: {
    '&, &:hover': {
      backgroundColor: theme.colors.blue[0],
    },
  },
}));
