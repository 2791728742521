import { UseQueryOptions } from '@tanstack/react-query';
import { filter, uniq } from 'lodash/fp';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { SpaceType, useSpace } from '@portals/api/organizations';
import { expandNodesByDepth, searchTree } from '@portals/utils';

import { useSpacesTree } from '../../../lib/spaces';

export function useCurrentSpace(queryOptions?: UseQueryOptions<SpaceType[]>) {
  const params = useParams<{ spaceId?: string }>();

  return useSpace(Number(params.spaceId), queryOptions);
}

export function useOverviewOrganizationTree() {
  const tree = useSpacesTree();

  const currentSpace = useCurrentSpace();

  // Load expanded nodes from local storage if exists
  const [persistedExpandedNodes, setPersistedExpandedNodes] = useLocalStorage<
    number[]
  >('spacesTree.expandedNodes', expandNodesByDepth(tree, 3));

  const [expandedNodes, setExpandedNodes] = useState(
    uniq([...(persistedExpandedNodes || []), ...(currentSpace?.path || [])])
  );

  useEffect(
    function updatePersistedExpandedNodes() {
      // Remove nodes that are not present in the tree anymore
      const filteredNodes = filter(
        (nodeId) => Boolean(searchTree(tree[0], nodeId)),
        expandedNodes
      );

      setPersistedExpandedNodes(filteredNodes);
    },
    [tree, expandedNodes, setPersistedExpandedNodes]
  );

  return {
    tree,
    expandedNodes,
    setExpandedNodes,
  };
}
