import { createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import { usePortalConfig } from '@portals/api/organizations';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { useOpenModal } from '@portals/redux';

import { ReactComponent as Connect } from '../../../assets/img/icons/connect.svg';

export function ConnectToggle() {
  const { classes } = useStyles();

  const portalConfig = usePortalConfig();

  const openModal = useOpenModal();
  const openRouteModal = useOpenRouteModal();

  const onClick = () => {
    if (portalConfig.data?.cec_enabled) {
      openModal('CecPartnersModal');
    } else {
      openRouteModal({ modalId: 'connect' });
    }
  };

  return (
    <Stack
      spacing="xs"
      align="center"
      className={classes.item}
      onClick={onClick}
    >
      <Connect />

      <Text fz={10}>Connect</Text>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  item: {
    paddingBlock: theme.spacing.xs,
    borderRadius: theme.spacing.sm,
    transition: 'all 0.15s ease-in-out',
    cursor: 'pointer',
    color: theme.colors.blue_gray[6],

    svg: {
      flexShrink: 0,
    },

    ...theme.fn.hover({
      backgroundColor: theme.colors.gray[1],
    }),
  },
}));
