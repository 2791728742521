import { Box, createStyles } from '@mantine/core';
import { TreeNodeProps } from 'rc-tree';
import React from 'react';

import { SpaceTreeNodeType, useSpace } from '@portals/api/organizations';
import { ReactComponent as ArrowDown } from '@portals/icons/linear/arrow-down.svg';

import { useIsNodeExpanded } from '../organization-tree.context';

interface TogglerProps {
  node: TreeNodeProps<SpaceTreeNodeType>;
}

export function NodeToggler({ node }: TogglerProps) {
  const { classes, cx } = useStyles();
  const space = useSpace(node?.data?.id);
  const isExpanded = useIsNodeExpanded(node.data.id);

  if (!space) return null;

  return (
    <Box
      className={cx(classes.container, 'toggle', {
        [classes.expanded]: isExpanded,
      })}
    >
      <ArrowDown className="rst_toggleButtonIcon" />
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    cursor: 'pointer',
    zIndex: 1,
    background: theme.white,

    '.rst_toggleButtonIcon': {
      height: 15,
      width: 15,
      transform: 'rotate(-90deg)',
      transition: 'transform 0.15s ease-in-out',
    },
  },
  placeholder: {
    width: 15,
    height: 15,
    background: theme.white,
  },
  expanded: {
    '.rst_toggleButtonIcon': {
      transform: 'rotate(0deg)',
    },
  },
}));
