import {
  BackgroundImage,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
  ModalProps as MantineModalProps,
  Stack,
  Text,
} from '@mantine/core';
import React, { useDeferredValue, useMemo, useState } from 'react';

import { useCecPartners } from '@portals/api/organizations';
import { SearchInput } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { CecPartnerType } from '@portals/types';

import { C2CIntegrations } from './C2CIntegrations';
import { CecConnectionCard } from './CecConnectionCard';
import { ConfirmCecConnectionModal } from './ConfirmCecConnectionModal';
import headerBackgroundImageSrc from './header-bg-image.svg';
import findPartnerBackground from '../../../assets/img/find-partner-background.svg';

interface CecPartnersModalProps extends ModalProps {}

export function CecPartnersModal({ closeMe }: CecPartnersModalProps) {
  const { classes } = useStyles();

  const cecPartners = useCecPartners();

  const [selectedPartner, setSelectedPartner] = useState<CecPartnerType | null>(
    null
  );

  const [searchTerm, setSearchTerm] = useState('');
  const deferredSearchTerm = useDeferredValue(searchTerm);

  const isStale = searchTerm !== deferredSearchTerm;

  const filteredPartners = useMemo(() => {
    const partnersList = cecPartners.data || [];

    if (!deferredSearchTerm) return partnersList;

    const lowerSearchTerm = deferredSearchTerm.toLowerCase();

    return partnersList.filter((partner) => {
      const searchableContent =
        `${partner.display_name}__@@__${partner.about}`.toLowerCase();

      return searchableContent.includes(lowerSearchTerm);
    });
  }, [cecPartners.data, deferredSearchTerm]);

  return (
    <>
      <Modal.Root
        opened
        onClose={closeMe}
        size="100%"
        radius="lg"
        styles={modalStyles}
        padding={0}
      >
        <Modal.Overlay />

        <Modal.Content>
          <LoadingOverlay visible={cecPartners.isLoading} />

          <Modal.Header top={-122}>
            <BackgroundImage ta="center" pb={40} src={headerBackgroundImageSrc}>
              <Stack spacing="xl" pt={40}>
                <Modal.Title>Unlock Endless Possibilities</Modal.Title>

                <Text size="md" weight={500} color="gray.9">
                  Discover a World of Products and Services from Leading
                  Manufacturers, Personalized Just for You.
                </Text>
              </Stack>

              <SearchInput
                radius="md"
                size="md"
                mt="xl"
                mx="auto"
                w={440}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClear={() => setSearchTerm('')}
                loading={isStale}
              />

              <Modal.CloseButton
                variant="transparent"
                size="lg"
                data-testid="modal-close-button"
              />
            </BackgroundImage>
          </Modal.Header>

          <Modal.Body>
            <div className={classes.bodyContent}>
              <Stack spacing="xxl">
                {filteredPartners.length > 0 && (
                  <div className={classes.grid}>
                    {filteredPartners.map((partner) => (
                      <CecConnectionCard
                        key={partner.id}
                        logo={partner.logo}
                        searchTerm={deferredSearchTerm}
                        isConnected={partner.connected}
                        title={partner.display_name}
                        description={partner.about}
                        action={
                          <Button onClick={() => setSelectedPartner(partner)}>
                            Connect
                          </Button>
                        }
                      />
                    ))}
                  </div>
                )}

                <Stack>
                  <Text c="gray.6" ta="center">
                    Cloud to cloud integrations
                  </Text>

                  <div className={classes.grid}>
                    <C2CIntegrations
                      searchTerm={deferredSearchTerm}
                      closeCecPartnersModal={closeMe}
                    />
                  </div>
                </Stack>
              </Stack>

              <BackgroundImage p="xxl" radius="lg" src={findPartnerBackground}>
                <Group position="apart">
                  <Stack spacing="xs" c="gray.9">
                    <Text size="lg" weight={500}>
                      Can't find your manufacturer?
                    </Text>
                    <Text>
                      Let us know which manufacturer you'd like to see on our
                      platform.
                    </Text>
                  </Stack>

                  <Button variant="default">Suggest a Manufacturer</Button>
                </Group>
              </BackgroundImage>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>

      <ConfirmCecConnectionModal
        cecPartner={selectedPartner}
        onClose={() => setSelectedPartner(null)}
        closeCecPartnersModal={closeMe}
      />
    </>
  );
}

const useStyles = createStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(290px, 1fr))',
    gridAutoRows: 'minmax(290px, auto)',
    gap: theme.spacing.md,
    placeContent: 'start',
  },
  bodyContent: {
    display: 'grid',
    gridTemplateRows: '1fr auto',
    gap: theme.spacing.xxl,
    height: '100%',
    maxWidth: 1280,
    marginInline: 'auto',
    padding: theme.spacing.xxl,
  },
}));

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  content: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    backgroundColor: theme.colors.gray[0],
  },
  title: {
    fontSize: 36,
    fontWeight: 600,
    color: theme.colors.gray[9],
  },
  close: {
    position: 'absolute',
    top: theme.spacing.lg,
    right: theme.spacing.lg,
    zIndex: 1,
    color: theme.colors.gray[7],
  },
});
