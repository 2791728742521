import { Descope, getSessionToken } from '@descope/react-sdk';
import { LoadingOverlay } from '@mantine/core';
import jwtDecode from 'jwt-decode';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCreateOrganization } from '@portals/api/organizations';
import { signedIn } from '@portals/redux/actions/auth';
import { TenantType } from '@portals/types';

import {
  useCommonConfig,
  useCommonPortalConfig,
} from '../../hooks/portal-config';
import { sendDescopeErrorReport } from '../../utils/descope.utils';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
} from '../../utils/feature-flags.utils';

interface SignUpJWT {
  name: string;
  org: string;
  email: string;
}

export function SignUpForm() {
  const dispatch = useDispatch();
  const isReferral = !!localStorage.getItem('referral');

  const navigate = useNavigate();
  const portalConfig = useCommonPortalConfig();
  const createOrganization = useCreateOrganization();

  const config = useCommonConfig();
  const organizationConfig = config.data?.[TenantType.Organization];

  const isB2C =
    isCustomerFeatureAll(portalConfig.data?.b2c_view) ||
    isCustomerFeatureLabOnly(
      portalConfig.data?.b2c_view,
      organizationConfig?.lab
    );

  useEffect(() => {
    if (!isReferral && portalConfig.data?.signup !== true) {
      navigate('/auth/sign-in');
    }
  }, [isReferral, navigate, portalConfig.data?.signup]);

  return (
    <Descope
      flowId={isB2C ? 'sign-up-b-2-c' : 'sign-up-b-2-b'}
      onSuccess={async (e) => {
        const sessionToken = getSessionToken();

        const parsedJWT = jwtDecode<SignUpJWT>(e.detail.sessionJwt);

        try {
          const auth = await createOrganization.mutateAsync({
            org: parsedJWT.org,
            name: parsedJWT.name,
            email: parsedJWT.email,
            token: sessionToken,
          });

          dispatch(signedIn(auth));
          navigate('/');
        } catch (error) {
          console.error(error);
        }
      }}
      logger={{
        debug: (title: string, description?: string) => {
          return;
        },
        info: (title, description, state) => {
          sendDescopeErrorReport(state, 'Framework > SignUp');
        },
        warn: () => {
          return;
        },
        error: () => {
          return;
        },
      }}
    />
  );
}

export function SignUp() {
  const portalConfig = useCommonPortalConfig();

  if (!portalConfig.isFetched) return <LoadingOverlay visible />;

  return <SignUpForm />;
}
