import { ActionIcon, Group, Text, Tooltip } from '@mantine/core';
import { useOs } from '@mantine/hooks';
import { useKBar } from 'kbar';
import React from 'react';

import { ReactComponent as CommandSquare } from '@portals/icons/linear/command-square.svg';
import { ReactComponent as SearchNormal2 } from '@portals/icons/linear/search-normal 2.svg';

export function QuickSearch() {
  const { query } = useKBar();

  const os = useOs();

  return (
    <Tooltip
      withinPortal
      label={
        <Group>
          <Text>Search...</Text>
          <Group spacing={4}>
            {os === 'macos' ? (
              <CommandSquare width={16} height={16} />
            ) : (
              <Text>Ctrl</Text>
            )}
            <Text>+</Text>
            <Text>K</Text>
          </Group>
        </Group>
      }
    >
      <ActionIcon
        onClick={query.toggle}
        variant="transparent"
        size="lg"
        sx={(theme) => ({
          color: theme.colors.blue_gray[6],
        })}
      >
        <SearchNormal2 />
      </ActionIcon>
    </Tooltip>
  );
}
