import { ActionIcon, Menu } from '@mantine/core';
import { filter, find, isEmpty } from 'lodash/fp';
import React from 'react';

import {
  DeviceType,
  useSnoozeDevice,
  useSpaces,
  useUnsnoozeDevice,
  useWithSupportCenter,
} from '@portals/api/organizations';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { ReactComponent as Lifebuoy } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as Logout } from '@portals/icons/linear/logout-1.svg';
import { ReactComponent as More } from '@portals/icons/linear/more.svg';
import { ReactComponent as Notification } from '@portals/icons/linear/notification.svg';
import { ReactComponent as Snooze } from '@portals/icons/linear/snooze.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { suppressPropagation } from '@portals/utils';

import { DeviceSnoozeMenu } from './DeviceSnoozeMenu';
import { canEdit } from '../../../../../../../lib/access';
import {
  DeleteDeviceModalProps,
  MoveDeviceProps,
} from '../../../../../../modals';
import { ReportIncidentModalData } from '../../../../../../modals/ReportIncident';

interface DeviceCardActionsMenuProps {
  device: DeviceType;
}

export function DeviceCardMenu({ device }: DeviceCardActionsMenuProps) {
  const openModal = useOpenModal();
  const withSupportCenter = useWithSupportCenter();

  const snoozeDevice = useSnoozeDevice(device.id);
  const unsnoozeDevice = useUnsnoozeDevice(device.id);

  const spaces = useSpaces();

  const currentSpace = find({ id: device.space_id }, spaces.data);
  const availableSpaces = filter(
    (space) => canEdit(space) && space !== currentSpace,
    spaces.data
  );

  const canMove = canEdit(currentSpace) && !isEmpty(availableSpaces);

  const onDeleteClick = async () => {
    openModal<DeleteDeviceModalProps['data']>('DeleteDeviceModal', {
      devices: [device],
    });
  };

  return (
    <Menu withinPortal position="right-start">
      <Menu.Target>
        <ActionIcon onClick={suppressPropagation()}>
          <More />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          icon={<Danger />}
          onClick={suppressPropagation(() =>
            openModal<ReportIncidentModalData>('ReportIncident', { device })
          )}
        >
          Report Incident
        </Menu.Item>

        {withSupportCenter && (
          <Menu.Item
            icon={<Lifebuoy />}
            onClick={suppressPropagation(() =>
              openModal('CreateTicket', { device })
            )}
          >
            Open Ticket
          </Menu.Item>
        )}

        {device.snoozed_until ? (
          <Menu.Item
            icon={<Notification />}
            onClick={suppressPropagation(() => unsnoozeDevice.mutate())}
          >
            Turn on incident tracking
          </Menu.Item>
        ) : (
          <Menu.Item onClick={suppressPropagation()} icon={<Snooze />}>
            <DeviceSnoozeMenu
              onSnoozeSelect={(minutes) => snoozeDevice.mutate(minutes)}
            />
          </Menu.Item>
        )}

        <Menu.Divider />

        {canMove ? (
          <Menu.Item
            onClick={suppressPropagation(() =>
              openModal<MoveDeviceProps['data']>('MoveDevice', { device })
            )}
            icon={<Logout />}
          >
            Move Device
          </Menu.Item>
        ) : null}

        <Menu.Item
          color="red"
          icon={<Trash />}
          onClick={suppressPropagation(onDeleteClick)}
        >
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
