import { Group } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { isArray } from 'lodash';
import { castArray, flatMap, forEach } from 'lodash/fp';
import React, { useMemo } from 'react';

import {
  DEVICES_API_URL,
  DeviceType,
  spacesQueryKeys,
  useDevices,
} from '@portals/api/organizations';
import { DeviceStatusBadge } from '@portals/framework';
import { getDeviceName, RouteModalLink } from '@portals/framework/route-modals';
import { PaginatedTable } from '@portals/table';
import {
  GetColumnCountFnProps,
  GridView,
  TableColumn,
  TableFilterTypeEnum,
  TableState,
  TableViewModeType,
} from '@portals/types';
import { getFilterSearchParams } from '@portals/utils';

import { CustomDeviceCardDraggedLayer } from './device-card/CustomDeviceCardDraggedLayer';
import { DeviceCard } from './device-card/DeviceCard';
import { useOverviewContext } from '../../../overview.context';
import { useCurrentSpace } from '../../../overview.hooks';

const COLUMNS: Array<TableColumn<DeviceType>> = [
  {
    dataField: 'device.name',
    text: 'Name',
    formatter: (_, { name, id }) => (
      <RouteModalLink modalId="device" pathParams={[id]}>
        {getDeviceName(name)}
      </RouteModalLink>
    ),
  },
  {
    dataField: 'partner.model',
    text: 'Model',
  },
  {
    dataField: 'status',
    text: 'Status',
    formatter: (_, { status }) => (
      <Group>
        <DeviceStatusBadge status={status} />
      </Group>
    ),
    filter: {
      type: TableFilterTypeEnum.Select,
      hidden: true,
      options: {
        online: 'Online',
        offline: 'Offline',
        error: 'Error',
        unavailable: 'Unavailable',
      },
    },
  },
];

function getColumnsCount({ tableWidth }: GetColumnCountFnProps) {
  if (!tableWidth) return 3;

  if (tableWidth < 550) return 1;
  else if (tableWidth < 865) return 2;
  else if (tableWidth < 1200) return 3;

  return 4;
}

interface DevicesListProps {
  viewType: TableViewModeType;
  filters: TableState<DeviceType>['filters'];
  sortBy: TableState<DeviceType>['sortBy'];
}

function DeviceCardWrapper({ device }: { device: DeviceType }) {
  const { ref, width = 0 } = useElementSize();

  const adjustedWidth = Math.floor(width);

  return (
    <div ref={ref} key={adjustedWidth}>
      <DeviceCard device={device} />

      <CustomDeviceCardDraggedLayer width={adjustedWidth} device={device} />
    </div>
  );
}

export function DevicesList({ viewType, sortBy, filters }: DevicesListProps) {
  const overview = useOverviewContext();
  const space = useCurrentSpace();

  const filtersUrlSearchParams = useMemo(() => {
    const generateFilterSearchParams = (
      filters: TableState<DeviceType>['filters']
    ) => {
      return flatMap((filter) => {
        const newFilter = {
          ...filter,
        };

        return getFilterSearchParams(newFilter, {
          filter: {
            type: isArray(filter.value)
              ? TableFilterTypeEnum.Select
              : TableFilterTypeEnum.Text,
          },
        });
      }, filters);
    };

    const filtersSearchParams = generateFilterSearchParams(filters);
    const urlSearchParams = new URLSearchParams();

    forEach(({ value, id }) => {
      const currentFilterValue = castArray(value);

      forEach((filterValue) => {
        urlSearchParams.append(id, filterValue);
      }, currentFilterValue);
    }, filtersSearchParams);

    return urlSearchParams.toString() ? `&${urlSearchParams.toString()}` : '';
  }, [filters]);

  const sortUrlsSearchParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams();

    if (overview.isLocalDataLevel) {
      urlSearchParams.append('q[space_id_eq]', `${space.id}`);
      urlSearchParams.append('q[s]', `${sortBy[0].id}+asc`);
    } else {
      urlSearchParams.append(
        'q[space_path_ltree_starts_with]',
        `"${encodeURIComponent(space.path.join('.'))}"`
      );
      urlSearchParams.append('q[s]', `${sortBy[0].id}+asc`);
    }

    return urlSearchParams.toString();
  }, [sortBy, space.id, overview.isLocalDataLevel, space.path]);

  const dataHookUrl = `${DEVICES_API_URL}?${sortUrlsSearchParams}${filtersUrlSearchParams}`;

  const gridView = useMemo<GridView<DeviceType>>(
    () => ({
      getColumnsCount,
      itemRenderer: ({ item }) => (
        <DeviceCardWrapper device={item.original} key={item.original.id} />
      ),
    }),
    []
  );

  return (
    <PaginatedTable<DeviceType>
      // Adding a `key` prop to force re-render of the table upon filters change,
      // which also resets the current table page to 1
      key={dataHookUrl}
      noHeader
      pageSize={9}
      keyField="id"
      name="organizations.dashboard.devices"
      dataHook={useDevices}
      columns={COLUMNS}
      dataHookUrl={dataHookUrl}
      dataHookQueryKey={spacesQueryKeys.devices.all(space.id)}
      viewType={viewType}
      gridView={gridView}
      isUrlSyncEnabled={false}
      noDataIndication={{ title: 'No devices' }}
      noFilters
    />
  );
}
