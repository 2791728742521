import { ServiceSectionType } from './incident-routing.types';
import EditCiscoTeams from './integrations/EditCiscoTeams';
import EditEmail from './integrations/EditEmail';
import EditFreshService from './integrations/EditFreshService';
import EditGChat from './integrations/EditGChat';
import EditMsTeams from './integrations/EditMsTeams';
import EditSlack from './integrations/EditSlack';
import EmailLogo from '../../../../../../assets/img/logos/email.svg';
import FreshserviceLogo from '../../../../../../assets/img/logos/freshservice.svg';
import GChatLogo from '../../../../../../assets/img/logos/google-chat.svg';
import MSTeamsLogo from '../../../../../../assets/img/logos/microsoft-teams.svg';
import SlackLogo from '../../../../../../assets/img/logos/slack.svg';
import WebexLogo from '../../../../../../assets/img/logos/webex.svg';

export const INCIDENT_ROUTING_SCHEMA: Array<ServiceSectionType> = [
  {
    name: 'Incidents',
    description: 'Get notified about new incidents',
    id: 'incidents',
    children: [
      {
        id: 'email',
        name: 'Email',
        component: EditEmail,
        logoSrc: EmailLogo,
      },
      {
        id: 'slack',
        name: 'Slack',
        component: EditSlack,
        logoSrc: SlackLogo,
      },
      {
        id: 'gchat',
        name: 'Google Chat',
        component: EditGChat,
        logoSrc: GChatLogo,
      },
      {
        id: 'fresh_service',
        name: 'Freshservice',
        component: EditFreshService,
        logoSrc: FreshserviceLogo,
      },
      {
        id: 'msteams',
        name: 'Microsoft Teams',
        component: EditMsTeams,
        logoSrc: MSTeamsLogo,
      },
      {
        id: 'cisco_teams',
        name: 'Cisco Webex Teams',
        component: EditCiscoTeams,
        logoSrc: WebexLogo,
      },
    ],
  },
];
