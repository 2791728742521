import { Box, createStyles, LoadingOverlay, px } from '@mantine/core';
import { isEmpty } from 'lodash/fp';
import React, { Dispatch, SetStateAction, useState } from 'react';

import { expandNodesByDepth } from '@portals/utils';

import { useSpacesTree } from '../../../lib/spaces';
import {
  type SpacesTreeProps,
  Tree,
} from '../../pages/overview/overview-sidebar/organization-tree/Tree';
import { CustomLabel } from '../../pages/overview/overview.types';

interface SpaceTreeProps {
  selectedSpaceId: number | null;
  setSelectedSpaceId: Dispatch<SetStateAction<number | null>>;
  customLabel?: CustomLabel;
}

const ITEM_HEIGHT = 35;

export function OrganizationTree({
  selectedSpaceId,
  setSelectedSpaceId,
  customLabel,
}: SpaceTreeProps) {
  const { classes, theme } = useStyles();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const tree = useSpacesTree();

  const [expandedNodes, setExpandedNodes] = useState(
    expandNodesByDepth(tree || [], 3)
  );

  const onSelect: SpacesTreeProps['handleSelected'] = (spaceId) => {
    setSelectedSpaceId(spaceId);
  };

  return (
    <Box className={classes.container}>
      <LoadingOverlay visible={isEmpty(tree)} />

      {!isEmpty(tree) ? (
        <Tree
          readonly
          draggable={false}
          itemHeight={ITEM_HEIGHT}
          indent={px(theme.spacing.sm)}
          handleSelected={onSelect}
          itemIdPrefix="move-device"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          treeNodes={tree}
          selectedSpaceId={selectedSpaceId}
          expandedNodes={expandedNodes}
          setExpandedNodes={setExpandedNodes}
          customLabel={customLabel}
        />
      ) : null}
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',

    '.organization-tree-container': {
      padding: 0,

      '.rc-tree-list-holder': {
        paddingRight: theme.spacing.sm,

        '.tree-item': {
          '.tree-item-indicators': {
            display: 'none',
          },
        },
      },
    },
  },
  portal: {
    color: theme.colors.gray[6],
  },
  overlay: {
    cursor: 'not-allowed',
    right: -theme.spacing.sm,
  },
}));
