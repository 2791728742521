import {
  Alert,
  Box,
  createStyles,
  LoadingOverlay,
  MantineProvider,
  Text,
  useMantineTheme,
} from '@mantine/core';
import dayjs from 'dayjs';
import { isEmpty, map } from 'lodash/fp';
import React, { FC, useMemo } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import {
  useDevice,
  useDeviceLastKnownState,
  useNewDeviceWidgets,
  useWidgetColors,
} from '@portals/api/organizations';
import { ReactComponent as InfoCircle } from '@portals/icons/bold/info-circle.svg';

import { DeviceWidgetWrapper } from './device-widgets/DeviceWidgetWrapper';

const ResponsiveGridLayout = WidthProvider(Responsive);

interface OverviewWidgetsProps {
  modelId: string;
  deviceId: string;
}

export const OverviewWidgets: FC<OverviewWidgetsProps> = ({
  modelId,
  deviceId,
}) => {
  const style = useStyles();
  const deviceWidgets = useNewDeviceWidgets(modelId);

  const device = useDevice(deviceId, {
    staleTime: 5000,
    refetchInterval: 5000,
  });
  const isDeviceOffline =
    device.isFetched && device.data?.state?.status === 'offline';

  const deviceLastKnownState = useDeviceLastKnownState(deviceId, {
    enabled: isDeviceOffline,
  });

  const theme = useMantineTheme();
  const colors = useWidgetColors();

  const layout = useMemo(
    () =>
      map(({ id, config }) => {
        return {
          x: 0,
          y: 0,
          w: config.width,
          h: config.height,
          // `config.layout` holds the x & y coordinates of the widget
          ...config.layout,
          i: id,
          config,
          id,
        };
      }, deviceWidgets.data),
    [deviceWidgets.data]
  );

  const widgetsList = useMemo(
    () =>
      map(
        (widget) => (
          <div key={widget.id}>
            <DeviceWidgetWrapper
              widget={widget}
              deviceId={deviceId}
              isDeviceOffline={isDeviceOffline}
              modelId={modelId}
            />
          </div>
        ),
        deviceWidgets.data
      ),
    [deviceId, modelId, deviceWidgets.data, isDeviceOffline]
  );

  if (deviceWidgets.isFetched && isEmpty(deviceWidgets.data)) {
    return null;
  }

  if (!deviceWidgets.isFetched)
    return (
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <LoadingOverlay visible />
      </Box>
    );

  return (
    <>
      {isDeviceOffline && deviceLastKnownState.data ? (
        <Alert
          icon={<InfoCircle className={style.classes.infoIcon} />}
          color="gray.9"
          variant="filled"
          radius="md"
          className={style.classes.alert}
        >
          <Text size="sm">
            Please note, the data displayed below corresponds to the device's
            previous state, captured at its last known online timestamp:
            {` ${dayjs(device.data?.last_seen).format(
              'MMMM D, YYYY, hh:mm:ss'
            )}`}
          </Text>
        </Alert>
      ) : null}

      <MantineProvider
        theme={{
          ...theme,
          colors: { ...theme.colors, ...colors },
          primaryShade: 4,
          primaryColor: 'blue_accent',
        }}
      >
        <Box>
          <ResponsiveGridLayout
            breakpoint="lg"
            cols={{ lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 }}
            layouts={{ lg: layout }}
            margin={[16, 16]}
            rowHeight={25}
            containerPadding={[0, 10]}
            className="layout"
            isResizable={false}
            isDraggable={false}
            measureBeforeMount
            useCSSTransforms={false}
          >
            {widgetsList}
          </ResponsiveGridLayout>
        </Box>
      </MantineProvider>
    </>
  );
};

const useStyles = createStyles((theme) => ({
  alert: {
    position: 'sticky',
    zIndex: 999,
    top: theme.spacing.xs,
    boxShadow: `0 0.07rem 0.2rem rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 0 2.25rem 1.75rem -0.5rem,
    rgba(0, 0, 0, 0.1) 0 1rem 1rem -0.1rem`,
  },
  infoIcon: {
    width: 20,
    height: 20,
    color: theme.colors.amber[7],
  },
}));
