import { Button, Center, Stack, Text } from '@mantine/core';
import React from 'react';

import { usePermissionAccess } from '@portals/framework';

import CecPartnersEmptyStateSrc from '../../../../assets/img/cec-partners-empty-state.svg';

interface CecPartnersEmptyStateProps {
  handelConnectClick: VoidFunction;
}

export function CecPartnersEmptyState({
  handelConnectClick,
}: CecPartnersEmptyStateProps) {
  const { isAdmin } = usePermissionAccess();

  return (
    <Center>
      <Stack align="center" spacing="xs">
        <img src={CecPartnersEmptyStateSrc} />

        <Text size="lg" color="gray.8" mt="sm">
          No Available Brands
        </Text>

        {isAdmin ? (
          <Text color="gray.5" align="center" mb="xs">
            You can only claim devices from brands you have connected with.{' '}
            <br />
            Connect with more brands and get started with claiming devices.
          </Text>
        ) : (
          <Text color="gray.5" align="center">
            You can only claim devices from brands you have connected with.{' '}
            <br />
            Please contact your admin to connect with more brands and get
            started with claiming devices.
          </Text>
        )}

        {isAdmin ? (
          <Button onClick={handelConnectClick} size="md">
            Connect with brands
          </Button>
        ) : null}
      </Stack>
    </Center>
  );
}
