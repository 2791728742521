import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { keys } from 'lodash/fp';

import { IncidentType } from '@portals/api/organizations';

import {
  BarType,
  PRIORITY_NAMES,
  PRIORITY_NUMERIC_VALUES,
  PriorityNumericValue,
  TierType,
  TimeUnit,
} from '../../../overview.types';

dayjs.extend(duration);

export function convertTimeUnits({
  value,
  from,
  to,
}: {
  value: number;
  from: TimeUnit;
  to: TimeUnit;
}) {
  if (!value || value < 0) return 0;

  if (from === to) return value;

  const timeUnitToSeconds = {
    [TimeUnit.Seconds]: 1,
    [TimeUnit.Minutes]: 60,
    [TimeUnit.Hours]: 3600,
  };

  const valueInSeconds = value * timeUnitToSeconds[from];
  return valueInSeconds / timeUnitToSeconds[to];
}

export function scaleTimeUnitValue({
  value,
  from,
  to,
}: {
  value: number;
  from: TimeUnit;
  to: TimeUnit;
}) {
  if (!value || value < 0) return 0;

  if (from === to) return value;

  const timeUnitToSeconds = {
    [TimeUnit.Seconds]: 1,
    [TimeUnit.Minutes]: 60,
    [TimeUnit.Hours]: 3600,
  };

  return value * (timeUnitToSeconds[to] / timeUnitToSeconds[from]);
}

function setIncidentsCountPerPriority({
  incidents,
  priority,
}: {
  incidents: IncidentType[];
  priority: PriorityNumericValue;
}): number {
  let incidentCount = 0;

  incidents.forEach((incident) => {
    if (incident.priority === priority) {
      incidentCount++;
    }
  });

  return incidentCount;
}

export function filterIncidentsByAge({
  incidents,
  tiers,
}: {
  incidents: IncidentType[] | undefined;
  tiers: TierType[];
}): TierType[] {
  return tiers.map((tier) => {
    if (!incidents) {
      return {
        ...tier,
        incidentCount: 0,
      };
    }

    const filteredIncidents = incidents.filter((incident) => {
      const date1 = dayjs(new Date(incident.created_at).toISOString());
      const date2 = dayjs(new Date().toISOString());

      const incidentAgeInSeconds = date2.diff(date1, 'second');

      return (
        incidentAgeInSeconds <= tier.lastValue &&
        incidentAgeInSeconds >= tier.firstValue
      );
    });

    return {
      ...tier,
      incidentCount: filteredIncidents.length || 0,

      critical: setIncidentsCountPerPriority({
        incidents: filteredIncidents,
        priority: PRIORITY_NUMERIC_VALUES.critical,
      }),

      high: setIncidentsCountPerPriority({
        incidents: filteredIncidents,
        priority: PRIORITY_NUMERIC_VALUES.high,
      }),

      moderate: setIncidentsCountPerPriority({
        incidents: filteredIncidents,
        priority: PRIORITY_NUMERIC_VALUES.moderate,
      }),

      low: setIncidentsCountPerPriority({
        incidents: filteredIncidents,
        priority: PRIORITY_NUMERIC_VALUES.low,
      }),

      planning: setIncidentsCountPerPriority({
        incidents: filteredIncidents,
        priority: PRIORITY_NUMERIC_VALUES.planning,
      }),
    };
  });
}

export function shouldDisplayRadius(
  dataKey: BarType['dataKey'],
  tier: TierType
) {
  if (!tier[dataKey]) {
    return false;
  }

  const currentPriority = PRIORITY_NAMES[dataKey];

  return keys(PRIORITY_NAMES).every((key) => {
    const priorityNumericValue = PRIORITY_NAMES[key];
    if (priorityNumericValue < currentPriority) {
      return tier[key] === 0;
    }

    return true;
  });
}

const BELOW_ONE_HOUR_THRESHOLD_IN_MINUTES = 60;
const BELOW_SEVENTY_TWO_HOURS_THRESHOLD_IN_HOURS = 72;

export function formatMedian(median: number | null) {
  if (median === null) {
    return 'N/A';
  }

  const durationObj = dayjs.duration(median, 'seconds');
  const days = durationObj.days();
  const hours = durationObj.hours();
  const minutes = durationObj.minutes();
  const seconds = durationObj.seconds();

  if (days === 0) {
    if (hours === 0) {
      if (minutes < BELOW_ONE_HOUR_THRESHOLD_IN_MINUTES) {
        return `${minutes}m ${seconds}s`;
      }
    }

    if (hours < BELOW_SEVENTY_TWO_HOURS_THRESHOLD_IN_HOURS) {
      return `${hours}h ${minutes}m`;
    }
  }

  return `${days}d ${hours}h`;
}
