import { Box, Button, createStyles, Group, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';

import { ReactComponent as Confetti } from '../../assets/img/confetti.svg';

export interface TourCompletedModalProps
  extends ModalProps<{
    title: string;
    nextTourIcon: ReactNode;
    nextTourTitle: string;
    nextTourDescription: string;
    onNextTourClick: () => void;
  }> {}

export function TourCompletedModal({ closeMe, data }: TourCompletedModalProps) {
  const { classes } = useStyles();
  const {
    nextTourTitle,
    nextTourDescription,
    nextTourIcon,
    title,
    onNextTourClick,
  } = data;

  const onStartNextTourClick = () => {
    onNextTourClick();
    closeMe();
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      media={<Confetti />}
      mediaSize={43}
      title={title}
      description="Ready to learn more? Next tour is:"
      styles={{ inner: { zIndex: 201 } }}
      footer={
        <Group grow w="100%">
          <Button
            variant="default"
            data-testid="ill-do-it-later-button"
            onClick={closeMe}
            size="md"
          >
            I’ll do it later
          </Button>

          <Button
            data-testid="start-next-tour-button"
            onClick={onStartNextTourClick}
            size="md"
          >
            Start next tour
          </Button>
        </Group>
      }
    >
      <Group
        noWrap
        spacing="xl"
        bg="gray.0"
        className={classes.nextTourContainer}
      >
        <Box className={classes.iconContainer}>{nextTourIcon}</Box>
        <Box>
          <Text color="gray.9">{nextTourTitle}</Text>
          <Text color="gray.7" size="xs">
            {nextTourDescription}
          </Text>
        </Box>
      </Group>
    </ModalCenteredMediaLayout>
  );
}

const useStyles = createStyles((theme) => ({
  iconContainer: {
    borderRadius: theme.radius.md,
    padding: theme.spacing.xs,
    backgroundColor: theme.fn.rgba(theme.colors.indigo_accent[1], 0.3),

    svg: {
      width: 24,
      height: 24,
      color: theme.colors.indigo_accent[2],
    },
  },

  nextTourContainer: {
    padding: theme.spacing.md,
    borderRadius: theme.radius.lg,
    marginBottom: theme.spacing.md,
  },
}));
