import { ActionIcon, createStyles, Group, Text } from '@mantine/core';
import React, { FC } from 'react';

import {
  useOrganizationConfig,
  usePortalConfig,
} from '@portals/api/organizations';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
  UserSettings,
  useShouldDisplayOrganizationTenantMenu,
} from '@portals/framework';
import { ReactComponent as ArrowDown } from '@portals/icons/linear/arrow-down.svg';
import { ReactComponent as MenuIcon } from '@portals/icons/linear/menu.svg';
import { useOpenModal } from '@portals/redux';
import { TenantType } from '@portals/types';

import { ShoppingCartToggle } from '../../../../desktop/components/ShoppingCart';

interface DashboardHeaderProps {
  onOpen: () => void;
}

export const DashboardHeader: FC<DashboardHeaderProps> = ({ onOpen }) => {
  const { classes } = useStyles();
  const organization = useOrganizationConfig();
  const portalConfig = usePortalConfig();
  const shouldDisplayTenantMenu = useShouldDisplayOrganizationTenantMenu();

  const openModal = useOpenModal();

  return (
    <Group position="apart" noWrap className={classes.container}>
      <img
        width={42}
        height={42}
        alt="logo"
        src={
          portalConfig.data?.design?.navigation_menu?.logo ||
          'logo_full_dark.svg'
        }
      />

      {shouldDisplayTenantMenu && (
        <Group
          noWrap
          className={classes.tenantSwitchTarget}
          onClick={() => openModal('SwitchTenantModal')}
        >
          <Text size="lg" weight="600" truncate>
            {organization?.name}
          </Text>

          <ArrowDown width={17} height={17} />
        </Group>
      )}

      <Group spacing="lg" noWrap>
        {isCustomerFeatureAll(portalConfig.data?.b2c_view) ||
        isCustomerFeatureLabOnly(
          portalConfig.data?.b2c_view,
          portalConfig?.[TenantType.Organization]?.lab
        ) ? (
          <ShoppingCartToggle />
        ) : null}

        <UserSettings />

        <ActionIcon
          variant="transparent"
          color="gray.9"
          data-testid="open-menu-icon"
          onClick={onOpen}
        >
          <MenuIcon />
        </ActionIcon>
      </Group>
    </Group>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    height: theme.other.navbarHeight,
    paddingInline: theme.spacing.md,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    zIndex: 2,
    background: theme.white,

    '.user-settings-control': {
      borderLeft: 'none',
    },

    '.user-name': {
      margin: 0,
      paddingLeft: 0,
    },
  },
  tenantSwitchTarget: {
    overflow: 'hidden',
  },
}));
