import { toNumber } from 'lodash/fp';
import React, { useMemo, useState } from 'react';

import {
  useDeviceStateHistory,
  WidgetDataRangeEnum,
} from '@portals/api/organizations';
import {
  SplineChartWidget,
  SplineChartWidgetFormType,
} from '@portals/device-widgets';

import { WidgetProps } from '../device-widgets.types';

export function SplineChartWidgetWrapper({
  widget,
  deviceId,

  isDeviceOffline,
  lastUpdateTimestamp,
}: WidgetProps<SplineChartWidgetFormType>) {
  const [range, setRange] = useState<WidgetDataRangeEnum>(
    WidgetDataRangeEnum.Day
  );
  const deviceHistory = useDeviceStateHistory({
    deviceId,
    widgetDataRange: range,
    queryOptions: {
      enabled: !!deviceId,
      staleTime: 0,
    },
  });
  const fields = widget?.config?.fields;

  const { name, measurements, scale_type, number_format, num_of_decimals } =
    fields ?? {};

  const data = useMemo(() => {
    if (!deviceHistory.isFetched) return [];

    return deviceHistory.data?.map(([state, timestamp]) => ({
      timestamp,
      ...measurements.reduce((acc, measurement) => {
        acc[measurement.telemetry] = toNumber(state[measurement.telemetry]);

        return acc;
      }, {}),
    }));
  }, [deviceHistory.data, deviceHistory.isFetched, measurements]);

  return (
    <SplineChartWidget
      title={name}
      measurements={measurements}
      data={data}
      isLoading={deviceHistory.isLoading}
      range={range}
      id={widget.id}
      setRange={setRange}
      scaleType={scale_type}
      numberFormat={number_format}
      numOfDecimals={num_of_decimals}
      isDeviceOffline={isDeviceOffline}
      lastUpdateTimestamp={lastUpdateTimestamp}
    />
  );
}
