import { createStyles, Group, Image, Stack, Text } from '@mantine/core';
import React from 'react';

import { useOpenModal } from '@portals/redux';

import { WidgetPreviewProps } from '../../../../modals/WidgetPreview/WidgetPreview';
import { WIDGETS_MAP } from '../../overview.constants';
import { useOverviewContext } from '../../overview.context';
import { useCurrentSpace } from '../../overview.hooks';
import { DashboardComponent } from '../../overview.types';

interface WidgetPreviewCardProps {
  widget: DashboardComponent;
  handleClick: () => void;
}

export function WidgetPreviewCard({
  widget,
  handleClick,
}: WidgetPreviewCardProps) {
  const { classes } = useStyles();
  const openModal = useOpenModal();
  const space = useCurrentSpace();
  const overview = useOverviewContext();

  const onClickWrapper = () => {
    const widgetSettings = WIDGETS_MAP[widget.id];

    if (widgetSettings.withSettings) {
      openModal<WidgetPreviewProps['data']>('WidgetPreview', {
        widget,
        space,
        dataLevel: overview.dataLevel,
        onSave: (params) => overview.onAddWidget(widget, params),
        type: 'create',
      });
    } else {
      handleClick();
    }
  };

  return (
    <Stack
      className={classes.container}
      p="md"
      onClick={onClickWrapper}
      spacing="xl"
    >
      <Image
        height={186}
        width={186}
        src={widget.thumbnail}
        className={classes.thumbnail}
        fit="contain"
      />

      <Stack>
        <Group noWrap spacing="sm">
          <img
            width={28}
            height={28}
            src={widget.icon}
            className={classes.icon}
          />

          <Text
            size="sm"
            weight={500}
            data-testid={`widget-${widget.name}-title`}
          >
            {widget.name}
          </Text>
        </Group>

        <Text size="xs" className={classes.description}>
          {widget.description}
        </Text>
      </Stack>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: 222,
    height: 336,
    backgroundColor: theme.fn.rgba(theme.colors.blue_gray[0], 0.5),
    borderRadius: theme.radius.lg,
    cursor: 'pointer',
    boxShadow: '0px 11px 18px rgba(38, 50, 56, 0)',
    transition: 'box-shadow 0.15s ease-in-out',
    position: 'relative',
    display: 'grid',
    gridTemplateRows: '186px 1fr',

    '&:hover': {
      boxShadow: '0px 11px 18px rgba(38, 50, 56, 0.1)',
    },
  },
  thumbnail: {
    borderRadius: theme.radius.lg,

    figure: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  },
  description: {
    color: theme.colors.gray[7],
    fontWeight: 300,
  },
  premiumTag: {
    position: 'absolute',
    top: 10,
    right: 10,
  },

  icon: {
    borderRadius: theme.radius.md,
    boxShadow: '0px 4.5px 9px 0px rgba(0, 0, 0, 0.04)',
  },
}));
