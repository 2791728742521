import { Box, Button, createStyles, LoadingOverlay } from '@mantine/core';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import {
  GettingStartedToursNamesEnum,
  useCecPartners,
  useOnboardingTours,
  useOrganizationConfig,
  usePortalCapabilities,
  usePortalConfig,
  useSpace,
  useSpaces,
} from '@portals/api/organizations';
import { useCurrentUser } from '@portals/api/ui';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
  TOUR_STEPS_IDS,
  usePermissionAccess,
  Page403,
} from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { VerticalScrollBar } from '@portals/scrollbar';

import { OverviewDashboard } from './overview-dashboard/OverviewDashboard';
import { WidgetsPanel } from './overview-dashboard/widgets-panel/WidgetsPanel';
import { OverviewHeader } from './overview-header/OverviewHeader';
import { OverviewSettingsDrawer } from './overview-settings-drawer/OverviewSettingsDrawer';
import { OverviewSidebar } from './overview-sidebar/OverviewSidebar';
import { OverviewContextProvider } from './overview.context';
import { canView, noAccess } from '../../../lib/access';
import { findAccessible, findRoot } from '../../../lib/spaces';
import { ClaimDeviceTour } from '../../components/setup-tours/ClaimDeviceTour';
import { SetupSpacesTour } from '../../components/setup-tours/SetupSpacesTour';

export const Overview = () => {
  const spaces = useSpaces();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ spaceId: string; settingsTab: string }>();
  const { classes } = useStyles();
  const activeTourSearchParams = new URLSearchParams(location.search).get(
    'active_tour'
  );
  const onboardingTours = useOnboardingTours();
  const currentUser = useCurrentUser();
  const openModal = useOpenModal();
  const { isAdmin } = usePermissionAccess();
  const portalCapabilities = usePortalCapabilities();
  const organizationConfig = useOrganizationConfig();
  const portalConfig = usePortalConfig();
  const cecPartners = useCecPartners();

  const adminEmail = organizationConfig?.admin_user_email || '';

  const { rootSpace, accessibleNode } = useMemo(() => {
    return {
      rootSpace: findRoot(spaces.data || []),
      accessibleNode: findAccessible(spaces.data || []),
    };
  }, [spaces.data]);
  const selected = useSpace(Number(params.spaceId));

  const showWelcomeVideoModal =
    !isAdmin &&
    !currentUser.data?.settings?.isWelcomeVideosPlayed &&
    (isCustomerFeatureAll(portalCapabilities.onboarding_video) ||
      isCustomerFeatureLabOnly(
        portalCapabilities.onboarding_video,
        organizationConfig?.lab
      ));

  const showCecPartnersModal =
    portalConfig.isFetched &&
    cecPartners.isFetched &&
    portalConfig.data?.cec_enabled &&
    (cecPartners.data || []).length === 0 &&
    isAdmin &&
    location.state?.['afterLogin'];

  useEffectOnce(() => {
    if (showCecPartnersModal) {
      openModal('CecPartnersModal');
    }

    if (showWelcomeVideoModal) {
      openModal('WelcomeVideoModal');
    }
  });

  useEffect(
    function redirectIfNoSelectedSpace() {
      if ((!selected && !!spaces) || noAccess(selected)) {
        if (canView(rootSpace)) {
          if (activeTourSearchParams) {
            navigate(
              `/overview/${rootSpace?.id}?active_tour=${activeTourSearchParams}`,
              { replace: true }
            );
          } else {
            navigate(`/overview/${rootSpace?.id}`, { replace: true });
          }
        } else if (accessibleNode?.id) {
          if (activeTourSearchParams) {
            navigate(
              `/overview/${accessibleNode?.id}?active_tour=${activeTourSearchParams}`,
              { replace: true }
            );
          } else {
            navigate(`/overview/${accessibleNode.id}`, { replace: true });
          }
        }
      }
    },
    [
      spaces,
      rootSpace,
      accessibleNode,
      selected,
      navigate,
      activeTourSearchParams,
    ]
  );

  const getTourToStart = () => {
    if (activeTourSearchParams === GettingStartedToursNamesEnum.SetupSpaces) {
      return <SetupSpacesTour />;
    } else if (
      activeTourSearchParams === GettingStartedToursNamesEnum.ClaimDevice &&
      onboardingTours.getting_started?.setup_spaces_completed
    ) {
      return <ClaimDeviceTour />;
    } else {
      return null;
    }
  };

  if (noAccess(rootSpace) && noAccess(selected) && noAccess(accessibleNode)) {
    return (
      <Page403
        action={
          <Button onClick={() => window.open(`mailto:${adminEmail}`, '_blank')}>
            Contact Admin
          </Button>
        }
      />
    );
  }

  if (!selected) return <LoadingOverlay visible />;

  return (
    <OverviewContextProvider>
      {getTourToStart()}
      <Box className={classes.container}>
        <Box className={classes.tree}>
          <OverviewSidebar />
        </Box>

        <VerticalScrollBar
          renderView={(props) => (
            <Box {...props} data-tour-step-id={TOUR_STEPS_IDS.overview} />
          )}
        >
          <OverviewHeader />

          <div className={classes.content}>
            <OverviewDashboard />
          </div>
        </VerticalScrollBar>

        <OverviewSettingsDrawer />
        <WidgetsPanel />
      </Box>
    </OverviewContextProvider>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.colors.gray[0],
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '300px 1fr',
    gap: 0,
  },
  tree: {
    width: '100%',
    height: '100%',
    background: theme.white,
    borderRight: `1px solid ${theme.colors.gray[2]}`,
  },
  content: {
    width: '100%',
    height: '100%',
  },
}));
