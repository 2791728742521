import { Group } from '@mantine/core';
import React from 'react';

import {
  OverviewSettingsTabEnum,
  OverviewSettingsTabType,
} from './settings-drawer.types';
import { Access } from './tabs/access';
import { IncidentRoutingWrapper } from './tabs/incident-routing';
import { Space } from './tabs/space';
import { UpgradeTierHoverCard } from '../../../components/UpgradeTierHoverCard';

export const OVERVIEW_SETTINGS_TABS: Record<string, OverviewSettingsTabType> = {
  [OverviewSettingsTabEnum.Space]: {
    sidebarLabel: 'Space',
    tabLabel: 'Space',
    id: OverviewSettingsTabEnum.Space,
    component: <Space />,
  },
  [OverviewSettingsTabEnum.IncidentRouting]: {
    sidebarLabel: 'Incident Routing',
    tabLabel: (
      <Group align="center" spacing="lg">
        Incident Routing
        <UpgradeTierHoverCard source="Organization > Overview > Settings > Events" />
      </Group>
    ),
    id: OverviewSettingsTabEnum.IncidentRouting,
    component: <IncidentRoutingWrapper />,
    visible: ({ portalCapabilities }) => portalCapabilities.paid_plans === true,
  },
  [OverviewSettingsTabEnum.Access]: {
    sidebarLabel: 'Access',
    tabLabel: 'Access',
    id: OverviewSettingsTabEnum.Access,
    component: <Access />,
  },
};
