import {
  Badge,
  BadgeProps,
  Button,
  Center,
  createStyles,
  Stack,
  Text,
} from '@mantine/core';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRequestQsysReflectIntegration } from '@portals/api/organizations';
import { ModalCenteredMediaLayout } from '@portals/core';
import { useIntegrations } from '@portals/redux';
import { StateType } from '@portals/types';

import { CecConnectionCard } from './CecConnectionCard';
import msTeamsSrc from '../../../assets/img/c2c-integrations/ms_teams_vendor_connect.svg';
import qscReflectSrc from '../../../assets/img/c2c-integrations/qsc_reflect_vendor_connect.png';
import xioCloudSrc from '../../../assets/img/c2c-integrations/xio-rounded.svg';
import zoomSrc from '../../../assets/img/c2c-integrations/zoom_vendor_connect.svg';

interface IntegrationItem {
  id: keyof StateType['data']['integrations'];
  title: string;
  logo: string;
  description: string;
  href?: string;
  isComingSoon?: boolean;
}

const C2C_INTEGRATIONS: Array<IntegrationItem> = [
  {
    id: 'zoom',
    title: 'Zoom Rooms',
    logo: zoomSrc,
    description:
      'Zoom Rooms integration with a cloud IoT provider allows users to control and manage connected devices in a meeting room through the Zoom Rooms interface, and use device data to enhance the meeting experience, such as optimize audio and video quality.',
    href: '/settings/integrations/uc/zoom',
  },
  {
    id: 'mtr',
    title: 'Microsoft Teams Rooms',
    logo: msTeamsSrc,
    description:
      'Microsoft Teams Rooms is a platform for video meetings and conference calls that can be integrated with IoT cloud providers to connect IoT devices and provide enhanced collaboration and communication capabilities. Additionally, sensor data from IoT devices can be integrated to provide real-time insights and analytics.',
    href: '/settings/integrations/uc/mtr',
  },
  {
    id: 'xio',
    title: 'Crestron XiO Cloud',
    logo: xioCloudSrc,
    description: `Crestron XiO is a cloud-based platform that integrates Crestron devices with IoT providers for remote monitoring, control, and data collection. The integration process involves connecting to the provider's cloud platform and using their API.`,
    href: '/settings/integrations/monitoring/xio',
  },
  {
    id: 'q_sys',
    title: 'QSC Reflect',
    logo: qscReflectSrc,
    description:
      'QSC Reflect is a software that allows for integration of IoT devices with cloud providers by collecting and analyzing data from the devices and triggering actions in the cloud. It can connect to multiple cloud platforms such as AWS, Azure, and Google Cloud.',
    isComingSoon: true,
  },
];

interface C2CIntegrationsProps {
  searchTerm: string;
  closeCecPartnersModal: VoidFunction;
}

export function C2CIntegrations({
  searchTerm,
  closeCecPartnersModal,
}: C2CIntegrationsProps) {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const integrations = useIntegrations();
  const requestQsysReflectIntegration = useRequestQsysReflectIntegration();

  const [selectedComingSoonIntegration, setSelectedComingSoonIntegration] =
    useState<IntegrationItem | null>(null);

  const onConnectClick = (integration: IntegrationItem) => {
    if (integration.isComingSoon) {
      setSelectedComingSoonIntegration(integration);

      requestQsysReflectIntegration.mutate();
    } else if (integration.href) {
      closeCecPartnersModal();
      navigate(integration.href);
    }
  };

  return (
    <>
      {C2C_INTEGRATIONS.map((integration) => (
        <CecConnectionCard
          key={integration.id}
          logo={integration.logo}
          title={integration.title}
          description={integration.description}
          isConnected={integrations?.[integration.id]?.state === 'active'}
          searchTerm={searchTerm}
          action={
            <Button onClick={() => onConnectClick(integration)}>Connect</Button>
          }
        />
      ))}

      {selectedComingSoonIntegration && (
        <ModalCenteredMediaLayout
          opened
          onClose={() => setSelectedComingSoonIntegration(null)}
          title={
            <Stack align="center">
              <Badge size="lg" styles={badgeStyles}>
                Coming soon
              </Badge>
              <Text>{selectedComingSoonIntegration.title} is coming soon</Text>
            </Stack>
          }
          classNames={{
            header: classes.header,
            headerContent: classes.headerContent,
          }}
          mediaSize={80}
          media={
            <img
              src={selectedComingSoonIntegration.logo}
              alt={selectedComingSoonIntegration.title}
            />
          }
          footer={
            <Center>
              <Button
                size="md"
                w={160}
                onClick={() => setSelectedComingSoonIntegration(null)}
              >
                Got it
              </Button>
            </Center>
          }
        >
          <Text ta="center">
            Our Customer Support team will inform you once this integration is
            available. Stay tuned!
          </Text>
        </ModalCenteredMediaLayout>
      )}
    </>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
  },
  headerContent: {
    gap: theme.spacing.xs,
  },
}));

const badgeStyles: BadgeProps['styles'] = (theme) => ({
  root: {
    textTransform: 'none',
    backgroundColor: theme.fn.rgba(theme.colors.indigo[0], 0.6),
  },
  inner: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 400,
    color: theme.colors.gray[9],
  },
});
