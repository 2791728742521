import {
  createStyles,
  Group,
  HoverCard,
  HoverCardProps,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import {
  useFeatureFlags,
  useOrganizationConfig,
} from '@portals/api/organizations';
import { ReactComponent as Crown1 } from '@portals/icons/linear/crown-1.svg';

import { FreeTrialProgress } from './FreeTrialProgress';
import { StartFreeTrialButton } from './StartFreeTrialButton';

interface UpgradeTierHoverCardProps {
  source: string;
}

export function UpgradeTierHoverCard({ source }: UpgradeTierHoverCardProps) {
  const { classes } = useStyles();

  const organizationConfig = useOrganizationConfig();

  const showProgress = organizationConfig?.free_trial_status === 'in_progress';
  const featureFlags = useFeatureFlags();

  const getTitleAndDescription = () => {
    if (!featureFlags.paid_plans) {
      return {
        title: 'This feature is currently in beta',
        description: `Unlimited usage of this feature is subject to change after the beta period`,
      };
    }

    if (organizationConfig?.free_trial_status === 'available') {
      return {
        title: 'Start Free Trial To Activate',
        description:
          'To access this feature, you need to start your 30-day free trial.',
      };
    } else if (organizationConfig?.free_trial_status === 'in_progress') {
      return {
        title: 'Feature Trial Ending Soon',
        description: (
          <>
            You're almost out of time! Your free trial is ending in{' '}
            <Text span weight={700}>
              {organizationConfig?.remaining_trial_days} days.
            </Text>
            <br />
            Upgrade your plan to keep using it.
          </>
        ),
      };
    } else {
      return {
        title: 'Upgrade To Activate',
        description:
          'Your trial has ended. To access this feature again, please upgrade your plan',
      };
    }
  };

  return (
    <HoverCard
      withArrow
      withinPortal
      arrowSize={8}
      position="right"
      radius="md"
      width={450}
      styles={hoverCardStyles}
    >
      <HoverCard.Target>
        <Group className={classes.iconContainer}>
          <Crown1 width={16} height={16} />
        </Group>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Stack>
          <Stack spacing="xs">
            <Group spacing="xs">
              <Crown1 width={16} height={16} />
              <Text size="md" weight={600}>
                {getTitleAndDescription().title}
              </Text>
            </Group>

            <Text>{getTitleAndDescription().description}</Text>
          </Stack>

          {featureFlags.paid_plans ? (
            <>
              {showProgress && <FreeTrialProgress />}
              <Group position="right">
                <StartFreeTrialButton variant="subtle" />
              </Group>
            </>
          ) : null}
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

const hoverCardStyles: HoverCardProps['styles'] = (theme) => ({
  dropdown: {
    padding: theme.spacing.xl,
    color: theme.colors.blue_gray[9],
    filter: 'drop-shadow(-2px 3px 14px rgba(69, 90, 100, 0.25))',
  },
});

const useStyles = createStyles((theme) => ({
  iconContainer: {
    width: 25,
    height: 25,
    justifyContent: 'center',
    borderRadius: '50%',
    color: theme.white,
    background: theme.fn.linearGradient(
      85,
      theme.colors.blue[3],
      theme.colors.indigo_accent[7]
    ),
  },
}));
