import { OrganizationPortalCapabilities, TenantType } from '@portals/types';

import {
  useCommonConfig,
  useCommonPortalCapabilities,
} from '../../../../hooks/portal-config';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
} from '../../../../utils/feature-flags.utils';

export function useShouldDisplayOrganizationTenantMenu() {
  const portalCapabilities = useCommonPortalCapabilities();
  const config = useCommonConfig();
  const organizationConfig = config.data?.[TenantType.Organization];

  return !(
    isCustomerFeatureAll(
      (portalCapabilities as OrganizationPortalCapabilities)?.b2c_view
    ) ||
    isCustomerFeatureLabOnly(
      (portalCapabilities as OrganizationPortalCapabilities)?.b2c_view,
      organizationConfig?.lab
    )
  );
}
