import { DeviceWidgetNameType } from '@portals/device-widgets';

import { BarControllerWidgetWrapper } from './widgets/BarControllerWidgetWrapper';
import { BarIndicatorWidgetWrapper } from './widgets/BarIndicatorWidgetWrapper';
import { ButtonWidgetWrapper } from './widgets/ButtonWidgetWrapper';
import { DeviceLocationWidgetWrapper } from './widgets/device-location-widget/DeviceLocationWidgetWrapper';
import { GaugeIndicatorWidgetWrapper } from './widgets/GaugeIndicatorWidgetWrapper';
import { GroupedSwitchesWidgetWrapper } from './widgets/GroupedSwitchesWidgetWrapper';
import { NumericMetricWidgetWrapper } from './widgets/NumericMetricWidgetWrapper';
import { ScatterChartWidgetWrapper } from './widgets/ScatterChartWidgetWrapper';
import { SectionHeaderWidgetWrapper } from './widgets/SectionHeaderWidgetWrapper';
import { SplineChartWidgetWrapper } from './widgets/SplineChartWidgetWrapper';
import { StateControllerWidgetWrapper } from './widgets/StateControllerWidgetWrapper';
import { StatusWidgetWrapper } from './widgets/StatusWidgetWrapper';
import { ToggleWidgetWrapper } from './widgets/ToggleWidgetWrapper';
import { ValueWidgetWrapper } from './widgets/ValueWidgetWrapper';

export function getDeviceWidgetWrapperRenderer(
  deviceWidgetName: DeviceWidgetNameType
) {
  switch (deviceWidgetName) {
    case 'status':
      return StatusWidgetWrapper;

    case 'numeric_metric':
      return NumericMetricWidgetWrapper;

    case 'toggle':
      return ToggleWidgetWrapper;

    case 'button':
      return ButtonWidgetWrapper;

    case 'device_location':
      return DeviceLocationWidgetWrapper;

    case 'grouped_switches':
      return GroupedSwitchesWidgetWrapper;

    case 'bar_indicator':
      return BarIndicatorWidgetWrapper;

    case 'gauge_indicator':
      return GaugeIndicatorWidgetWrapper;

    case 'bar_controller':
      return BarControllerWidgetWrapper;

    case 'section_header':
      return SectionHeaderWidgetWrapper;

    case 'state_controller':
      return StateControllerWidgetWrapper;

    case 'spline_chart':
      return SplineChartWidgetWrapper;

    case 'scatter_chart':
      return ScatterChartWidgetWrapper;

    case 'value':
    default:
      return ValueWidgetWrapper;
  }
}
