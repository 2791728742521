import {
  Button,
  Group,
  LoadingOverlay,
  Select,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import { object, string } from 'yup';

import { SpaceType, useAddSpaceAccess } from '@portals/api/organizations';
import { ModalCenteredMediaLayout } from '@portals/core';
import { Members, ModalProps } from '@portals/framework';

export interface AddExternalUserAccessModalProps
  extends ModalProps<{
    space: SpaceType;
  }> {}

const ACCESS_LEVEL = [
  { value: '1', label: 'View' },
  { value: '2', label: 'Edit' },
  { value: '3', label: 'Admin' },
];

const schema = object({
  email: string().email().required(),
});

export function AddExternalUserAccessModal({
  closeMe,
  data: { space },
}: AddExternalUserAccessModalProps) {
  const addSpaceAccess = useAddSpaceAccess();

  const usersForm = useForm<{ email: string; level: string }>({
    initialValues: {
      email: '',
      level: null,
    },
    validate: yupResolver(schema),
  });

  const onSubmit = async (values: typeof usersForm.values) => {
    try {
      await addSpaceAccess.mutateAsync({
        spaceId: space.id,
        ...values,
      });

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title="Add External Access"
      media={<Members />}
    >
      <LoadingOverlay visible={addSpaceAccess.isLoading} />

      <form onSubmit={usersForm.onSubmit(onSubmit)}>
        <Stack spacing="xl">
          <TextInput
            label="User Email"
            type="email"
            placeholder="Enter email"
            required
            {...usersForm.getInputProps('email')}
          />

          <Select
            withinPortal
            label="Access Level"
            required
            placeholder="Select access level"
            data={ACCESS_LEVEL}
            {...usersForm.getInputProps('level')}
          />

          <Group grow>
            <Button variant="default" onClick={closeMe}>
              Cancel
            </Button>

            <Button type="submit">Add User</Button>
          </Group>
        </Stack>
      </form>
    </ModalCenteredMediaLayout>
  );
}
