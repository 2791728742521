import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';

import { UsePaginatedTableApiQuery } from '@portals/types';

import { INCIDENTS_API_URL, incidentsQueryKeys } from './incidents.constants';
import { IncidentsCountType, IncidentType } from './incidents.types';
import { useApiQuery } from '../../hooks';
import {
  fetchApiRequest,
  usePaginatedTableApiQuery,
  useRequestOptions,
} from '../../utils';
import { globalQueryKeys } from '../global-query-keys';

export function useIncidents(
  tableState: UsePaginatedTableApiQuery<IncidentType>['tableState'],
  columns: UsePaginatedTableApiQuery<IncidentType>['columns'],
  baseUrl: string = INCIDENTS_API_URL,
  queryKey: Array<string>
) {
  return usePaginatedTableApiQuery<IncidentType>({
    baseUrl,
    queryKey: queryKey
      ? [...queryKey, baseUrl, tableState]
      : [...globalQueryKeys.incidents, baseUrl, tableState],
    tableState,
    columns,
    queryOptions: {
      cacheTime: 0,
    },
  });
}

interface UseAllIncidentsParams {
  spaceTreePathName?: string;
  deviceId?: string;
  status?: string;
  spaceId?: number;
  perPage?: number;
  sortBy?: string;
}

// Hacky way to get around the "20 per page", when we need to fetch "all" data (fetching max 500)
export const useAllIncidents = (
  {
    spaceTreePathName,
    deviceId,
    status,
    spaceId,
    perPage = 500,
    sortBy,
  }: UseAllIncidentsParams = {},
  queryKey: Array<string> = []
): UseQueryResult<IncidentType[]> => {
  const { url, options } = useRequestOptions({
    url: INCIDENTS_API_URL,
  });

  const requestUrl = useMemo(() => {
    let finalUrl = `${url}?per_page=${perPage}&page=1`;

    if (spaceTreePathName) {
      finalUrl += `&q[space_tree_path_name_cont]=${spaceTreePathName}`;
    }

    if (deviceId) {
      finalUrl += `&q[device_id_eq]=${deviceId}`;
    }

    if (status) {
      finalUrl += `&q[status_eq]=${status}`;
    }

    if (spaceId) {
      finalUrl += `&q[device_space_id_eq]=${spaceId}`;
    }

    if (sortBy) {
      finalUrl += `&q[s]=${sortBy}+asc`;
    }

    return finalUrl;
  }, [url, perPage, spaceTreePathName, deviceId, status, spaceId, sortBy]);

  return useQuery({
    queryKey: [...incidentsQueryKeys.base, ...queryKey, requestUrl],
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (data) => data.data,
    staleTime: 3000,
    meta: {
      method: 'GET',
      baseUrl: INCIDENTS_API_URL,
    },
  });
};

export function useIncident(incidentId: string) {
  return useApiQuery<IncidentType>(
    `${INCIDENTS_API_URL}/${incidentId}`,
    incidentsQueryKeys.detail(incidentId),
    {
      enabled: !!incidentId,
      staleTime: 0,
      cacheTime: 0,
    }
  );
}

export const useIncidentsCount = () =>
  useApiQuery<IncidentsCountType>(
    `${INCIDENTS_API_URL}/count`,
    incidentsQueryKeys.count()
  );
