import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  createStyles,
  Divider,
  Group,
  Input,
  InputProps,
  Paper,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { every } from 'lodash/fp';
import React, { useCallback } from 'react';

import { SearchInput } from '@portals/core';
import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';

import { FieldsType } from './ExportDeviceStateHistoryModal';

interface SelectFieldsProps {
  onSetSearchTerm: (searchTerm: string) => void;
  searchTerm: string;
  onAddField: () => void;
  onCheckboxChange: (checked: boolean, fieldIndex: number) => void;
  onLabelChange: (label: string, fieldIndex: number) => void;
  fields: FieldsType[];
  onRemoveField: (index: number) => void;
  onSelectAll: (checked: boolean) => void;
}

export function SelectFields({
  onSetSearchTerm,
  searchTerm,
  onAddField,
  fields,
  onCheckboxChange,
  onLabelChange,
  onRemoveField,
  onSelectAll,
}: SelectFieldsProps) {
  const { classes, cx } = useStyles();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSetSearchTerm(event.target.value);
    },
    [onSetSearchTerm]
  );

  const onClear = useCallback(() => {
    onSetSearchTerm('');
  }, [onSetSearchTerm]);

  return (
    <Paper withBorder radius="lg" p="xl">
      <Stack>
        <Text color="blue_gray.9" size={18}>
          Select Fields
        </Text>

        <Text color="gray.6">
          Personalize your CSV export by selecting specific fields from the
          device's latest state and optionally include additional custom fields.
        </Text>

        <SearchInput
          value={searchTerm}
          placeholder="Search..."
          className={classes.searchInput}
          onClear={onClear}
          onChange={onChange}
        />

        <Checkbox
          checked={every({ isChecked: true }, fields)}
          label="Select all"
          onChange={(event) => onSelectAll(event.target.checked)}
        />

        <Divider />

        <Stack className={classes.fields} spacing="xs">
          {fields.map((field, index) => (
            <Group position="apart" key={index} className={classes.field}>
              <Group spacing="xs">
                <Checkbox
                  checked={field.isChecked}
                  onChange={(event) =>
                    onCheckboxChange(event.target.checked, index)
                  }
                />

                <Box>
                  <Input
                    autoFocus
                    placeholder="Enter telemetry key"
                    variant="unstyled"
                    value={field.id}
                    readOnly={!field.isCustom}
                    onChange={(event) =>
                      onLabelChange(event.target.value, index)
                    }
                    styles={inputStyles}
                    className={cx(classes.labelInput, {
                      readonly: field.isCustom,
                    })}
                  />
                </Box>
              </Group>

              {field.isCustom ? (
                <Tooltip label="Remove field" withArrow>
                  <ActionIcon onClick={() => onRemoveField(index)}>
                    <Trash />
                  </ActionIcon>
                </Tooltip>
              ) : null}
            </Group>
          ))}

          <Tooltip label="When requesting an additional data field make sure to type the field name accurately (case sensitive)">
            <Button
              variant="default"
              mih={34}
              leftIcon={<Add width={16} height={16} />}
              onClick={onAddField}
              mt="auto"
            >
              Add Field
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  fields: {
    overflow: 'scroll',
    overflowY: 'scroll',
    maxHeight: 150,
    minHeight: 150,
  },

  field: {
    paddingRight: theme.spacing.xl,
  },

  searchInput: {
    width: '100%',
  },

  labelInput: {
    '&.readonly > input': {
      '&:hover': {
        border: `1px solid ${theme.colors.gray[3]}`,
      },

      '&:focus': {
        border: `1px solid ${theme.colors.blue[5]} !important`,
      },
    },
  },
}));

const inputStyles: InputProps['styles'] = () => ({
  input: {
    height: 28,
    paddingLeft: 10,
  },
});
