import {
  Badge,
  createStyles,
  Group,
  Select,
  SelectProps,
  Textarea,
  TextInput,
} from '@mantine/core';
import React, { forwardRef, useMemo, useState } from 'react';

import { IncidentType, useUpdateIncident } from '@portals/api/organizations';
import { DetailsPanel } from '@portals/core';
import { DetailsList, PriorityIcon } from '@portals/framework';
import { IncidentPriorityLevel } from '@portals/types';
import {
  getPrioritiesList,
  Priority,
  suppressPropagation,
} from '@portals/utils';

interface IncidentEditDetailsPanelProps<TIncident> {
  incident: TIncident;
  discardEdit: () => void;
  closeDetailsPanel: () => void;
}

export function IncidentEditDetailsPanel<TIncident extends IncidentType>({
  incident,
  discardEdit,
  closeDetailsPanel,
}: IncidentEditDetailsPanelProps<TIncident>) {
  const { classes } = useStyles();

  const updateIncident = useUpdateIncident();

  const [title, setTitle] = useState(incident.title);
  const [description, setDescription] = useState(incident.description ?? '');
  const [priorityLevel, setPriorityLevel] = useState(incident.priority);

  const priorityOptions = useMemo<SelectProps['data']>(() => {
    return getPrioritiesList().map(({ id, name, color }) => ({
      label: name,
      color: color,
      value: String(id),
    }));
  }, []);

  const onSubmit = async () => {
    try {
      await updateIncident.mutateAsync({
        deviceId: incident.device_id,
        incident: {
          id: incident.id,
          title,
          description,
          priority: priorityLevel,
        },
      });

      closeDetailsPanel();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <DetailsPanel>
      <DetailsPanel.Header
        onClose={closeDetailsPanel}
        title={
          <Group noWrap>
            <PriorityIcon priorityLevel={priorityLevel} iconSize={36} />
            <TextInput
              data-testid="incident-title-input"
              size="md"
              className={classes.textInput}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Group>
        }
      />

      <DetailsPanel.Body>
        <DetailsPanel.Section title="Description">
          <Textarea
            data-testid="incident-description-textarea-input"
            minRows={15}
            maxRows={15}
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </DetailsPanel.Section>

        <DetailsList
          items={[
            {
              label: 'Priority',
              value: (
                <Select
                  searchable={false}
                  data={priorityOptions}
                  value={String(priorityLevel)}
                  onChange={(v) =>
                    setPriorityLevel(Number(v) as IncidentPriorityLevel)
                  }
                  itemComponent={PrioritySelectItem}
                />
              ),
            },
          ]}
        />
      </DetailsPanel.Body>

      <DetailsPanel.Footer>
        <DetailsPanel.Actions>
          <DetailsPanel.ActionButton onClick={discardEdit}>
            Discard
          </DetailsPanel.ActionButton>
          <DetailsPanel.ActionButton
            variant="filled"
            loading={updateIncident.isLoading}
            onClick={onSubmit}
            data-testid="save-changes-button"
          >
            Save Changes
          </DetailsPanel.ActionButton>
        </DetailsPanel.Actions>
      </DetailsPanel.Footer>
    </DetailsPanel>
  );
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  color: Priority['color'];
}

const PrioritySelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, color, onMouseDown, ...others }: ItemProps, ref) => {
    return (
      <Group
        grow
        ref={ref}
        onMouseDown={suppressPropagation(onMouseDown)}
        {...others}
      >
        <Badge radius="sm" size="lg" color={color}>
          {label}
        </Badge>
      </Group>
    );
  }
);

const useStyles = createStyles(() => ({
  textInput: {
    flexGrow: 1,
  },
}));
