import { Button, Group, LoadingOverlay, Tooltip } from '@mantine/core';
import { find, isEmpty, sortBy } from 'lodash/fp';
import React, { useState } from 'react';

import {
  DeviceType,
  useActiveDeviceCommands,
  useFeatureFlags,
} from '@portals/api/organizations';
import { ReactComponent as Crown2 } from '@portals/icons/bold/crown-2.svg';
import { useOpenModal } from '@portals/redux';
import {
  AccessLevelEnum,
  DeviceType as CommonDeviceType,
  SupportedCommandType,
} from '@portals/types';

import { EntityAccess } from '../../../../EntityAccess';
import { useSendCommandWithApproval } from '../../../../hooks/commands';
import { PremiumCommandLicenseModalProps } from '../../../../modals/license-to-premium-command/PremiumCommandLicenseModal';
import { SSHTunnelLoadingModalProps } from '../../../../modals/SSHTunnelLoadingModal';

interface CommandsProps<TDevice extends DeviceType | CommonDeviceType> {
  device: TDevice;
}

export function Commands<TDevice extends DeviceType | CommonDeviceType>({
  device,
}: CommandsProps<TDevice>) {
  const openModal = useOpenModal();
  const activeCommands = useActiveDeviceCommands(device.id);
  const sendCommandWithApproval = useSendCommandWithApproval();
  const featureFlags = useFeatureFlags();

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  if (isEmpty((device as DeviceType)?.supported_commands)) return null;

  const visibleCommands = sortBy(
    (command) => (command.premium && !command.has_license ? 1 : 0),
    (device as DeviceType)?.supported_commands.filter(
      (command: SupportedCommandType) => command.is_visible_in_panel
    )
  );

  if (isEmpty(visibleCommands)) {
    return null;
  }

  const onCommandSend = async (command: SupportedCommandType) => {
    if (command.premium && !command.has_license) {
      openModal<PremiumCommandLicenseModalProps['data']>(
        'PremiumCommandLicenseModal',
        {
          deviceId: device.id,
          commandId: command.id,
          commandName: command.name,
        }
      );
    } else {
      if (command.custom_fields?.length === 0 && !command.with_file) {
        //TODO: Remove this demo code block as it is only for demo purposes on ISE
        if (command.name === 'tunnel_demo' && featureFlags?.tunnel_demo) {
          setIsLoaderVisible(true);

          setTimeout(() => {
            setIsLoaderVisible(false);

            window.open(
              'https://eu1.tunnel.xyte.io/e2ef49cd-590b-4165-860b-e69e4c87d03f/config.html'
            );
          }, 3000);

          return;
        }

        try {
          const commandResponse = await sendCommandWithApproval({
            command,
            deviceIds: [device.id],
          });

          if (commandResponse[0]?.tunnel_authenticate_url) {
            if (!device) {
              return;
            }

            openModal<SSHTunnelLoadingModalProps['data']>(
              'SSHTunnelLoadingModal',
              {
                command: commandResponse[0],
                supportedCommand: command,
                deviceId: device.id,
                onSendCommand: onCommandSend,
              }
            );
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        openModal('RunCommand', {
          deviceType: device.partner.type_id,
          command,
          deviceIds: [device.id],
        });
      }
    }
  };

  const getIsCommandActive = (commandName: string) =>
    !!find({ name: commandName }, activeCommands.data);

  return (
    <Group>
      <LoadingOverlay visible={isLoaderVisible} />

      {visibleCommands.map((command) => {
        const isDisabled =
          !command.allow_duplicate_commands && getIsCommandActive(command.name);

        return (
          <EntityAccess
            key={command.id}
            id={command.id}
            minLevel={AccessLevelEnum.Edit}
            entity={device}
          >
            <Tooltip
              label="Once the queued or in progress command is sent you can send the command again"
              key={command.id}
              withinPortal
              withArrow
              multiline
              width={210}
              disabled={!isDisabled}
            >
              <div>
                <Button
                  maw="fit-content"
                  size="sm"
                  variant="light"
                  loading={isDisabled}
                  data-testid={`run-command-${command.friendly_name}`}
                  data-analyticsid={
                    command.premium && !command.has_license
                      ? 'locked'
                      : 'unlocked'
                  }
                  onClick={() => onCommandSend(command)}
                >
                  <Tooltip label={command.description}>
                    <Group spacing={4}>
                      {command.premium && !command.has_license ? (
                        <Crown2 width={18} height={18} />
                      ) : null}
                      {command.friendly_name}
                    </Group>
                  </Tooltip>
                </Button>
              </div>
            </Tooltip>
          </EntityAccess>
        );
      })}
    </Group>
  );
}
