import { LoadingOverlay } from '@mantine/core';
import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { usePortalConfig } from '@portals/api/organizations';
import { AuthenticationGuard } from '@portals/framework';
import { useAppConfig } from '@portals/framework/context';
import { useAuth } from '@portals/redux';
import { OrganizationConfigurationType } from '@portals/types';
import { getStyledThemeColor } from '@portals/utils';

import Header from '../components/layout/header';
import Sidebar from '../components/layout/sidebar';

interface MobileLayoutProps {
  children: ReactNode;
  isAuth?: boolean;
}

function MobileLayout({ children, isAuth = false }: MobileLayoutProps) {
  const { extraLayout } = useAppConfig<OrganizationConfigurationType>();
  const [isOpen, setIsOpen] = useState(false);
  const portalConfig = usePortalConfig({ enabled: isAuth });

  if (isAuth && !portalConfig.isFetched) return <LoadingOverlay visible />;

  return (
    <Container>
      {extraLayout?.globalLayout ? extraLayout.globalLayout() : null}

      <Header onOpen={() => setIsOpen(true)} isAuth={isAuth} />

      {isAuth ? null : (
        <Sidebar isOpen={isOpen} onClose={() => setIsOpen(false)} />
      )}

      <Content className="mobile-layout-container">{children}</Content>
    </Container>
  );
}

export function AuthMobileLayout({ children }: MobileLayoutProps) {
  const authentication = useAuth();

  if (authentication) return <Navigate to="/" replace />;

  return <MobileLayout isAuth>{children}</MobileLayout>;
}

export function DashboardMobileLayout({ children }: MobileLayoutProps) {
  const auth = useAuth();
  const navigate = useNavigate();
  const { extraLayout } = useAppConfig();
  const DataLayout = extraLayout?.dashboardDataLayout;

  useEffect(
    function redirectedAuthenticatedUser() {
      if (!auth?.token) return;

      const afterAuth = localStorage.getItem('afterAuth');

      if (!afterAuth || afterAuth === '/') return;

      localStorage.removeItem('afterAuth');

      navigate(afterAuth, { replace: true });
    },
    [auth?.token, navigate]
  );

  if (DataLayout) {
    return (
      <AuthenticationGuard>
        <DataLayout>
          <MobileLayout>{children}</MobileLayout>
        </DataLayout>
      </AuthenticationGuard>
    );
  }

  return (
    <AuthenticationGuard>
      <MobileLayout>{children}</MobileLayout>
    </AuthenticationGuard>
  );
}

const Container = styled.div`
  position: fixed;
  height: 100dvh;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: env(safe-area-inset-top);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  background-color: ${getStyledThemeColor('white')};
  overflow: auto;
  height: 100dvh;
`;
