import { Badge, createStyles, Group, Image, Paper, Text } from '@mantine/core';
import React from 'react';

import { TextWithTooltip } from '@portals/core';
import { ReactComponent as Gallery } from '@portals/icons/bold/gallery.svg';
import { ReactComponent as Clock2 } from '@portals/icons/linear/clock-2.svg';
import { LicenseType, PeriodEnum } from '@portals/types';

interface UnassignedLicenseCardProps {
  license: LicenseType;
  amountLeft: number;
  isSelected: boolean;
  onSelect: (license: LicenseType) => void;
}

export function UnassignedLicenseCard({
  license,
  amountLeft,
  isSelected,
  onSelect,
}: UnassignedLicenseCardProps) {
  const { classes, cx } = useStyles();

  const paymentIntervalText = () => {
    switch (license.product_payment_interval) {
      case PeriodEnum.OneTime:
        return 'One time';
      case PeriodEnum.Monthly:
        return 'Monthly';
      case PeriodEnum.Yearly:
        return 'Yearly';
    }
  };

  return (
    <Paper
      radius="md"
      p="xl"
      withBorder
      className={cx(classes.container, { [classes.selected]: isSelected })}
      onClick={() => onSelect(license)}
    >
      <Group position="apart" align="flex-start" noWrap>
        <Image
          radius="md"
          width={80}
          height={80}
          src={license.product_image ?? undefined}
          withPlaceholder
          placeholder={<Gallery />}
          styles={{
            root: { width: 80, height: 80 },
          }}
        />
        <Badge>{amountLeft} available</Badge>
      </Group>

      <div>
        <TextWithTooltip
          className={classes.productName}
          data-testid={`product-name-${license.product_name}`}
        >
          {license.product_name}
        </TextWithTooltip>
        <Text
          mb="xs"
          className={classes.partnerName}
          data-testid="partner-name"
        >
          by {license.partner_display_name}
        </Text>
      </div>

      <Text
        lineClamp={2}
        className={classes.productSubtitle}
        data-testid="product-subtitle"
      >
        {license.product_subtitle}
      </Text>

      <Group spacing={6} mt="auto" className={classes.paymentInterval}>
        <Clock2 width={16} height={16} />
        <Text inherit>{paymentIntervalText()}</Text>
      </Group>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.sm,
    userSelect: 'none',
    cursor: 'pointer',
    transition: 'border-color 150ms linear',
  },
  selected: {
    '&[data-with-border]': {
      borderColor: theme.fn.primaryColor(),
      borderWidth: 2,
    },
  },
  productName: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 600,
    color: theme.colors.gray[9],
  },
  productSubtitle: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[8],
  },
  partnerName: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 600,
    color: theme.colors.gray[5],
  },
  paymentInterval: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.gray[5],
  },
}));
