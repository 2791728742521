import { Descope, getSessionToken, useDescope } from '@descope/react-sdk';
import {
  Anchor,
  Center,
  Group,
  Loader,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { noop } from 'lodash/fp';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, Link, useMatch, useNavigate } from 'react-router-dom';

import { Pendo } from '@portals/analytics';
import { useCheckUserDomain, useSignIn } from '@portals/api/auth';
import { usePortalConfig } from '@portals/api/organizations';
import {
  descopeErrorTransformer,
  sendDescopeErrorReport,
} from '@portals/framework';
import { RegisteredWithDifferentPortalModalProps } from '@portals/framework/modals';
import { useAuth, useOpenModal } from '@portals/redux';
import { toastrError } from '@portals/redux/actions/toastr';
import { TenantType } from '@portals/types';

export function Signin() {
  const navigate = useNavigate();
  const descope = useDescope();
  const dispatch = useDispatch();

  const [isDescopeFlowLoading, setIsDescopeFlowLoading] = useState(true);

  const match = useMatch('/auth/sign-in/:partner_name?');

  const auth = useAuth();
  const openModal = useOpenModal();

  const signIn = useSignIn();
  const portalConfig = usePortalConfig();
  const checkUserDomain = useCheckUserDomain();

  const withSignup = portalConfig.data?.signup || false;

  const setRouteTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(
    function redirectedAuthenticatedUser() {
      if (!auth?.token) return;

      const path = localStorage.getItem('afterAuth') || '/';
      localStorage.removeItem('afterAuth');

      setRouteTimeout.current = setTimeout(() =>
        navigate(path, { replace: true })
      );

      return () => {
        if (setRouteTimeout.current) {
          clearTimeout(setRouteTimeout.current);
          setRouteTimeout.current = null;
        }
      };
    },
    [auth?.token, navigate]
  );

  useEffect(
    function redirectToChangePassword() {
      if (!auth?.change || !!auth?.token) return;

      setRouteTimeout.current = setTimeout(() =>
        navigate('/auth/change-password', { replace: true })
      );

      return () => {
        if (setRouteTimeout.current) {
          clearTimeout(setRouteTimeout.current);
          setRouteTimeout.current = null;
        }
      };
    },
    [auth?.change, auth?.token, navigate]
  );

  const isReferral = !!localStorage.getItem('referral');

  const isSignupVisible = isReferral || withSignup;

  const logger: React.ComponentProps<typeof Descope>['logger'] = {
    debug: noop,
    warn: noop,
    error: noop,
    info: (_title, _description, state) => {
      sendDescopeErrorReport(state, 'Framework > SignIn');

      if (state.error) {
        const email = state?.screen?.state?.form?.email;

        if (!email) {
          Pendo.track('Descope error - wrong login domain', {
            email: 'No email provided',
            domain: window.location.origin,
          });

          return;
        }

        checkUserDomain.mutate(
          { email },
          {
            onSuccess: ({ domain, partner_name }) => {
              if (!domain || domain === window.location.origin) {
                return;
              }

              openModal<RegisteredWithDifferentPortalModalProps['data']>(
                'RegisteredWithDifferentPortal',
                { domain, partnerName: partner_name }
              );
            },
          }
        );

        Pendo.track('Descope error - wrong login domain', {
          email,
          domain: window.location.origin,
        });
      }
    },
  };

  return (
    <Stack>
      <Group mb="lg" align="baseline" position="center">
        <Title order={1} className="auth-page-title" align="center">
          Sign-In
        </Title>

        {isSignupVisible ? (
          <Text color="dimmed" size="sm" align="center">
            <Group spacing="xs">
              Don't have an account yet?
              <Anchor
                data-testid="sign-up-button"
                size="sm"
                component={Link}
                to={generatePath('/auth/sign-up/:partner_name?', {
                  partner_name: match?.params?.partner_name || null,
                })}
              >
                Sign up
              </Anchor>
            </Group>
          </Text>
        ) : null}
      </Group>

      <LoadingOverlay visible={checkUserDomain.isLoading} />

      {isDescopeFlowLoading && (
        <Center>
          <Loader />
        </Center>
      )}

      <Descope
        flowId="sign-in-organization"
        onReady={() => setIsDescopeFlowLoading(false)}
        logger={logger}
        errorTransformer={descopeErrorTransformer}
        onSuccess={async (e) => {
          const sessionToken = getSessionToken();

          try {
            const signInResponse = await signIn.mutateAsync({
              token: sessionToken,
              tenant_type: TenantType.Organization,
            });

            Pendo.track('Successful sign-in (v1)', {
              email: signInResponse?.email,
              tenantType: TenantType.Organization,
            });
          } catch (error) {
            await descope.logout();
            await descope.refresh();
            dispatch(
              toastrError(
                'Failed to sign in',
                error?.data?.error || error?.data
              )
            );
          } finally {
            navigate('/', { state: { afterLogin: true } });
          }
        }}
      />
    </Stack>
  );
}
