import { ActionIcon, Tooltip } from '@mantine/core';
import React from 'react';

import { ReactComponent as New } from '@portals/icons/linear/new.svg';
import { OrganizationPortalCapabilities, TenantType } from '@portals/types';

import { useAppConfig } from '../../../context';
import { LaunchNotes } from '../../../external/LaunchNotes';
import {
  useCommonConfig,
  useCommonPortalCapabilities,
} from '../../../hooks/portal-config';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
} from '../../../utils/feature-flags.utils';

export const ProductUpdates = () => {
  const appConfig = useAppConfig();
  const portalCapabilities = useCommonPortalCapabilities();
  const config = useCommonConfig();
  const organizationConfig = config.data?.[TenantType.Organization];

  if (appConfig.tenantType === TenantType.Organization) {
    if (
      isCustomerFeatureAll(
        (portalCapabilities as OrganizationPortalCapabilities).b2c_view
      ) ||
      isCustomerFeatureLabOnly(
        (portalCapabilities as OrganizationPortalCapabilities).b2c_view,
        organizationConfig?.lab
      )
    )
      return null;
  }

  return (
    <LaunchNotes>
      {({ isActive, setIsActive }) => {
        return (
          <Tooltip label="What's new" withinPortal>
            <ActionIcon
              onClick={() => setIsActive(true)}
              variant="transparent"
              size="lg"
              id="launch-notes-toggle"
              sx={(theme) => ({
                color: isActive
                  ? theme.colors.blue_accent[4]
                  : theme.colors.blue_gray[6],
              })}
            >
              <New />
            </ActionIcon>
          </Tooltip>
        );
      }}
    </LaunchNotes>
  );
};
