import { find } from 'lodash/fp';
import { useMemo } from 'react';

import { SpaceType, useSpaces } from '@portals/api/organizations';
import { buildTreeFromNodes } from '@portals/utils';

export const findRoot = (spaces: SpaceType[]) =>
  find({ parent_id: null }, spaces);

export const findAccessible = (spaces: SpaceType[]) =>
  find('access_level', spaces);

export function useSpacesTree() {
  const spaces = useSpaces();

  return useMemo(() => buildTreeFromNodes(spaces.data || []), [spaces.data]);
}

interface UseSpaceTreeProps {
  spaceId: number | null;
  parentSpaceId: number | null;
}

export function useSpaceTree({ spaceId, parentSpaceId }: UseSpaceTreeProps) {
  const spaces = useSpaces();

  return useMemo(() => {
    const treeNodes = buildTreeFromNodes(spaces.data || [], parentSpaceId);

    if (spaceId === 1) {
      return treeNodes[0];
    }

    return treeNodes.filter((space) => space.id === spaceId)[0];
  }, [spaces.data, spaceId, parentSpaceId]);
}
