import {
  AddExternalGroupAccessModal,
  AddExternalGroupAccessModalProps,
} from './access/AddExternalGroupAccessModal';
import {
  AddExternalUserAccessModal,
  AddExternalUserAccessModalProps,
} from './access/AddExternalUserAccessModal';
import {
  AddInternalGroupAccessModal,
  AddInternalGroupAccessModalProps,
} from './access/AddInternalGroupAccessModal';
import {
  AddInternalUserAccessModal,
  AddInternalUserAccessModalProps,
} from './access/AddInternalUserAccessModal';
import {
  AddDeviceModal as AddDeviceModalV1,
  AddDeviceModalProps as AddDeviceModalPropsV1,
} from './add-device/AddDeviceModal';
import {
  AddDeviceModal,
  AddDeviceModalProps,
} from './add-device-v2/AddDeviceModal';
import {
  SelectModelFromPartnerModal,
  SelectModelFromPartnerModalProps,
} from './add-device-v2/SelectModelFromPartnerModal';
import {
  SelectModelModal,
  SelectModelModalProps,
} from './add-device-v2/SelectModelModal';
import {
  SelectPartnerOrDeviceModelModal,
  SelectPartnerOrDeviceModelModalProps,
} from './add-device-v2/SelectPartnerOrDeviceModelModal/SelectPartnerOrDeviceModelModal';
import { AddAddress, type AddAddressProps } from './AddAddress';
import { AddCreditCard, AddCreditCardProps } from './AddCreditCard';
import AddLicense from './AddLicense';
import { AddUserModal } from './AddUserModal';
import {
  AddUserTypeModal,
  type AddUserTypeModalProps,
  type InternalOrExternal,
} from './AddUserTypeModal';
import { AdminWelcomeVideoModal } from './AdminWelcomeVideoModal';
import { ArchiveCardModal, ArchiveCardModalProps } from './ArchiveCardModal';
import { CecPartnersModal } from './cec-partners/CecPartnersModal';
import {
  ChangeSpaceModal,
  type ChangeSpaceModalProps,
} from './ChangeSpaceModal';
import { CloseIncident } from './CloseIncident';
import { Connect } from './Connect';
import {
  ContactServiceProviderModal,
  ContactServiceProviderModalProps,
} from './ContactServiceProvider';
import { CreateTicket, CreateTicketProps } from './CreateTicket';
import {
  DeleteDeviceModal,
  DeleteDeviceModalProps,
} from './DeleteDeviceModal/DeleteDeviceModal';
import { DeleteUserModal } from './DeleteUserModal';
import { type DeviceTroubleshootingStepsProps } from './device-troubleshooting-steps/DeviceTroubleshootingStepsModal';
import { DismissSetupGuideModal } from './DismissSetupGuideModal';
import {
  ExportDeviceStateHistoryModal,
  ExportDeviceStateHistoryModalProps,
} from './export-device-state-history/ExportDeviceStateHistoryModal';
import { FailedCreditCard } from './FailedCreditCard';
import { FailedOrder } from './FailedOrder';
import FeatureFlags from './FeatureFlags';
import { FileInfoUploader, FileInfoUploaderProps } from './FileInfoUploader';
import { FreeTrialModal } from './free-trial/FreeTrialModal';
import {
  AddInternalUsersToNewGroupModal,
  type AddInternalUsersToNewGroupModalProps,
} from './groups/create-group/AddInternalUsersToNewGroupModal';
import { CreateNewGroupModal } from './groups/create-group/CreateNewGroupModal';
import {
  AddExternalUserModal,
  type AddExternalUserModalProps,
} from './groups/edit-group/AddExternalUserModal';
import {
  AddInternalUsersToExistingGroupModal,
  type AddInternalUsersToExistingGroupModalProps,
} from './groups/edit-group/AddInternalUsersToExistingGroupModal';
import {
  EditGroupModal,
  type EditGroupModalProps,
} from './groups/edit-group/EditGroupModal';
import { MoveDevice, type MoveDeviceProps } from './move-device/MoveDevice';
import { OnboardingToursCompletedModal } from './OnboardingToursCompletedModal';
import {
  PaymentsSuccessfullyTransferredModal,
  PaymentsSuccessfullyTransferredModalProps,
} from './PaymentsSuccessfullyTansferredModal';
import {
  PostPurchaseParametersModal,
  PostPurchaseParametersModalProps,
} from './post-purchase-parameters/PostPurchaseParametersModal';
import { PreventProductPurchase } from './PreventProductPurchase';
import { PriceQuoteLoader } from './PriceQuote/Loader';
import { PriceQuotePreview } from './PriceQuote/Preview';
import PurchaseShippingSuccess from './PurchaseShippingSuccess';
import { ReportIncident } from './ReportIncident';
import { RunCommand } from './RunCommand';
import { SpaceFileUploader } from './SpaceFileUploader';
import {
  SubscriptionCancellationFlowModal,
  type SubscriptionCancellationFlowModalProps,
} from './subscription-cancellation/SubscriptionCancellationFlowModal';
import {
  SubscriptionCancellationSuccessModal,
  type SubscriptionCancellationSuccessModalProps,
} from './SubscriptionCancellationSuccessModal';
import {
  SubscriptionRenewalModal,
  type SubscriptionRenewalModalProps,
} from './SubscriptionRenewalModal';
import {
  TourCompletedModal,
  type TourCompletedModalProps,
} from './TourCompletedModal';
import {
  TransferPaymentModal,
  TransferPaymentModalProps,
} from './TransferPaymentModal';
import {
  UnassignedLicenses,
  type UnassignedLicensesModalData,
} from './unassigned-licenses/UnassignedLicenses';
import {
  UpgradePricingPlanModal,
  type UpgradePricingPlanModalProps,
} from './UpgradePricingPlanModal';
import {
  UploadWarranty,
  UploadWarrantyModalData,
} from './upload-warranty/UploadWarranty';
import { WelcomeVideoAvailableLaterModal } from './WelcomeVideoAvailableLaterModal';
import { WelcomeVideoModal } from './WelcomeVideoModal';
import { WidgetPreview } from './WidgetPreview/WidgetPreview';

export const desktopModals = {
  AddDeviceModal,
  AddAddress,
  AddCreditCard,
  AddLicense,
  RunCommand,
  ReportIncident,
  CreateTicket,
  CloseIncident,
  FileInfoUploader,
  ContactServiceProviderModal,
  SpaceFileUploader,
  FailedCreditCard,
  ExportDeviceStateHistoryModal,
  TreeMoveDevice: MoveDevice,
  FailedOrder,
  PreventProductPurchase,
  MoveDevice,
  FeatureFlags,
  PurchaseShippingSuccess,
  Connect,
  PriceQuoteLoader,
  PriceQuotePreview,
  UnassignedLicenses,
  UploadWarranty,
  WidgetPreview,
  AddUserModal,
  EditGroupModal,
  AddUserTypeModal,
  AddExternalUserModal,
  AddInternalUsersToExistingGroupModal,
  CreateNewGroupModal,
  AddInternalUsersToNewGroupModal,
  AddExternalGroupAccessModal,
  AddInternalGroupAccessModal,
  AddExternalUserAccessModal,
  AddInternalUserAccessModal,
  UpgradePricingPlanModal,
  PostPurchaseParametersModal,
  FreeTrialModal,
  SubscriptionCancellationSuccessModal,
  SubscriptionRenewalModal,
  DeleteUserModal,
  TourCompletedModal,
  AdminWelcomeVideoModal,
  WelcomeVideoModal,
  OnboardingToursCompletedModal,
  DismissSetupGuideModal,
  SubscriptionCancellationFlowModal,
  WelcomeVideoAvailableLaterModal,
  TransferPaymentModal,
  PaymentsSuccessfullyTransferredModal,
  ArchiveCardModal,
  ChangeSpaceModal,
  DeleteDeviceModal,
  SelectPartnerOrDeviceModelModal,
  SelectModelFromPartnerModal,
  SelectModelModal,
  CecPartnersModal,
  AddDeviceModalV1,
};

export {
  type UnassignedLicensesModalData,
  type UploadWarrantyModalData,
  type MoveDeviceProps,
  type EditGroupModalProps,
  type AddUserTypeModalProps,
  type InternalOrExternal,
  type AddExternalUserModalProps,
  type AddInternalUsersToExistingGroupModalProps,
  type AddInternalUsersToNewGroupModalProps,
  type AddExternalGroupAccessModalProps,
  type AddInternalGroupAccessModalProps,
  type AddExternalUserAccessModalProps,
  type AddInternalUserAccessModalProps,
  type UpgradePricingPlanModalProps,
  type AddAddressProps,
  type PostPurchaseParametersModalProps,
  type SubscriptionCancellationSuccessModalProps,
  type SubscriptionRenewalModalProps,
  type ContactServiceProviderModalProps,
  type TourCompletedModalProps,
  type ExportDeviceStateHistoryModalProps,
  type SubscriptionCancellationFlowModalProps,
  type PaymentsSuccessfullyTransferredModalProps,
  type TransferPaymentModalProps,
  type ArchiveCardModalProps,
  type AddCreditCardProps,
  type FileInfoUploaderProps,
  type AddDeviceModalProps,
  type ChangeSpaceModalProps,
  type DeleteDeviceModalProps,
  type DeviceTroubleshootingStepsProps,
  type CreateTicketProps,
  type SelectPartnerOrDeviceModelModalProps,
  type SelectModelFromPartnerModalProps,
  type SelectModelModalProps,
  type AddDeviceModalPropsV1,
};
