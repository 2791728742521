import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Pendo } from '@portals/analytics';
import {
  useFeatureFlags,
  useOrganizationConfig,
} from '@portals/api/organizations';
import { useCurrentUser } from '@portals/api/ui';
import { useAuth } from '@portals/redux';

// This one is Organizations-specific, should be moved under `apps/organizations` when Org
// portal is decoupled from framework-based routing.
export function useAnalytics() {
  const location = useLocation();
  const auth = useAuth();
  const currentUser = useCurrentUser();
  const featureFlags = useFeatureFlags();
  const organizationConfig = useOrganizationConfig();

  useEffect(
    function initPendo() {
      if (auth) {
        Pendo.initializeAuthenticated({
          visitor: {
            id: auth?.email,
            firstName: auth.name?.split(' ')?.[0],
          },
          account: {
            id: auth?.tenant?.display_name,
            support_email:
              organizationConfig?.partner_support_email || 'support@xyte.io',
          },
        });
      } else {
        // Do not initialize pendo on sign-in page
        if (location.pathname.includes('/auth/sign-in')) return;

        Pendo.initializeAnonymous();
      }
    },
    [auth, location.pathname, organizationConfig?.partner_support_email]
  );

  useEffect(() => {
    if (currentUser.data) {
      Pendo.identify({
        visitor: {
          id: currentUser.data?.email,
          firstName: currentUser.data?.name?.split(' ')?.[0],
        },
        account: {
          id: currentUser.data?.organization_display_name,
          partner_tenant: organizationConfig?.partner_display_name,
          support_email:
            organizationConfig?.partner_support_email || 'support@xyte.io',
          customer_tenant_type:
            organizationConfig?.lab || organizationConfig?.is_test
              ? 'Test'
              : 'Live',
        },
      });
    }
  }, [
    currentUser.data,
    organizationConfig?.is_test,
    organizationConfig?.lab,
    organizationConfig?.partner_display_name,
    organizationConfig?.partner_support_email,
  ]);

  // useEffect(
  //   function trackPageViewPerPathname() {
  //     const userEmail = auth?.email;
  //
  //     const isSignInPage = location.pathname.includes('/auth/sign-in');
  //     const isAuthPage = ['/auth/sign-up', '/auth/reset-password'].some(
  //       (path) => location.pathname.includes(path)
  //     );
  //
  //     if (isSignInPage || (!userEmail && !isAuthPage)) return;
  //
  //     Pendo.trackPageView(location.pathname, userEmail);
  //   },
  //   [auth?.email, location.pathname]
  // );

  useEffect(
    function trackFeatureFlags() {
      if (featureFlags) {
        Pendo.track('featureFlags', featureFlags);
      }
    },
    [featureFlags]
  );
}
