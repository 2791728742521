import {
  ActionIcon,
  Box,
  Button,
  createStyles,
  Group,
  Indicator,
  Popover,
  Text,
} from '@mantine/core';
import { map, sum } from 'lodash/fp';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import { ReactComponent as ShoppingCartIcon } from '@portals/icons/linear/shopping-cart.svg';

import { ExternalStoreShoppingCart } from './external-store-shopping-cart/ExternalStoreShoppingCart';
import { useCartProductsList } from '../../../redux/hooks/store';

interface ShopNavbarProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}

export function ExternalStoreNavbar({ setIsOpen, isOpen }: ShopNavbarProps) {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const cartProductsList = useCartProductsList();
  const totalCount = useMemo(
    () => sum(map('quantity', cartProductsList)),
    [cartProductsList]
  );

  useUpdateEffect(() => {
    if (totalCount > 0 && !isOpen) {
      setIsOpen(true);
    }
  }, [totalCount]);

  return (
    <Group
      position="right"
      bg="white"
      px={30}
      py="xs"
      className={classes.container}
      spacing="xl"
    >
      <Popover
        opened={isOpen}
        onClose={() => setIsOpen(false)}
        closeOnClickOutside
        closeOnEscape
        withinPortal
        offset={20}
        position="bottom-end"
        shadow="xl"
      >
        <Popover.Target>
          <Group
            align="center"
            spacing="xs"
            onClick={() => setIsOpen(true)}
            sx={{ cursor: 'pointer' }}
            py={0}
          >
            <Indicator
              inline
              size={16}
              label={totalCount}
              disabled={!totalCount}
              color="red_accent"
              offset={6}
            >
              <ActionIcon
                onClick={() => setIsOpen((prev) => !prev)}
                variant="transparent"
                size="lg"
                color="gray.7"
              >
                <ShoppingCartIcon />
              </ActionIcon>
            </Indicator>

            <Text color="gray.7" lh="40px">
              Your Cart
            </Text>
          </Group>
        </Popover.Target>

        <Popover.Dropdown p={0}>
          <Box w={395}>
            <ExternalStoreShoppingCart onClose={() => setIsOpen(false)} />
          </Box>
        </Popover.Dropdown>
      </Popover>

      <Button
        px={0}
        variant="transparent"
        onClick={() => navigate('/auth/sign-in')}
        c="gray.7"
        size="md"
        lh="40px"
      >
        Sign In
      </Button>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    borderBottom: `2px solid ${theme.colors.blue_gray[0]}`,
  },
}));
