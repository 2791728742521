import React from 'react';
import styled from 'styled-components';

import { TreeChevron } from '@portals/framework';
import { getStyledThemeColor } from '@portals/utils';

const Toggler = ({ expanded, handleToggle }) => (
  <Container className="toggler" onClick={handleToggle} $isExpanded={expanded}>
    <TreeChevron className="rst_toggleButtonIcon" />
  </Container>
);

const Container = styled.div<{ $isExpanded: boolean }>`
  cursor: pointer;
  z-index: 1;
  background-color: ${getStyledThemeColor('gray150')};

  .rst_toggleButtonIcon {
    height: 13px;
    width: 13px;
    transform: rotate(0deg);
    transition: transform 0.15s ease-in-out;

    ${({ $isExpanded }) =>
      !$isExpanded &&
      `
      transform: rotate(-90deg);
    `}
  }
`;

export default Toggler;
