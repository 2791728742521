import { ReactComponent as Box2 } from '@portals/icons/linear/box-2.svg';
import { ReactComponent as Setting2 } from '@portals/icons/linear/setting-2.svg';

import {
  VendorCategoryEnum,
  VendorCategoryType,
  VendorType,
} from './connect.types';
import adaptEnergySrc from '../../../assets/img/c2c-integrations/adapt_energy_vendor_connect.png';
import avocorSrc from '../../../assets/img/c2c-integrations/avocor_vendor_connect.svg';
import avproEdgeSrc from '../../../assets/img/c2c-integrations/avpro_edge_vendor_connect.svg';
import dellSrc from '../../../assets/img/c2c-integrations/dell_vendor_connect.svg';
import hpSrc from '../../../assets/img/c2c-integrations/hp_vendor_connect.svg';
import intelSrc from '../../../assets/img/c2c-integrations/intel_vendor_connect.svg';
import legrandSrc from '../../../assets/img/c2c-integrations/legrand_vendor_connect.svg';
import msTeamsSrc from '../../../assets/img/c2c-integrations/ms_teams_vendor_connect.svg';
import msolutionsSrc from '../../../assets/img/c2c-integrations/msolutions_vendor_connect.svg';
import qscReflectSrc from '../../../assets/img/c2c-integrations/qsc_reflect_vendor_connect.png';
import tekvoxSrc from '../../../assets/img/c2c-integrations/tekvox_vendor_connect.png';
import volvoSrc from '../../../assets/img/c2c-integrations/volvo_vendor_connect.svg';
import xioCloudSrc from '../../../assets/img/c2c-integrations/xio_cloud_vendor_connect.svg';
import zoomSrc from '../../../assets/img/c2c-integrations/zoom_vendor_connect.svg';

export const CATEGORIES: Array<VendorCategoryType> = [
  {
    id: VendorCategoryEnum.CloudService,
    label: 'Cloud Services',
  },
  {
    id: VendorCategoryEnum.Manufacturer,
    label: 'Manufacturers',
  },
];

export const CATEGORY = {
  [VendorCategoryEnum.Manufacturer]: 'Manufacturer',
  [VendorCategoryEnum.CloudService]: 'Cloud Service',
};

export const CATEGORY_ICON = {
  [VendorCategoryEnum.Manufacturer]: Box2,
  [VendorCategoryEnum.CloudService]: Setting2,
};

export const VENDORS: Array<VendorType> = [
  {
    id: 'avocor',
    name: 'Avocor',
    logo: avocorSrc,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'avpro_edge',
    name: 'AVPro Edge',
    logo: avproEdgeSrc,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'adapt_energy',
    name: 'Adapt Energy',
    logo: adaptEnergySrc,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'dell',
    name: 'Dell',
    logo: dellSrc,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'hp_technologies',
    name: 'HP Technologies',
    logo: hpSrc,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'intel',
    name: 'Intel',
    logo: intelSrc,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'legrand',
    name: 'Legrand',
    logo: legrandSrc,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'msolutions',
    name: 'MSolutions',
    logo: msolutionsSrc,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'tekvox',
    name: 'Tekvox',
    logo: tekvoxSrc,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'volvo_ce',
    name: 'Volvo CE',
    logo: volvoSrc,
    category: VendorCategoryEnum.Manufacturer,
  },
  {
    id: 'zoom',
    name: 'Zoom Rooms',
    logo: zoomSrc,
    category: VendorCategoryEnum.CloudService,
  },
  {
    id: 'microsoft_teams',
    name: 'Microsoft Teams Rooms',
    logo: msTeamsSrc,
    category: VendorCategoryEnum.CloudService,
  },
  {
    id: 'xio_cloud',
    name: 'Crestron XiO Cloud',
    logo: xioCloudSrc,
    category: VendorCategoryEnum.CloudService,
  },

  {
    id: 'qsc_reflect',
    name: 'QSC Reflect',
    logo: qscReflectSrc,
    category: VendorCategoryEnum.CloudService,
  },
];
