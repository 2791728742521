import { Paper, PaperProps, Stack, Text } from '@mantine/core';
import React, { FC, ReactNode } from 'react';

export interface KpiWidgetType {
  title: string;
  label: ReactNode;
}

interface KPIWidgetProps {
  kpiWidget: KpiWidgetType;
  paperProps?: Partial<PaperProps>;
}

const KpiWidget: FC<KPIWidgetProps> = ({ kpiWidget, paperProps = {} }) => {
  return (
    <Paper p={32} radius={20} {...paperProps}>
      <Stack spacing="xs">
        <Text
          color="gray.5"
          size="md"
          data-testid={`kpi-widget-${kpiWidget.title}-title`}
        >
          {kpiWidget.title}
        </Text>
        <Text
          color="gray.8"
          weight={700}
          size={28}
          data-testid={`kpi-widget-${kpiWidget.title}-label`}
        >
          {kpiWidget.label}
        </Text>
      </Stack>
    </Paper>
  );
};

export default KpiWidget;
