import {
  Badge,
  BadgeProps,
  createStyles,
  Highlight,
  Paper,
  Stack,
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode } from 'react';

import { PartnerAvatar } from '@portals/framework';
import { ReactComponent as TickCircleIcon } from '@portals/icons/bold/tick-circle.svg';

interface CecConnectionCardProps {
  logo: string;
  title: string;
  description: string;
  isConnected: boolean;
  searchTerm: string;
  action: ReactNode;
}

export function CecConnectionCard({
  logo,
  title,
  description,
  isConnected,
  searchTerm,
  action,
}: CecConnectionCardProps) {
  const { classes } = useStyles();

  const { hovered, ref } = useHover();

  return (
    <Paper radius="lg" className={classes.container} ref={ref}>
      {isConnected && (
        <Badge size="xl" styles={badgeStyles} leftSection={<TickCircleIcon />}>
          Connected
        </Badge>
      )}

      <Stack align="center" h="100%">
        <PartnerAvatar size={70} src={logo} partnerName={title} />

        <Highlight
          highlight={searchTerm}
          size="lg"
          color="gray.9"
          className={classes.highlight}
          truncate
        >
          {title}
        </Highlight>

        <Highlight
          highlight={searchTerm}
          color="gray.6"
          className={classes.highlight}
          lineClamp={5}
        >
          {description}
        </Highlight>
      </Stack>

      {!isConnected && (
        <AnimatePresence initial={false}>
          {hovered && (
            <motion.div
              initial={{ y: 50 }}
              animate={{ y: 0 }}
              exit={{ y: 120 }}
              className={classes.action}
            >
              {action}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    overflow: 'hidden',
    position: 'relative',
    padding: theme.spacing.xxl,
    textAlign: 'center',
    border: `2px solid ${theme.colors.gray[1]}`,
    transition: 'box-shadow 150ms ease-in-out',

    ...theme.fn.hover({
      boxShadow: theme.shadows.xl,
    }),
  },
  highlight: {
    width: '100%',
    mark: {
      padding: 0,
    },
  },
  action: {
    position: 'absolute',
    height: 120,
    width: '100%',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'grid',
    placeContent: 'center',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.00) -6.8%, #FFF 26.5%)',
  },
}));

const badgeStyles: BadgeProps['styles'] = (theme) => ({
  root: {
    position: 'absolute',
    top: theme.spacing.lg,
    right: theme.spacing.lg,
    paddingInline: theme.spacing.xs,
    fontSize: 10,
    fontWeight: 400,
    color: theme.colors.gray[9],
    backgroundColor: theme.colors.indigo[0],
  },
  leftSection: {
    lineHeight: 'normal',

    svg: {
      width: 16,
      height: 16,
    },
  },
  inner: {
    lineHeight: 'normal',
  },
});
