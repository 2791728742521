import { LoadingOverlay } from '@mantine/core';
import { QueryObserverResult } from '@tanstack/react-query';
import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { ServerError } from '@portals/api';
import {
  GettingStartedToursNamesEnum,
  useClaimDeviceByQrCode,
  useCompleteGettingStartedTour,
  useIsFirstClaimedDevice,
  useOrganizationConfig,
  usePortalCapabilities,
} from '@portals/api/organizations';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
} from '@portals/framework';
import { ReactComponent as TrendUp } from '@portals/icons/bulk/trend-up.svg';
import { useOpenModal } from '@portals/redux';

import { TourCompletedModalProps } from '../modals';

export const ClaimDeviceByQrCode = () => {
  const location = useLocation();
  const isFirstClaimedDevice = useIsFirstClaimedDevice();
  const openModal = useOpenModal();
  const completeGettingStartedTour = useCompleteGettingStartedTour();
  const portalCapabilities = usePortalCapabilities();
  const navigate = useNavigate();
  const { mutateAsync: claimDevice } = useClaimDeviceByQrCode();
  const organizationConfig = useOrganizationConfig();

  const { cloudId } = useParams<{ cloudId: string }>();

  const qrCode = useMemo(
    () => new URLSearchParams(location.search).get('qr') || cloudId,
    [location.search, cloudId]
  );

  const showClaimDeviceTourCompletedModal = useCallback(
    (response: QueryObserverResult<boolean, ServerError>) => {
      return (
        response.data &&
        (isCustomerFeatureAll(portalCapabilities.onboarding_guides) ||
          isCustomerFeatureLabOnly(
            portalCapabilities.onboarding_guides,
            organizationConfig.lab
          ))
      );
    },
    [organizationConfig.lab, portalCapabilities.onboarding_guides]
  );

  const onClaimDevice = useCallback(
    async (qrCode) => {
      try {
        await claimDevice({ qr: qrCode });

        const response = await isFirstClaimedDevice.refetch();

        if (showClaimDeviceTourCompletedModal(response)) {
          openModal<TourCompletedModalProps['data']>('TourCompletedModal', {
            title: 'You’ve just claimed your first device',
            nextTourTitle: 'Monitor and manage devices',
            nextTourDescription:
              'Learn how to effectively monitor, manage, and configure your devices, empowering you to keep track of their performance and make necessary adjustments as needed.',
            nextTourIcon: <TrendUp />,
            onNextTourClick: () => {
              navigate(
                `/overview/?active_tour=${GettingStartedToursNamesEnum.Monitoring}`
              );
            },
          });

          completeGettingStartedTour.mutate('claim_device_completed');
        }
      } catch (err) {
        console.error(err);
      }

      navigate('/');
    },
    [
      navigate,
      claimDevice,
      isFirstClaimedDevice,
      showClaimDeviceTourCompletedModal,
      openModal,
      completeGettingStartedTour,
    ]
  );

  useEffectOnce(function claimDeviceOnQrParam() {
    if (qrCode) {
      onClaimDevice(qrCode);
    } else {
      navigate('/');
    }
  });

  return <LoadingOverlay visible />;
};
