import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';

import {
  DEVICE_MODELS_API_URL,
  deviceModelsQueryKeys,
} from './device-models.constants';
import {
  DeviceModelAndPartnerSearchResultType,
  DeviceModelType,
  PartnerWithDeviceModelsType,
} from './device-models.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export function useDeviceModels() {
  return useApiQuery<Array<DeviceModelType>>(
    DEVICE_MODELS_API_URL,
    deviceModelsQueryKeys.base
  );
}

interface UseSearchDeviceModelsAndPartnersParams {
  searchTerm: string;
}

export function useSearchDeviceModelsAndPartners() {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: DEVICE_MODELS_API_URL,
    method: 'GET',
  });

  return useMutation<
    Array<DeviceModelAndPartnerSearchResultType>,
    ServerError,
    UseSearchDeviceModelsAndPartnersParams
  >({
    mutationFn: ({ searchTerm }) =>
      fetchApiRequest(`${url}/search?q=${searchTerm}`, options),
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useDeviceModelOrPartnerSearchResult',
      baseUrl: `${DEVICE_MODELS_API_URL}/search`,
      method: 'GET',
    },
  });
}

export function usePartnerWithDeviceModels(partnerId: string) {
  return useApiQuery<PartnerWithDeviceModelsType>(
    `${DEVICE_MODELS_API_URL}/partner_device_models?partner_id=${partnerId}`,
    deviceModelsQueryKeys.partnerWithDeviceModels(partnerId),
    {
      enabled: !!partnerId,
    }
  );
}
