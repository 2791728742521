import { Modal } from '@mantine/core';
import React, { FC } from 'react';

import { DeviceType, useCreateIncident } from '@portals/api/organizations';
import { AutoFormik } from '@portals/autoformik';
import { ModalProps } from '@portals/framework';
import { FieldType, FieldTypeEnum } from '@portals/types';

const FIELDS: Array<FieldType> = [
  { name: 'title', title: 'Title', required: true },
  { name: 'description', title: 'Description', type: FieldTypeEnum.Textarea },
  {
    name: 'priority',
    title: 'Priority',
    required: true,
    type: FieldTypeEnum.Select,
    options: {
      1: 'Critical',
      2: 'High',
      3: 'Moderate',
      4: 'Low',
      5: 'Planning',
    },
  },
];

export interface ReportIncidentModalData {
  device: DeviceType;
}

export const ReportIncident: FC<ModalProps<ReportIncidentModalData>> = ({
  data: { device },
  closeMe,
}) => {
  const createIncident = useCreateIncident();

  const handleSubmit = async (formData) => {
    try {
      await createIncident.mutateAsync({
        deviceId: device.id,
        incident: formData,
      });

      closeMe();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal opened onClose={closeMe} title="Report Incident">
      <AutoFormik
        fields={FIELDS}
        inProgress={createIncident.isLoading}
        handleSubmit={handleSubmit}
        initialValues={{ priority: 3 }}
        modal={closeMe}
        submitTitle="Create"
      />
    </Modal>
  );
};
