import { ActionIcon, createStyles, Tooltip } from '@mantine/core';
import React from 'react';

import { useOrganizationConfig } from '@portals/api/organizations';
import { ReactComponent as Lifebuoy } from '@portals/icons/linear/lifebuoy.svg';

export function NavbarContactSupport() {
  const organizationConfig = useOrganizationConfig();

  const { classes } = useStyles();

  if (!organizationConfig?.partner_support_email) {
    return null;
  }

  return (
    <Tooltip label="Contact support">
      <ActionIcon
        component="a"
        variant="transparent"
        size="lg"
        target="_blank"
        href={`mailto:${organizationConfig.partner_support_email}`}
        className={classes.actionIcon}
      >
        <Lifebuoy />
      </ActionIcon>
    </Tooltip>
  );
}

const useStyles = createStyles((theme) => ({
  actionIcon: {
    color: theme.colors.blue_gray[6],

    '&:hover': {
      color: theme.colors.blue_gray[6],
    },
  },
}));
