import {
  Badge,
  Box,
  createStyles,
  Group,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import React, { useMemo } from 'react';

import {
  IncidentPriorityEnum,
  SpaceIncidentViewEnum,
} from '@portals/api/organizations';
import { ReactComponent as InfoCircle } from '@portals/icons/linear/info-circle.svg';
import { DashboardLayoutListItem } from '@portals/types';

import { useSpaceIncidentRateWidgetCountAndPercentage } from './space-incident-rate-widget.hooks';
import {
  countLocalIncidentsInSpace,
  countSpacesWithDevices,
  countSpacesWithIncidentsIncludingChildren,
} from './utils';
import { useSpaceTree } from '../../../../../../lib/spaces';
import { useOverviewContext } from '../../../overview.context';
import { useCurrentSpace } from '../../../overview.hooks';
import { DataLevelEnum, WidgetType } from '../../../overview.types';
import { WidgetCardWrapper } from '../common/WidgetCardWrapper';

interface SpaceIncidentRateWidgetExtraParams {
  title: string;
  incidentPriority: IncidentPriorityEnum[];
  spaceIncidentView: string;
}

export function SpaceIncidentRateDashboardWidgetWrapper({
  widgetId,
}: {
  widgetId: string;
}) {
  const { dashboard } = useOverviewContext();

  return dashboard.list.map((item) => {
    //As there is a type issue with the item, we need to cast it to the correct type
    const widget = item as DashboardLayoutListItem<
      WidgetType,
      SpaceIncidentRateWidgetExtraParams
    >;

    return item.id === 'space_incidents_rate' && item.i === widgetId ? (
      <SpaceIncidentRateDashboardWidget key={item.i} item={widget} />
    ) : null;
  });
}

export function SpaceIncidentRateDashboardWidget({
  item,
}: {
  item: DashboardLayoutListItem<WidgetType, SpaceIncidentRateWidgetExtraParams>;
}) {
  const { classes } = useStyles();
  const space = useCurrentSpace();

  const { dataLevel } = useOverviewContext();

  const title = item?.extraParams?.title;
  const incidentPriority = item?.extraParams?.incidentPriority;
  const spaceIncidentView = item?.extraParams?.spaceIncidentView;

  const spaceTree = useSpaceTree({
    spaceId: space?.id ?? 1,
    parentSpaceId: space?.parent_id ?? null,
  });

  const spacesCount = countSpacesWithDevices({
    spaceTreeChildren: spaceTree.children,
    dataLevel,
    totalDevicesCount: spaceTree.device_count,
    localDevicesCount:
      spaceTree.local_device_count && spaceTree.local_device_count > 0 ? 1 : 0,
  });

  const spacesWithIncidentsCount = useMemo(() => {
    if (!incidentPriority || spacesCount === 0) {
      return 0;
    }

    if (dataLevel === DataLevelEnum.Local) {
      return countLocalIncidentsInSpace(incidentPriority, spaceTree);
    }

    return countSpacesWithIncidentsIncludingChildren(
      incidentPriority,
      spaceTree
    );
  }, [incidentPriority, spaceTree, dataLevel, spacesCount]);

  const getPrioritiesLabel = () => {
    if (incidentPriority.length === Object.keys(IncidentPriorityEnum).length) {
      return 'All Priorities';
    } else if (incidentPriority.length === 1) {
      return incidentPriority[0];
    }

    return `${incidentPriority.length} priorities`;
  };

  const {
    spacesWithoutIncidentsCount,
    percentageOfSpacesWithoutIncidents,
    percentageOfSpacesWithIncidents,
  } = useSpaceIncidentRateWidgetCountAndPercentage({
    spacesWithIncidentsCount,
    spacesCount,
  });

  if (!title || !incidentPriority || !spaceIncidentView) {
    return null;
  }

  return (
    <WidgetCardWrapper
      paperProps={{
        p: 'xl',
      }}
      stackProps={{ spacing: 'xs', p: 0 }}
      header={
        <Group spacing="xs" position="apart" noWrap>
          <Text color="gray.5" truncate size="md">
            {title}
          </Text>

          <Box>
            <Tooltip label="Showing only spaces with devices. Spaces without devices are ignored.">
              <InfoCircle className={classes.icon} width={18} height={18} />
            </Tooltip>
          </Box>
        </Group>
      }
    >
      <Stack spacing="xs" justify="center" pos="relative">
        <Tooltip
          label={
            <Group spacing={2}>
              <Text>Incident priorities:</Text>

              <Text transform="capitalize">{incidentPriority.join(', ')}</Text>
            </Group>
          }
        >
          <Badge color="gray" variant="outline" px="md" py="sm" w="fit-content">
            {getPrioritiesLabel()}
          </Badge>
        </Tooltip>

        <Box>
          <Group spacing="sm" align="baseline">
            <Text weight={700} size={28} color="blue_gray.9">
              {spaceIncidentView === SpaceIncidentViewEnum.SpacesWithIncidents
                ? percentageOfSpacesWithIncidents
                : percentageOfSpacesWithoutIncidents}
              %
            </Text>

            <Text size="sm" color="blue_gray.9">
              (
              {`${
                spaceIncidentView === SpaceIncidentViewEnum.SpacesWithIncidents
                  ? spacesWithIncidentsCount
                  : spacesWithoutIncidentsCount
              } of ${spacesCount}`}
              )
            </Text>
          </Group>

          <Text size="sm" color="blue_gray.9">
            {spaceIncidentView === SpaceIncidentViewEnum.SpacesWithIncidents
              ? 'spaces with incidents'
              : 'incident-free spaces'}
          </Text>
        </Box>
      </Stack>
    </WidgetCardWrapper>
  );
}

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.colors.gray[5],
  },
}));
